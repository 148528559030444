import { getEventCategories } from '../../api/api'

// CONSTANTS
const FETCH_EVENT_CATEGORIES = 'FETCH_EVENT_CATEGORIES'
const RECEIVE_EVENT_CATEGORIES = 'RECEIVE_EVENT_CATEGORIES'
const EVENT_CATEGORIES_ERROR = 'EVENT_CATEGORIES_ERROR'
export const CLEAR_EVENTS_CATEGORIES = 'CLEAR_EVENTS_CATEGORIES'

// ACTIONS
export const fetchEventCategories = () => dispatch =>
  getEventCategories()
    .then(res => {
      dispatch({ type: FETCH_EVENT_CATEGORIES })
      if (res?.data) {
        const data = res?.data
          .map(category => ({
            label: category?.name,
            value: category.uuid,
            order: category?.weight,
          }))
          .sort((a, b) => (a.order < b.order ? -1 : 1))

        dispatch({
          type: RECEIVE_EVENT_CATEGORIES,
          payload: data,
        })
      } else {
        dispatch({
          type: EVENT_CATEGORIES_ERROR,
          payload: 'Unable to fetch event categories',
        })
      }
    })
    .catch(() => {
      dispatch({
        type: EVENT_CATEGORIES_ERROR,
        payload: 'Unable to fetch event categories',
      })
    })

// REDUCER
export default function eventCategoriesReducer(
  state = { loading: false, data: null, error: null },
  action,
) {
  switch (action.type) {
    case FETCH_EVENT_CATEGORIES:
      return {
        ...state,
        loading: true,
      }
    case RECEIVE_EVENT_CATEGORIES:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }
    case EVENT_CATEGORIES_ERROR:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }
    case CLEAR_EVENTS_CATEGORIES:
      return { ...state, data: null }
    default:
      return { ...state }
  }
}

// SELECTORS
export const selectEventCategoryState = state => state.eventCategoriesData
