import { getJobLocations } from '../../api/api'

// CONSTANTS
const FETCH_JOB_LOCATIONS = 'FETCH_JOB_LOCATIONS'
const RECEIVE_JOB_LOCATIONS = 'RECEIVE_JOB_LOCATIONS'
const JOB_LOCATIONS_ERROR = 'JOB_LOCATIONS_ERROR'
export const CLEAR_JOB_LOCATIONS = 'CLEAR_JOB_LOCATIONS'

// ACTIONS
export const fetchJobLocations = () => dispatch =>
  getJobLocations()
    .then(res => {
      dispatch({ type: FETCH_JOB_LOCATIONS })
      if (res?.data) {
        const data = res?.data
          .map(location => ({
            label: location?.name,
            value: location.uuid,
            order: location?.weight,
          }))
          .sort((a, b) => (a.order < b.order ? -1 : 1))

        dispatch({
          type: RECEIVE_JOB_LOCATIONS,
          payload: data,
        })
      } else {
        dispatch({
          type: JOB_LOCATIONS_ERROR,
          payload: 'Unable to fetch job locations',
        })
      }
    })
    .catch(() => {
      dispatch({
        type: JOB_LOCATIONS_ERROR,
        payload: 'Unable to fetch job locations',
      })
    })

// REDUCER
export default function jobLocationsReducer(
  state = { loading: false, data: null, error: null },
  action,
) {
  switch (action.type) {
    case FETCH_JOB_LOCATIONS:
      return {
        ...state,
        loading: true,
      }
    case RECEIVE_JOB_LOCATIONS:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }
    case JOB_LOCATIONS_ERROR:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }
    case CLEAR_JOB_LOCATIONS:
      return { ...state, data: null }
    default:
      return { ...state }
  }
}

// SELECTORS
export const selectJobLocationState = state => state.jobLocationsData
