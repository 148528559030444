import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { logoutUser } from '../../redux/authentication/authentication'
import { selectUserData } from '../../redux/user/fetchUser'
import {
  selectHasPartners,
  selectUserPartnerOptionsWithNonMember,
} from '../../redux/partners/userPartners'
import {
  setActivePartner,
  selectActivePartner,
} from '../../redux/activePartner/activePartner'
// ASSETS
import homeIcon from '../../assets/icons/home.svg'
import textLogo from '../../assets/images/hhi-text-logo.svg'
import calIcon from '../../assets/icons/calendar.svg'
import offerIcon from '../../assets/icons/offer.svg'
import userGuide from '../../assets/icons/guide.svg'
import userIcon from '../../assets/icons/user.svg'
import helpIcon from '../../assets/icons/help.svg'
import gearIcon from '../../assets/icons/gears.svg'
import logOutIcon from '../../assets/icons/arrow-out.svg'
import questionIcon from '../../assets/icons/help-light-grey.svg'
import phoneIcon from '../../assets/icons/phone.svg'
import hhiLogo from '../../assets/images/hhi-logo-mobile.svg'
import jobLogo from '../../assets/icons/Post-a-job-Tack-icon.svg'
// STYLES
import '../../styles/structure/header.scss'
import routes from '../../configuration/routes'
import Dropdown from '../elements/Dropdown/Dropdown'

const Header = () => {
  const dispatch = useDispatch()
  const { data } = useSelector(selectUserData)
  const partnerOptions = useSelector(selectUserPartnerOptionsWithNonMember)
  const activePartner = useSelector(selectActivePartner)
  const hasPartners = useSelector(selectHasPartners)
  const location = useLocation()

  const partnerSelectRoutes = [
    routes.dashboard,
    routes.events,
    routes.offers,
  ].some(
    route => location?.pathname === route || location?.pathname === `${route}/`,
  )
  const [showPartnerSelect, setShowPartnerSelect] =
    useState(partnerSelectRoutes)

  useEffect(() => {
    setShowPartnerSelect(partnerSelectRoutes)
  }, [location])

  function handleLogout() {
    dispatch(logoutUser())
  }

  return (
    <div className="header bg-dark color-white p-4">
      <div className="header-nav-container container-centered-xlr flex flex-align-center">
        <Link to={routes.dashboard}>
          <img
            className="header-logo mr-6 lgr-up"
            src={textLogo}
            alt="Hilton Head-Island Chamber of Commerce"
          />
          <img
            className="header-logo-mobile mr-6"
            src={hhiLogo}
            alt="Hilton Head-Island Chamber of Commerce"
          />
        </Link>
        <div className="header-nav flex-md-up flex-align-center flex-space-between flex-grow-md-up pl-6 border-left-xs-up border-dark-grey">
          <div>
            {showPartnerSelect && (
              <>
                <p className="m-0 ">
                  {data?.firstName}&nbsp;{data?.lastName}
                </p>
                <Dropdown
                  btnText={
                    activePartner?.title
                      ? activePartner.title
                      : 'Choose Account'
                  }
                  btnClassNames="color-white border-bottom border-white text-align-left f-14 border-radius-0 px-0 pb-2 organization-dropdown"
                  spaceBetween
                >
                  {partnerOptions !== null &&
                    partnerOptions.map(partner => (
                      <HeaderDropdownItem
                        onClick={() =>
                          dispatch(setActivePartner(partner.id, partner.title))
                        }
                        text={partner.title}
                        borderBottom
                        key={partner.id}
                      />
                    ))}
                </Dropdown>
              </>
            )}
          </div>

          <div className="header-nav__links lgr-up flex-align-center">
            <Link
              to={routes.dashboard}
              className="flex flex-center color-white no-text-decoration uppercase bold mr-8 f-14"
            >
              <div className="flex flex-align-center">
                <img src={homeIcon} alt="home" className="mr-2" />
                Home
              </div>
            </Link>

            <Link
              to={routes.events}
              className="flex flex-center color-white no-text-decoration uppercase bold mr-8 f-14"
            >
              <div className="flex flex-align-center">
                <img src={calIcon} alt="calender" className="mr-2" />
                Events
              </div>
            </Link>
            {hasPartners && (
              <>
                <Link
                  to={routes.offers}
                  className="color-white no-text-decoration uppercase bold mr-8 f-14"
                >
                  <div className="flex flex-align-center">
                    <img src={offerIcon} alt="offers" className="mr-2" />
                    Offers
                  </div>
                </Link>
                <Link
                  to={routes.jobs}
                  className="color-white no-text-decoration uppercase bold mr-8 f-14"
                >
                  <div className="flex flex-align-center">
                    <img src={jobLogo} alt="Jobs" className="mr-2" />
                    Jobs
                  </div>
                </Link>
              </>
            )}

            <div className="pr-2">
              <Dropdown
                btnText="Account"
                btnClassNames="color-white bg-dark-grey f-14 py-3 px-4"
                activeBtnClassNames="btn-primary f-14 py-3 px-4"
                icon={userIcon}
              >
                <div className="bg-white box-shadow border border-light-grey border-radius-3">
                  <Link to={routes.profileSettings}>
                    <HeaderDropdownItem
                      icon={gearIcon}
                      text="Profile Settings"
                      borderBottom
                    />
                  </Link>
                  <HeaderDropdownItem
                    onClick={handleLogout}
                    icon={logOutIcon}
                    text="Log Out"
                  />
                </div>
              </Dropdown>
            </div>
            <div>
              <Dropdown
                btnText="Help"
                btnClassNames="color-white bg-dark-grey f-14 py-3 px-4"
                activeBtnClassNames="btn-primary f-14 py-3 px-4"
                icon={helpIcon}
              >
                <div className="bg-white box-shadow border border-light-grey border-radius-3">
                  <Link to={routes.faq}>
                    <HeaderDropdownItem
                      icon={questionIcon}
                      text="FAQ"
                      borderBottom
                    />
                  </Link>
                  <Link to={routes.userGuide}>
                    <HeaderDropdownItem
                      icon={userGuide}
                      text="User Guide"
                      borderBottom
                    />
                  </Link>
                  <Link
                    to={{
                      pathname:
                        'https://partners.hiltonheadisland.org/contact-us',
                    }}
                    target="_blank"
                  >
                    <HeaderDropdownItem icon={phoneIcon} text="Contact Us" />
                  </Link>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const HeaderDropdownItem = ({ borderBottom, onClick, icon, text }) => (
  <button
    type="button"
    onClick={onClick}
    className={`remove-margin btn color-field-grey bg-white f-14 font-weight-light no-text-transform no-wrap border-radius-0 full-width
    ${borderBottom ? 'border-bottom border-color-grey' : ''}
    `}
  >
    <div className="flex flex-align-center">
      {icon && <img src={icon} alt={text} className="mr-2" />}
      <span>{text}</span>
    </div>
  </button>
)
HeaderDropdownItem.defaultProps = {
  icon: null,
  borderBottom: false,
  onClick: null,
}
HeaderDropdownItem.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  borderBottom: PropTypes.bool,
}

export default Header
