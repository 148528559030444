import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = ({
  checked,
  renderLabel,
  name,
  onChange,
  error,
  disabled,
  groupValue,
}) => (
  <div>
    <label className="flex flex-align-center" htmlFor={`${name}-${groupValue}`}>
      <input
        type="checkbox"
        id={`${name}-${groupValue}`}
        name={name}
        value={checked}
        onChange={onChange}
        className={error ? 'error' : ''}
        disabled={disabled}
        data-groupvalue={groupValue}
      />
      <span>{renderLabel()}</span>
    </label>
    {error && <p className="color-error remove-margin">{error}</p>}
  </div>
)

Checkbox.defaultProps = {
  error: null,
  disabled: false,
  groupValue: '',
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  renderLabel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  groupValue: PropTypes.string,
}

export default Checkbox
