import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const MobileStepper = ({ activeStep, steps }) => (
  <div className="mobile-stepper-container text-center">
    <p className="m-0 py-2 f-24">
      {activeStep + 1}/{steps.length} {steps[activeStep]}
    </p>
    <div className="flex-grow stepper flex flex-justify-center">
      {steps.map((step, i) => {
        const isActive = activeStep >= i
        const isComplete = activeStep >= i + 1
        const lastStep = i + 1 === steps.length
        const centerStep = i > 0 && i + 1 < steps.length

        return (
          <div
            key={step}
            className={`flex step-container flex-justify-center ${
              isActive ? 'active' : ''
            } 
            ${isComplete ? 'complete' : ''}
            ${centerStep ? ' center-step' : ''} ${
              lastStep ? 'last-step' : ''
            } ${i === 0 ? 'first-step' : ''}`}
          >
            <div className="step">
              <div className="indicator" />
            </div>
          </div>
        )
      })}
    </div>
  </div>
)

MobileStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default MobileStepper
