import draftToHtml from 'draftjs-to-html'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'

export function convertEditorStateToRaw(state) {
  return state ? draftToHtml(convertToRaw(state?.getCurrentContent())) : null
}

export function convertRawToEditorState(html) {
  let initialState = null
  if (html) {
    const contentBlock = htmlToDraft(html)

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      )

      const editorState = EditorState.createWithContent(contentState)
      if (editorState) {
        initialState = editorState
      }
    }
  }
  return initialState
}

export function getTotalLengthFromEditorState(state) {
  let contentArray = []
  if (state && convertToRaw(state?.getCurrentContent())?.blocks) {
    contentArray = convertToRaw(state?.getCurrentContent())?.blocks
  }

  return contentArray.reduce((num, block) => {
    const length = block?.text?.length || 0

    return num + length
  }, 0)
}

export function convertEditorStateToPlainText(state) {
  let contentArray = []
  if (state && convertToRaw(state?.getCurrentContent())?.blocks) {
    contentArray = convertToRaw(state?.getCurrentContent())?.blocks
  }
  return contentArray
    .map(block => (block.text ? `${block.text}` : ''))
    .join(' ')
}
