import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import routes from '../configuration/routes'

const PublicRoute = ({ isAuthenticated, children, ...rest }) => (
  <Route
    {...rest}
    render={() =>
      !isAuthenticated ? children : <Redirect to={routes.dashboard} />
    }
  />
)

PublicRoute.defaultProps = {
  isAuthenticated: false,
}
PublicRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  children: PropTypes.element.isRequired,
}

export default PublicRoute
