import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { selectActivePartnerOffers } from '../../../redux/offers/offers'

import { selectActivePartnerEvents } from '../../../redux/events/events'

import { selectActivePartnerJobs } from '../../../redux/jobs/jobs'

import SubmissionsItems from './components/SubmissionsItems'

import './styles.scss'

const SubmissionsList = ({ type, linkTo }) => {
  const offers = useSelector(selectActivePartnerOffers)
  const events = useSelector(selectActivePartnerEvents)
  const jobs = useSelector(selectActivePartnerJobs)

  let items = events
  switch (type) {
    case 'offer':
      items = offers
      break
    case 'job':
      items = jobs
      break
    case 'event':
      items = events
      break
    default:
      items = events
      break
  }

  return (
    <div className="submissions-list p-6 border border-card-border bg-white border-radius-4 mt-12 mt-8-lg-max">
      <div className="flex flex-column">
        <div className="flex flex-space-between mb-2">
          <p className="font-heading f-16 normal remove-margin capitalize">
            {type} Submissions
          </p>

          <Link to={linkTo} className="font-body f-14 color-primary-color bold">
            View All
          </Link>
        </div>
        {(!items || items.length === 0) && <p>No {type}s found.</p>}
        {items?.length > 0 &&
          items.map(item => (
            <SubmissionsItems
              title={item.title}
              status={item.status}
              time={item.created}
              key={item.id}
              isLocked={item.hasLiveRevision}
            />
          ))}
      </div>
    </div>
  )
}

SubmissionsList.propTypes = {
  type: PropTypes.oneOf(['event', 'offer', 'job']).isRequired,
  linkTo: PropTypes.string.isRequired,
}

export default SubmissionsList
