import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import {
  formInitialValues,
  formValidation,
} from '../../../utilities/content/generateFormData'
import FormikInput from '../../../components/elements/FormikInput'

import companyDetailsFormFields from './formFields/companyDetailsFormFields'
import { getCountryStatesISO } from '../../../utilities/content/supportedCountries'

const CompanyDetailsForm = ({
  submit,
  initialValues,
  companySizeOptions,
  contactMethodOptions,
  industryOptions,
}) => (
  <Formik
    initialValues={initialValues || formInitialValues(companyDetailsFormFields)}
    onSubmit={submit}
    validationSchema={formValidation(companyDetailsFormFields)}
  >
    {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
      <form onSubmit={handleSubmit}>
        <div className="py-3-lg-up pb-2-lg-max">
          <FormikInput {...companyDetailsFormFields.companyName} />
        </div>
        <div className="flex-lg-up pt-3 pt-2-lg-max pb-7">
          <div className="flex-grow pr-4-lg-up pb-2-lg-max">
            <FormikInput {...companyDetailsFormFields.contactTitle} />
          </div>
          <div className="flex-grow px-4-lg-up py-2-lg-max">
            <FormikInput {...companyDetailsFormFields.contactFirstName} />
          </div>
          <div className="flex-grow pl-4-lg-up pt-2-lg-max">
            <FormikInput {...companyDetailsFormFields.contactLastName} />
          </div>
        </div>
        <div className="pb-3 pb-2-lg-max pt-7 pt-5-lg-max">
          <FormikInput {...companyDetailsFormFields.mailingAddress} />
        </div>
        <div className="py-3 py-2-lg-max">
          <FormikInput {...companyDetailsFormFields.mailingAddress2} />
        </div>

        <div className="py-3 py-2-lg-max flex-lg-up">
          <div className="flex-half pr-4-lg-up pb-2-lg-max">
            <FormikInput {...companyDetailsFormFields.city} />
          </div>
          <div className="flex-half pl-4-lg-up pt-2-lg-max">
            <FormikInput
              {...companyDetailsFormFields.state}
              options={
                values.country
                  ? getCountryStatesISO(values.country)
                  : companyDetailsFormFields.state.options
              }
            />
          </div>
        </div>
        <div className="pt-3 pt-2-lg-max pb-4 flex-lg-up">
          <div className="flex-half pr-4-lg-up pb-2-lg-max">
            <FormikInput {...companyDetailsFormFields.country} />
          </div>
          <div className="flex-half pl-4-lg-up py-2-lg-max">
            <FormikInput {...companyDetailsFormFields.zip} />
          </div>
        </div>
        {/* !values.streetSameAsMail && (
        <div className="pt-4 pb-5">
          <FormikInput {...companyDetailsFormFields.streetSameAsMail} />
        </div>
          <div className="pt-3 pb-10">
            <p className="bold f-18 m-0">Street address</p>
            <div className="pb-3 pt-7">
              <FormikInput {...companyDetailsFormFields.streetMailingAddress} />
            </div>
            <div className="py-3">
              <FormikInput
                {...companyDetailsFormFields.streetMailingAddress2}
              />
            </div>

            <div className="py-3 flex-lg-up">
              <div className="flex-half pr-4-lg-up py-2-lg-max">
                <FormikInput {...companyDetailsFormFields.streetCity} />
              </div>
              <div className="flex-half pl-4-lg-up py-2-lg-max">
                <FormikInput
                  {...companyDetailsFormFields.streetState}
                  options={
                    values.country
                      ? getCountryStatesISO(values.country)
                      : companyDetailsFormFields.state.options
                  }
                />
              </div>
            </div>
            <div className="pt-3 pb-4 flex-lg-up">
              <div className="flex-half pr-4-lg-up py-2-lg-max">
                <FormikInput {...companyDetailsFormFields.streetCountry} />
              </div>
              <div className="flex-half pl-4-lg-up py-2-lg-max">
                <FormikInput {...companyDetailsFormFields.streetZip} />
              </div>
            </div>
          </div>
        ) 
        */}

        <div className="pb-3 pb-2-lg-max pt-5 pt-3-lg-max flex-lg-up">
          <div className="flex-half pr-4-lg-up pb-2-lg-max">
            <FormikInput {...companyDetailsFormFields.phone} />
          </div>
          <div className="flex-half pl-4-lg-up pt-2-lg-max">
            <FormikInput {...companyDetailsFormFields.fax} />
          </div>
        </div>
        <div className="pt-3 pt-2-lg-max pb-5 pb-2-lg-max flex-lg-up">
          <div className="flex-half pr-4-lg-up pb-2-lg-max">
            <FormikInput {...companyDetailsFormFields.email} />
          </div>
          <div className="flex-half pl-4-md-up pt-2-md-max">
            <FormikInput
              {...companyDetailsFormFields.website}
              onFocus={() => {
                if (!values?.website) {
                  setFieldValue(
                    companyDetailsFormFields.website.name,
                    'https://',
                  )
                }
              }}
              onBlur={e => {
                if (
                  values?.website === 'https://' ||
                  values?.website === 'http://'
                ) {
                  setFieldValue(companyDetailsFormFields.website.name, '')
                } else {
                  setFieldValue(
                    companyDetailsFormFields.website.name,
                    e.target.value.trim(),
                  )
                }
              }}
            />
          </div>
        </div>

        <div className="pt-15 pt-10-lg-max pb-3 pb-2-lg-max flex-lg-up">
          <div className="flex-half pr-4-lg-up pb-2-lg-max">
            <FormikInput
              {...companyDetailsFormFields.industry}
              options={
                industryOptions
                  ? [
                      { label: 'Please Select Your Industry *', value: '' },
                      ...industryOptions,
                    ]
                  : []
              }
            />
          </div>
          <div className="flex-half pl-4-lg-up pt-2-lg-max">
            <FormikInput
              {...companyDetailsFormFields.companySize}
              options={
                companySizeOptions
                  ? [
                      { label: 'Company Size *', value: '' },
                      ...companySizeOptions,
                    ]
                  : []
              }
            />
          </div>
        </div>

        <div className="pt-3 pb-4 pr-4 px-0-lg-max half-width-lg-up py-2-lg-max">
          <FormikInput
            {...companyDetailsFormFields.contactMethod}
            options={
              contactMethodOptions
                ? [
                    { label: 'Preferred Method of Contact *', value: '' },
                    ...contactMethodOptions,
                  ]
                : []
            }
          />
        </div>
        <div className="pt-10 pt-6-lg-max flex-lg-max flex-column-lg-max flex-align-center-lg-max">
          <button
            className="btn btn-primary"
            type="submit"
            disabled={isSubmitting}
          >
            Continue to Business Details
          </button>
        </div>
      </form>
    )}
  </Formik>
)
CompanyDetailsForm.defaultProps = {
  initialValues: null,
  companySizeOptions: null,
  contactMethodOptions: null,
  industryOptions: null,
}
CompanyDetailsForm.propTypes = {
  submit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  companySizeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  contactMethodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  industryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
}

export default CompanyDetailsForm
