import React from 'react'
import PropTypes from 'prop-types'
import FormikInput from '../FormikInput'

import './styles.scss'

const SocialLink = ({ icon, name, label, initialValue, href }) => (
  <div className="flex flex-align-center social-link">
    <div className="flex flex-align-center left pr-2">
      <div className="social-link__icon-container bg-body-copy flex flex-justify-center flex-align-center">
        <img className="social-link__icon" src={icon} alt="social-icon" />
      </div>
      <p className="social-link__href m-0 ml-4 mr-2 color-black md-up">
        {href}
      </p>
    </div>
    <div className="flex-grow">
      <p className="social-link__href m-0 color-black md-max">{href}</p>
      <FormikInput
        placeholder={label}
        name={name}
        initialValue={initialValue}
      />
    </div>
  </div>
)

SocialLink.defaultProps = {
  initialValue: '',
}

SocialLink.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  href: PropTypes.string.isRequired,
}
export default SocialLink
