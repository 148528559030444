const routes = {
  createEvent: '/create-event',
  createOffer: '/create-offer',
  dashboard: '/',
  editEvent: '/edit-event/',
  editOffer: '/edit-offer/',
  editPartner: '/edit-partner/',
  editPartnerMembers: '/edit-members/',
  events: '/events',
  faq: '/faq',
  jobs: '/jobs',
  userGuide: '/user-guide',
  contactUs: '/contact-us',
  profileSettings: '/profile-settings',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  joinOrganization: '/join-organization',
  login: '/login',
  offers: '/offers',
  registerOrganization: '/register-organization',
  registration: '/register',
  registrationStepTwo: '/register/join-organization',
  privacyPolicy: '/privacy-policy',
  termsConditions: '/terms-conditions',
  postJob: '/post-a-job',
}
export default routes
