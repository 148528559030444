import { getLocationFilters } from '../../api/api'

// CONSTANTS
const FETCH_LOCATION_SERVICES_CATEGORIES = 'FETCH_LOCATION_SERVICES_CATEGORIES'
const RECEIVE_LOCATION_SERVICES_CATEGORIES =
  'RECEIVE_LOCATION_SERVICES_CATEGORIES'
const LOCATION_SERVICES_CATEGORIES_ERROR = 'LOCATION_SERVICES_CATEGORIES_ERROR'
export const CLEAR_LOCATION_SERVICES = 'CLEAR_LOCATION_SERVICES'

// ACTIONS
export const fetchLocationServices = () => dispatch =>
  getLocationFilters()
    .then(res => {
      dispatch({ type: FETCH_LOCATION_SERVICES_CATEGORIES })
      if (res?.data) {
        const data = res?.data
          .map(locationService => ({
            label: locationService?.name,
            value: locationService.uuid,
            order: locationService?.weight,
          }))
          .sort((a, b) => (a.order < b.order ? -1 : 1))
          .filter(item => !!(item.label && item.value))

        dispatch({
          type: RECEIVE_LOCATION_SERVICES_CATEGORIES,
          payload: data,
        })
      } else {
        dispatch({
          type: LOCATION_SERVICES_CATEGORIES_ERROR,
          payload: 'Unable to fetch location services categories',
        })
      }
    })
    .catch(() => {
      dispatch({
        type: LOCATION_SERVICES_CATEGORIES_ERROR,
        payload: 'Unable to fetch event categories',
      })
    })

// REDUCER
export default function locationServiceReducer(
  state = { loading: false, data: null, error: null },
  action,
) {
  switch (action.type) {
    case FETCH_LOCATION_SERVICES_CATEGORIES:
      return {
        ...state,
        loading: true,
      }
    case RECEIVE_LOCATION_SERVICES_CATEGORIES:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }
    case LOCATION_SERVICES_CATEGORIES_ERROR:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }
    case CLEAR_LOCATION_SERVICES:
      return { ...state, data: null }
    default:
      return { ...state }
  }
}

// SELECTORS
export const selectLocationServicesState = state => state.locationServicesData
