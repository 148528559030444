import React from 'react'

import HHILogo from '../../assets/images/hhi-logo.svg'
import constants from '../../configuration/constants'
import MobileFooter from './MobileFooter'

import '../../styles/structure/footer.scss'

const {
  labels: { url, copyright },
} = constants

const Footer = () => (
  <>
    <div className="footer bg-dark px-4 container flex-column flex-justify-center flex-align-center text-center">
      <div className="footer__brand flex-justify-center flex-align-center">
        <img src={HHILogo} className="header-brand__logo" alt="Brand Logo" />
      </div>

      <div className="footer__content color-white">
        <div className="footer__links bold">
          Visit&nbsp;
          <a
            className="no-text-decoration color-white"
            href={`https://${url}`}
            rel="noreferrer"
            target="_blank"
          >
            {url}
          </a>
        </div>

        <div className="footer__copyright">
          <p className="remove-margin">
            &#169; {new Date().getFullYear()} {copyright}
          </p>
        </div>
      </div>
    </div>
    <MobileFooter />
  </>
)

export default Footer
