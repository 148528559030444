import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import {
  fetchPartners,
  selectPartnerState,
} from '../../redux/partners/userPartners'
import {
  formInitialValues,
  formValidation,
} from '../../utilities/content/generateFormData'
import { socialLinksData } from '../../utilities/content/socialLinksData'

import renderFormFields, { formatFormForSubmission } from './formFields'
import FormikInput from '../../components/elements/FormikInput'
import WysiwygEditor from '../../components/elements/WYSIWYG/WysiwygEditor'
import { selectUserToken } from '../../redux/authentication/authentication'
import { updatePartner } from '../../api/api'
import SimpleSpinner from '../../components/elements/SimpleSpinner'
import routes from '../../configuration/routes'
import { selectUserUid } from '../../redux/user/fetchUser'
import SocialLink from '../../components/elements/SocialLink/SocialLink'

const EditPartner = () => {
  const { id } = useParams()
  const userToken = useSelector(selectUserToken)
  const partnerState = useSelector(selectPartnerState)
  const userUid = useSelector(selectUserUid)
  const currentPartner = partnerState?.data?.[id]

  const [error, setError] = useState(null)
  const [currentStep, setCurrentStep] = useState('form')
  const dispatch = useDispatch()
  const history = useHistory()
  const isAdmin = currentPartner?.userRole === 'admin'

  const formFields = renderFormFields(currentPartner, isAdmin)
  const initialValues = formInitialValues(formFields)
  const schema = formValidation(formFields)

  function submit(formValues) {
    setError(null)
    const data = formatFormForSubmission(formValues)

    return updatePartner({
      reqBody: data.partnerData,
      token: userToken,
    })
      .then(() => {
        dispatch(fetchPartners(userUid))
        setCurrentStep('success')
      })
      .catch(err => {
        setError(
          err?.response?.data?.errors?.[0]?.detail ||
            'Unable to update organization details.',
        )
        return err
      })
  }

  return (
    <div className="bg-light-grey px-2 py-10 full-height create-offer">
      <div className="container-centered-lg">
        <div className="py-8">
          <h1>Edit: {currentPartner?.title}</h1>

          <div className="bg-white p-4 p-10-md-up p-20-lg-up border border-medium-grey border-radius-4">
            {currentPartner ? (
              <>
                {currentStep === 'success' ? (
                  <div>
                    <p className="text-center f-18">
                      {currentStep === 'success'
                        ? 'Thank You. Your changes have been submitted and are pending approval.'
                        : 'Changes are pending approval.'}
                    </p>
                    <div className="pt-8 text-center">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => history.push(routes.dashboard)}
                      >
                        Return Home
                      </button>
                    </div>
                  </div>
                ) : (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={submit}
                  >
                    {({
                      handleSubmit,
                      isSubmitting,
                      values,
                      errors,
                      touched,
                      setFieldValue,
                    }) =>
                      isSubmitting ? (
                        <div className="flex flex-justify-center">
                          <SimpleSpinner />
                        </div>
                      ) : (
                        <form onSubmit={handleSubmit}>
                          <div className="py-2 pb-8">
                            <FormikInput {...formFields.title} />
                          </div>
                          <div className="py-2 pb-8">
                            <p className="mb-4 font-body f-18 bold capitalize">
                              Description
                            </p>
                            <WysiwygEditor
                              onChange={value => setFieldValue('body', value)}
                              initialState={values?.body || null}
                              disable={formFields.body?.disabled}
                            />
                            {touched?.body && errors?.body && (
                              <p className="color-error remove-margin">
                                {errors.body}
                              </p>
                            )}
                            <p className="f-12 my-1">(Max. 500 characters)</p>
                          </div>
                          <div className="py-2 pb-8">
                            <FormikInput
                              {...formFields.website}
                              onFocus={() => {
                                if (!values?.website) {
                                  setFieldValue(
                                    formFields.website.name,
                                    'https://',
                                  )
                                }
                              }}
                              onBlur={() => {
                                if (
                                  values?.website === 'https://' ||
                                  values?.website === 'http://'
                                ) {
                                  setFieldValue(formFields.website.name, '')
                                }
                              }}
                            />
                          </div>
                          <div className="py-2 pb-4">
                            <FormikInput {...formFields.logoUpload} />
                          </div>
                          <div className="py-2 pb-8">
                            <FormikInput {...formFields.imageUpload} />
                          </div>
                          <div className="py-2 pb-8">
                            <FormikInput {...formFields.accolades} />
                          </div>
                          <div className="pt-2">
                            <p className="mb-0 font-body f-18 bold">
                              Social Media URLs
                            </p>
                          </div>
                          <div className="social-link1 mb-10">
                            <p className="font-body mb-0 pt-2">
                              Enter handles as they appear after the .com/
                            </p>
                            {socialLinksData.map(link => (
                              <div className="pt-4 pb-6" key={link.name}>
                                <SocialLink
                                  {...link}
                                  initialValue={
                                    formFields[link.name]?.initialValue
                                  }
                                />
                              </div>
                            ))}
                          </div>
                          {error && (
                            <p className="mb-6 color-error f-18 bold">
                              {error}
                            </p>
                          )}
                          <button
                            className="btn btn-primary px-16 py-5"
                            type="submit"
                            disabled={isSubmitting || !isAdmin}
                          >
                            Update
                          </button>
                          {!isAdmin && (
                            <p className="my-4 italic">
                              Only admin members can update
                            </p>
                          )}
                        </form>
                      )
                    }
                  </Formik>
                )}
              </>
            ) : (
              <div>
                <p>No organization found</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditPartner
