/* eslint-disable import/no-cycle */
import { getPartners } from '../../api/api'
import { selectUserUuid } from '../user/fetchUser'
import {
  selectActivePartner,
  setActivePartner,
} from '../activePartner/activePartner'
import { selectAvailablePartners } from './availablePartners'
import {
  NON_MEMBER_ACCOUNT_ID,
  NON_MEMBER_LABEL,
} from '../../configuration/constants'
// CONSTANTS
const FETCH_USER_PARTNERS_BEGIN = 'FETCH_USER_PARTNERS_BEGIN'
const FETCH_USER_PARTNERS_ERROR = 'FETCH_USER_PARTNERS_ERROR'
const FETCH_USER_PARTNERS_SUCCESS = 'FETCH_USER_PARTNERS_SUCCESS'
export const CLEAR_USER_PARTNERS = 'CLEAR_USER_PARTNERS'

// ACTIONS
export const fetchPartners = uid => (dispatch, getState) => {
  dispatch({ type: FETCH_USER_PARTNERS_BEGIN })

  const state = getState()
  const userUuid = selectUserUuid(state)
  const activePartner = selectActivePartner(state)

  return getPartners(uid)
    .then(res => {
      if (res?.data?.data) {
        let firstMemberPartner
        const payload = res?.data?.data.reduce((obj, partner) => {
          if (!partner) {
            return {
              ...obj,
            }
          }
          const id = partner?.uuid
          const formattedPartner = formatPartner(partner, userUuid)
          if (formattedPartner?.userRole && !firstMemberPartner) {
            firstMemberPartner = {
              id: formattedPartner.uuid,
              title: formattedPartner.title,
            }
          }

          return {
            ...obj,
            [id]: formattedPartner,
          }
        }, {})
        if (!activePartner?.id) {
          if (firstMemberPartner) {
            dispatch(
              setActivePartner(firstMemberPartner.id, firstMemberPartner.title),
            )
          } else {
            dispatch(setActivePartner(NON_MEMBER_ACCOUNT_ID, NON_MEMBER_LABEL))
          }
        }
        dispatch({ type: FETCH_USER_PARTNERS_SUCCESS, payload })
      } else {
        dispatch({
          type: FETCH_USER_PARTNERS_ERROR,
          payload: 'Unable to retrieve partners',
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_USER_PARTNERS_ERROR,
        payload: err?.response?.message || 'Unable to retrieve partners',
      })
    })
}

const initialState = { loading: false, error: null, data: null }

// REDUCER
export default function userPartnerReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_PARTNERS_BEGIN:
      return {
        ...state,
        loading: true,
      }
    case FETCH_USER_PARTNERS_ERROR:
      return {
        loading: false,
        error: action.payload || 'Something went wrong',
        data: null,
      }

    case FETCH_USER_PARTNERS_SUCCESS:
      return {
        loading: false,
        error: null,
        data: action.payload,
      }
    case CLEAR_USER_PARTNERS:
      return { ...initialState }
    default:
      return state
  }
}

// SELECTORS

export const selectPartnerState = state => state.userPartnerData

// all partners
export const selectPartners = state => {
  if (
    !state?.userPartnerData?.data ||
    !Object.keys(state?.userPartnerData?.data).length
  )
    return null

  return Object.values(state.userPartnerData.data)
}

export const selectUserPartners = state => {
  const allPartners = selectPartners(state)
  return allPartners || []
}

export const selectUserPartnerOptionsWithNonMember = state => [
  ...selectUserPartners(state),
  {
    id: NON_MEMBER_ACCOUNT_ID,
    title: NON_MEMBER_LABEL,
  },
]

export const selectUserPartnerOptionsWithNonMemberAccountCode = state => {
  const userPartners = selectUserPartners(state)
  const partnersWithFieldAccountCode = userPartners.filter(
    partner => partner?.field_account?.field_account_code,
  )
  return [
    ...partnersWithFieldAccountCode,
    {
      id: NON_MEMBER_ACCOUNT_ID,
      title: NON_MEMBER_LABEL,
    },
  ]
}

export const selectUserPartnerIds = state => {
  const userPartners = selectUserPartners(state)
  return userPartners?.map(partner => partner.id)
}

export const selectNonUserPartners = state => {
  const allPartners = selectAvailablePartners(state)
  const userPartnerIds = selectUserPartnerIds(state)

  return Object.entries(allPartners).reduce((arr, [id, partner]) => {
    if (!userPartnerIds.some(partnerId => partnerId === id)) {
      arr.push(partner)
    }
    return arr
  }, [])
}

export const getUserTypeFromPartnerId = (state, partnerId) => {
  const partners = selectUserPartners(state)
  const activePartner = partners.find(partner => partner.id === partnerId)

  return activePartner?.userRole
}

export const selectHasPartners = state =>
  selectUserPartnerIds(state)?.length > 0

export const selectHasFieldAccountCode = (state, partnerId) => {
  const userPartners = selectUserPartners(state)
  const activePartner = userPartners?.find(partner => partner.id === partnerId)
  return !!activePartner?.field_account?.field_account_code
}
// UTILITIES
const formatPartner = (partner, userId) => {
  const partnerImages = partner?.field_partner_images?.length
    ? partner?.field_partner_images
    : []
  const formattedPartnerImages = []

  if (partnerImages) {
    partnerImages.forEach(image => {
      formattedPartnerImages.push({
        ...image,
        src: image?.url,
        drupalId: image.uuid,
      })
    })
  }

  const partnerAccolades = partner?.field_partner_accolades?.length
    ? partner?.field_partner_accolades
    : []
  const formattedPartnerAccolades = []

  if (partnerAccolades) {
    partnerAccolades.forEach(image => {
      formattedPartnerAccolades.push({
        ...image,
        src: image?.url,
        drupalId: image.uuid,
      })
    })
  }

  const formattedPartnerLogo = partner?.field_partner_logo?.url
    ? {
        ...partner?.field_partner_logo,
        src: partner?.field_partner_logo?.url,
        drupalId: partner?.field_partner_logo?.uuid,
      }
    : null
  const accountData = partner?.field_account || null

  const adminUsers = accountData?.field_admin_user?.length
    ? accountData?.field_admin_user
        ?.filter(user => !!user)
        .map(user => ({ ...user, role: 'admin' }))
    : []

  const memberUsers = accountData?.field_member_user?.length
    ? accountData?.field_member_user
        ?.filter(user => !!user)
        .map(user => ({ ...user, role: 'member' }))
    : []

  const pendingMembers = accountData?.field_pending_user?.length
    ? accountData?.field_pending_user
        ?.filter(user => !!user)
        .map(user => ({
          ...user,
          role: 'pending',
        }))
    : []
  const allUsers = [...adminUsers, ...memberUsers, ...pendingMembers]

  const isAdmin = adminUsers.some(user => user?.uuid === userId)
  const isMember = memberUsers.some(user => user?.uuid === userId)
  const isPendingMember = pendingMembers.some(user => user?.uuid === userId)

  let userRole = isAdmin ? 'admin' : null

  if (!userRole && isMember) {
    userRole = 'member'
  }
  if (!userRole && isPendingMember) {
    userRole = 'pending'
  }
  const isLive = partner?.status
  const isPendingRevision = !!partner?.revision_pending

  const isOwner = partner?.uid?.uuid && partner?.uid?.uuid === userId
  const canEditTeam = isOwner || isAdmin

  return {
    ...partner,
    id: partner.uuid,
    formattedPartnerImages,
    formattedPartnerLogo,
    formattedPartnerAccolades,
    accountData,
    userRole,
    isOwner,
    allUsers: canEditTeam ? allUsers : null,
    canEditTeam,
    hasLiveRevision: isPendingRevision && !isLive,
    hasDraft: partner.moderation_state === 'draft',
  }
}
