import React from 'react'
import PropTypes from 'prop-types'

import MobileStepper from '../MobileStepper/MobileStepper'

import './styles.scss'

const Stepper = ({ activeStep, steps, handleStepChange }) => (
  <>
    <div className="stepper-container">
      <div className="stepper flex flex-space-between">
        {steps.map((step, i) => {
          const isActive = activeStep >= i
          const isComplete = activeStep >= i + 1
          const lastStep = i + 1 === steps.length
          const centerStep = i > 0 && i + 1 < steps.length
          return (
            <div
              key={step}
              className={`flex-grow flex step-container flex-justify-center ${
                isActive ? 'active' : ''
              } 
              ${isComplete ? 'complete' : ''}
              ${centerStep ? ' center-step' : ''} ${
                lastStep ? 'last-step' : ''
              } ${i === 0 ? 'first-step' : ''}`}
            >
              <button
                type="button"
                className="step btn btn-text-only"
                onClick={() => {
                  if (handleStepChange && isComplete) {
                    handleStepChange(i)
                  }
                }}
              >
                <p
                  className={`m-0 f-24 ${
                    i > activeStep ? 'color-border-color' : ''
                  }`}
                >
                  {i + 1}. {step}
                </p>
                <div className="indicator" />
              </button>
            </div>
          )
        })}
      </div>
    </div>
    <MobileStepper activeStep={activeStep} steps={steps} />
  </>
)

Stepper.defaultProps = {
  handleStepChange: null,
}

Stepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleStepChange: PropTypes.func,
}

export default Stepper
