import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import routes from '../configuration/routes'
import SimpleHeader from '../components/structure/SimpleHeader'
import Footer from '../components/structure/Footer'

const RouteNotFound = ({ noHeader, noFooter }) => (
  <>
    {!noHeader && <SimpleHeader />}
    <div className="p-20 text-center flex-grow">
      <h1>Page not found</h1>
      <Link to={routes.dashboard}>Home</Link>
    </div>
    {!noFooter && <Footer />}
  </>
)

RouteNotFound.defaultProps = {
  noHeader: false,
  noFooter: false,
}

RouteNotFound.propTypes = {
  noHeader: PropTypes.bool,
  noFooter: PropTypes.bool,
}

export default RouteNotFound
