import React from 'react'
import PropTypes from 'prop-types'

const Radio = ({
  renderLabel,
  options,
  name,
  onChange,
  error,
  errorShowOnTop,
  checked,
}) => (
  <div>
    {error && errorShowOnTop && <p className="color-error my-4">{error}</p>}
    <div className="radio-group">
      {options.map(option => (
        <div
          key={`${option.label}-${option.value}`}
          className="radio-container"
        >
          <input
            type="radio"
            name={name}
            id={name + option.value}
            value={option.value}
            checked={checked === option.value}
            onChange={onChange}
          />
          <div className="radio-labels">
            <label htmlFor={name + option.value} className="radio-label">
              {option.label}
            </label>
            {option.meta && (
              <label htmlFor={name + option.value} className="radio-meta">
                {option.meta}
              </label>
            )}
          </div>
        </div>
      ))}
      <span>{renderLabel()}</span>
    </div>
    {error && !errorShowOnTop && (
      <p className="color-error remove-margin mt-4">{error}</p>
    )}
  </div>
)

Radio.defaultProps = {
  error: null,
  errorShowOnTop: false,
  checked: '',
}

Radio.propTypes = {
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  renderLabel: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  errorShowOnTop: PropTypes.bool,
  checked: PropTypes.string,
}

export default Radio
