import moment from 'moment'
import { set } from 'lodash'
import {
  convertEditorStateToPlainText,
  convertEditorStateToRaw,
} from '../../../components/elements/WYSIWYG/utilities'
import { NON_MEMBER_ACCOUNT_ID } from '../../../configuration/constants'

export const getTimeString = (values, isEndDate) => {
  const requiresSetTimes = values?.callTimes || values?.allDayEvent

  const startTime =
    requiresSetTimes || !values.startTimeSelection
      ? '00:00:00'
      : values.startTimeSelection

  if (!isEndDate) {
    return startTime
  }

  if (requiresSetTimes) {
    return '23:59:00'
  }

  return (
    values.endTimeSelection ||
    moment(startTime, 'HH:mm:ss').add(1, 'hours').format('HH:mm:ss')
  )
}
export const formatFormDataForSubmission = ({
  selectedPartnerId,
  eventDetails,
  locationDetails,
  timeDateDetails,
  contactDetails,
  rrule,
}) => {
  const startTimeSelection = getTimeString(timeDateDetails)
  const endTimeSelection = getTimeString(timeDateDetails, true)
  // gets the local start time, which then is converted to UTC for saving purposes
  const startDate = moment.utc(
    `${moment(timeDateDetails.startDate)
      .utc()
      .format('YYYY-MM-DD')} ${startTimeSelection}`,
  )

  const endDate = moment.utc(
    `${moment(timeDateDetails.endDate).format(
      'YYYY-MM-DD',
    )} ${endTimeSelection}`,
  )

  const dateFields = {
    value: moment(startDate).clone().format(),
    infinite: Boolean(
      !timeDateDetails?.callTimes && !timeDateDetails.rruleCount,
    ),
    timezone: 'UTC',
    rrule:
      !timeDateDetails?.callTimes && timeDateDetails.isRepeating ? rrule : '',
    end_value: moment(endDate).clone().format(),
  }

  const socialMediaFields = {
    facebook: {
      value: contactDetails.facebook || '',
    },
    twitter: {
      value: contactDetails.twitter || '',
    },
    instagram: {
      value: contactDetails.instagram || '',
    },
    youtube_channel: {
      value: contactDetails.youtube_channel || '',
    },
  }

  const requestBody = {
    type: 'node--event',
    attributes: {
      title: eventDetails.eventName,

      field_price: eventDetails.admissionDetails,
      moderation_state: 'draft',
      body: {
        value: convertEditorStateToRaw(eventDetails.rawDescriptionData),
        format: 'basic_html',
        summary: convertEditorStateToPlainText(eventDetails.rawDescriptionData),
      },
      field_address: {
        organization: locationDetails.locationName,
        locality: locationDetails.city,
        address_line1: locationDetails.streetAddress,
        address_line2: locationDetails.streetAddress2,
        administrative_area: locationDetails.state,
        country_code: 'US',
        postal_code: locationDetails.zip,
      },
      field_geolocation: {
        lat: parseFloat(locationDetails.latitude),
        lng: parseFloat(locationDetails.longitude),
        value: `${locationDetails.latitude}, ${locationDetails.longitude}`,
      },
      field_directions: locationDetails.directions,
      field_event_date: dateFields,
      field_call_for_times: timeDateDetails.callTimes || false,
      field_all_day_event: timeDateDetails.allDayEvent || false,
      field_coordinator_name: contactDetails.coordinatorName,
      field_coordinator_email: contactDetails.coordinatorEmailAddress,
      field_coordinator_phone: contactDetails.coordinatorPhoneNumber,
      field_public_coordinator_phone: contactDetails.PhoneNumber,
      field_public_toll_free: contactDetails.tollFreePhoneNumber,
      field_social_media: {
        platform_values: socialMediaFields,
      },
      field_calendar: eventDetails.appearSite?.split(',') || [],
    },
    relationships: {
      field_location: {
        data: [
          {
            type: 'taxonomy_term--location_filters',
            id: locationDetails.mainLocation,
          },
        ],
      },
    },
  }

  if (selectedPartnerId && selectedPartnerId !== NON_MEMBER_ACCOUNT_ID) {
    set(requestBody, 'relationships.field_partner.data', [
      {
        type: 'node--partner',
        id: selectedPartnerId,
      },
    ])
  }

  if (eventDetails.webURL?.length) {
    requestBody.attributes.field_website_url = {
      uri: eventDetails.webURL.trim(),
      title: '',
      options: [],
    }
  }

  if (eventDetails.categories && Object.keys(eventDetails.categories)?.length) {
    requestBody.relationships.field_event_categories = {
      data: [],
    }
    Object.entries(eventDetails.categories).forEach(([id, checked]) => {
      if (checked) {
        requestBody.relationships.field_event_categories.data.push({
          type: 'taxonomy_term--event_categories',
          id,
        })
      }
    })
  }

  if (
    eventDetails?.imageUpload?.[0] &&
    Object.keys(eventDetails?.imageUpload[0]).length > 0
  ) {
    const data = eventDetails.imageUpload
      .map(image => ({
        type: 'file--file',
        id: image.drupalId || image.id,
      }))
      .filter(image => !!image.id)

    requestBody.relationships.field_event_image = { data }
  }

  return requestBody
}
