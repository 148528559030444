import React from 'react'
import PropTypes from 'prop-types'
import brandLoader from '../../assets/icons/hhi-logo-black.png'

import '../../styles/elements/brand-loader.scss'

const BrandLoader = ({ containerClasses }) => (
  <div className={containerClasses || 'brand-loader'}>
    <img src={brandLoader} alt="Brand Loading Animation" />
  </div>
)

BrandLoader.defaultProps = {
  containerClasses: null,
}
BrandLoader.propTypes = {
  containerClasses: PropTypes.string,
}
export default BrandLoader
