import React from 'react'

import '../../styles/elements/simple-spinner.scss'

export default function SimpleSpinner() {
  return (
    <div className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}
