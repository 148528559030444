import moment from 'moment'

export function supportedTime() {
  const timeList = [{ label: 'Time', value: '' }]
  let i = 1
  while (i <= 24) {
    timeList[i] = {
      label: moment('2021-05-20 12:00am', 'YYYY-MM-DD h:mm:ss a')
        .add(i - 1, 'hours')
        .format('h:mma'),
      value: moment('2021-05-20 12:00am', 'YYYY-MM-DD h:mm:ss a')
        .add(i - 1, 'hours')
        .format('HH:mm:ss'),
    }
    i += 1
  }

  return timeList
}
