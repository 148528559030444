import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  fetchPartners,
  selectPartnerState,
} from '../../redux/partners/userPartners'
import { updateAccountMembers } from '../../api/api'
import SimpleSpinner from '../../components/elements/SimpleSpinner'
import { selectUserToken } from '../../redux/authentication/authentication'
import routes from '../../configuration/routes'
import { selectUserUid } from '../../redux/user/fetchUser'

function renderUserName(user) {
  if (user?.field_first_name || user?.field_last_name) {
    return `${user?.field_first_name} ${user?.field_last_name}`
  }
  return user.name
}

const MemberManagement = ({ handleRouteChange }) => {
  const partnerState = useSelector(selectPartnerState)
  const userToken = useSelector(selectUserToken)
  const userUid = useSelector(selectUserUid)
  const dispatch = useDispatch()
  const { id } = useParams()

  const currentPartner = partnerState?.data?.[id]
  const allUsers = currentPartner?.allUsers || []
  const [memberStatuses, setMemberStatuses] = useState(
    allUsers.reduce(
      (obj, member) => ({
        ...obj,
        [member.uuid]: member.role || 'pending',
      }),
      {},
    ),
  )

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  function toggleMemberStatus(uuid, role) {
    setMemberStatuses({
      ...memberStatuses,
      [uuid]: role,
    })
  }

  function handleSubmit() {
    setIsLoading(true)
    setError(null)
    const formattedId = Object.entries(memberStatuses).reduce(
      (obj, [memberId, status]) => {
        obj[status].push(memberId)

        return obj
      },
      {
        pending: [],
        member: [],
        admin: [],
        remove: [],
      },
    )

    const members = {
      pendingMemberIds: formattedId.pending,
      memberIds: formattedId.member,
      adminIds: formattedId.admin,
    }

    return updateAccountMembers({
      token: userToken,
      members,
      accountId: currentPartner?.accountData?.uuid,
    })
      .then(() => {
        setIsLoading(false)
        dispatch(fetchPartners(userUid))
        handleRouteChange(routes.dashboard)
      })
      .catch(err => {
        setError(
          err?.response?.data?.errors?.[0]?.detail ||
            'unable to update member status',
        )
        setIsLoading(false)
      })
  }

  return (
    <div className="bg-light-grey px-2 py-10 full-height">
      <div className="container-centered-lg">
        <div className="py-8">
          <h1>{currentPartner?.title} Members</h1>
        </div>

        {allUsers?.length > 0 && (
          <div>
            {allUsers.map(member => {
              const memberId = member.uuid
              return (
                <div
                  key={memberId}
                  className="flex flex-align-center py-2 container-sm"
                >
                  <p className="m-0 flex-grow half-width flex-no-shrink">
                    {renderUserName(member)}
                  </p>
                  <select
                    onChange={e => toggleMemberStatus(memberId, e.target.value)}
                    value={memberStatuses[memberId]}
                  >
                    <option value="admin">Admin</option>
                    <option value="member">Editor</option>
                    <option value="pending">Pending</option>
                    <option value="remove">Remove</option>
                  </select>
                </div>
              )
            })}
            <div className="pt-8">
              {error && <p>{error}</p>}
              {isLoading ? (
                <SimpleSpinner />
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  Save changes
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

MemberManagement.propTypes = {
  handleRouteChange: PropTypes.func.isRequired,
}

export default MemberManagement
