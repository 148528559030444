import React from 'react'
import { useSelector } from 'react-redux'

import { Link } from 'react-router-dom'

import routes from '../../configuration/routes'
import SubmissionsList from '../../components/elements/SubmissionsList/SubmissionsList'

import OrganizationCard from '../../components/elements/OrganizationCard/OrganizationCard'

// STYLES
import './styles.scss'

import {
  selectHasPartners,
  selectPartnerState,
  selectUserPartners,
} from '../../redux/partners/userPartners'
import { selectActivePartner } from '../../redux/activePartner/activePartner'
import SimpleSpinner from '../../components/elements/SimpleSpinner'

const OrganizationsContainer = () => {
  const partners = useSelector(selectUserPartners)
  const partnerState = useSelector(selectPartnerState)
  const activePartner = useSelector(selectActivePartner)
  const hasPartners = useSelector(selectHasPartners)
  function renderPartnersList() {
    if (partnerState.loading) {
      return (
        <div className="flex flex-justify-center">
          <SimpleSpinner />
        </div>
      )
    }
    if (partners?.length) {
      return (
        <>
          {partners.map(partner => (
            <div className="py-8-lg-up pb-8-lg-max" key={partner.id}>
              <OrganizationCard
                partner={partner}
                isActive={activePartner?.id === partner.id}
              />
            </div>
          ))}
        </>
      )
    }

    return <p>No Organizations Found</p>
  }

  return (
    <div
      data-testid="dashboard-container"
      className="organizations-container flex py-12-lg-up pt-12-lg-max pb-10-lg-max px-2 px-8-lg-max flex-column-lg-max mx-auto"
    >
      <div className="organizations-container__left-section">
        <div className="flex flex-space-between flex-align-center full-width flex-column-lg-max flex-align-start-lg-max pb-8-lg-up">
          <h1 className="normal remove-margin">Your Organizations</h1>
          <Link
            className="border-none py-6 px-8 my-8-lg-max border-radius-3 color-white font-body f-15 bold bg-primary-color uppercase no-text-decoration"
            to={routes.joinOrganization}
          >
            + Join New
          </Link>
        </div>
        <div className="pb-4-lg-max">{renderPartnersList()}</div>
      </div>
      {hasPartners && (
        <div className="organizations-container__right-section flex flex-column ml-8 ml-0-lg-max ">
          <SubmissionsList type="event" linkTo={routes.events} />
          <SubmissionsList type="offer" linkTo={routes.offers} />
          <SubmissionsList type="job" linkTo={routes.jobs} />
        </div>
      )}
    </div>
  )
}

export default OrganizationsContainer
