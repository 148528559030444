/* eslint-disable import/no-cycle */
import moment from 'moment'
import { getOffers } from '../../api/api'
import {
  selectPartnerState,
  selectUserPartnerIds,
} from '../partners/userPartners'
import { selectActivePartner } from '../activePartner/activePartner'
import { selectUserUuid } from '../user/fetchUser'

// CONSTANTS
export const FETCH_OFFERS_BEGIN = 'FETCH_OFFERS_BEGIN'
export const FETCH_OFFERS_ERROR = 'FETCH_OFFERS_ERROR'
export const FETCH_OFFERS_SUCCESS = 'FETCH_OFFERS_SUCCESS'
export const CLEAR_OFFERS = 'CLEAR_OFFERS'

// ACTIONS
export const fetchOffers = uid => dispatch => {
  dispatch({ type: FETCH_OFFERS_BEGIN })
  return getOffers(uid)
    .then(res => {
      if (res?.data?.data) {
        const payload = res?.data?.data
          .sort((a, b) =>
            moment(a.changed).isBefore(moment(b.changed)) ? 1 : -1,
          )
          .reduce((obj, offer) => {
            const id = offer?.uuid
            return {
              ...obj,
              [id]: formatOffer(offer),
            }
          }, {})
        dispatch({ type: FETCH_OFFERS_SUCCESS, payload })
      } else {
        dispatch({
          type: FETCH_OFFERS_ERROR,
          payload: 'Unable to retrieve offers',
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_OFFERS_ERROR,
        payload: err?.response?.message || 'Unable to retrieve offers',
      })
    })
}

// REDUCER
export default function offerReducer(
  state = { loading: false, error: null, data: null },
  action,
) {
  switch (action.type) {
    case FETCH_OFFERS_BEGIN:
      return {
        ...state,
        loading: true,
      }
    case FETCH_OFFERS_ERROR:
      return {
        loading: false,
        error: action.payload || 'Something went wrong',
        data: null,
      }

    case FETCH_OFFERS_SUCCESS:
      return {
        loading: false,
        error: null,
        data: action.payload,
      }
    case CLEAR_OFFERS:
      return {
        ...state,
        data: null,
      }
    default:
      return state
  }
}

// SELECTORS
export const selectOffersState = state => state.offersData

export const selectOffers = state => {
  if (!state?.offersData?.data || !Object.keys(state?.offersData?.data).length)
    return null

  const partners = selectPartnerState(state)
  const userUuid = selectUserUuid(state)

  return Object.values(state.offersData.data).map(offer => {
    const accountData = offer?.field_partner?.field_account || null

    const adminUsers = accountData?.field_admin_user?.length
      ? accountData?.field_admin_user
          ?.filter(user => !!user)
          .map(user => ({ ...user, role: 'admin' }))
      : []

    const isAdmin = adminUsers.some(user => user?.uuid === userUuid)
    return {
      ...offer,
      isOwner: userUuid && offer?.createdBy?.uuid === userUuid,
      userRole: partners?.data?.[offer?.partnerId]?.userRole,
      canEdit: userUuid && (offer?.createdBy?.uuid === userUuid || isAdmin),
    }
  })
}

export const selectUserOffers = state => {
  const partnerIds = selectUserPartnerIds(state)
  const allOffers = selectOffers(state)
  return allOffers?.length && partnerIds?.length
    ? allOffers.filter(offer => partnerIds.some(id => id === offer.partnerId))
    : null
}

export const selectActivePartnerOffers = state => {
  const allOffers = selectUserOffers(state)

  const activePartnerId = selectActivePartner(state)?.id

  return allOffers && activePartnerId
    ? allOffers.filter(offer => offer.partnerId === activePartnerId)
    : []
}

export const selectOfferById = (state, id) =>
  state?.offersData?.data?.[id] || null

// UTILITIES
const formatOffer = offer => {
  const formattedOfferImage = offer?.field_offer_image?.uuid
    ? {
        ...offer.field_offer_image,
        src: offer.field_offer_image?.url,
        drupalId: offer.field_offer_image.uuid,
      }
    : null
  const isLive = offer?.status
  const isPendingRevision = !!offer?.revision_pending

  return {
    ...offer,
    id: offer.uuid,
    formattedOfferImage,
    partnerId: offer?.field_partner?.uuid,
    createdBy: offer.uid || {},
    isLive,
    hasLiveRevision: isPendingRevision && !isLive,
  }
}
