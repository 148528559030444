import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { formInitialValues } from '../../utilities/content/generateFormData'
import ProfileSettingsForm from './forms/ProfileSettingsForm'
import { renderInitialFormFields } from './forms/formFields/profileSettingsFormFields'

import './styles.scss'
import BrandLoader from '../../components/elements/BrandLoader'
import { formatFormDataForSubmission } from './utilities/formatProfileSettings'
import {
  selectUserToken,
  updateCsrfToken,
} from '../../redux/authentication/authentication'
import { fetchUser, selectUserData } from '../../redux/user/fetchUser'
import { getCSRFToken, updateUser } from '../../api/api'
import routes from '../../configuration/routes'

const ProfileSettings = ({ handleRouteChange }) => {
  const userToken = useSelector(selectUserToken)
  const currentUser = useSelector(selectUserData)
  const initialFormData = renderInitialFormFields(currentUser.data)

  const [formValues, setFormValues] = useState(
    formInitialValues(initialFormData),
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)

  const dispatch = useDispatch()

  if (
    ((currentUser?.loading || !currentUser.data) && !currentUser.error) ||
    isSubmitting
  ) {
    return (
      <div className="container-centered container-sm text-center pt-14">
        <BrandLoader />
      </div>
    )
  }
  function handleSubmit(profileSettingsDetails) {
    setIsSubmitting(true)
    setError(null)
    const reqBody = formatFormDataForSubmission({
      ...profileSettingsDetails,
    })

    return updateUser({
      reqBody,
      token: userToken,
      uid: currentUser.data.id,
    })
      .then(async () => {
        if (profileSettingsDetails?.password) {
          await getCSRFToken().then(res => {
            dispatch(updateCsrfToken(res?.data))
          })
        }
        dispatch(fetchUser(currentUser?.data?.id))
        setIsSubmitting(false)
        handleRouteChange(routes.dashboard)
      })
      .catch(err => {
        setError(err?.response?.data?.message || 'Unable update profile')
        setIsSubmitting(false)
      })
  }

  return (
    <div className="bg-light-grey px-2 py-10 full-height create-offer">
      <div className="container-centered-lg">
        <div className="py-8">
          <ProfileSettingsForm
            submit={profileSettingsDetails => {
              setFormValues(profileSettingsDetails)
              return handleSubmit(profileSettingsDetails)
            }}
            initialValues={formValues}
            profileSettingsFormFields={initialFormData}
            error={error}
          />
        </div>
      </div>
    </div>
  )
}

ProfileSettings.propTypes = {
  handleRouteChange: PropTypes.func.isRequired,
}

export default ProfileSettings
