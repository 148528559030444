import React from 'react'
import { Switch, Route, Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import routes from '../configuration/routes'

import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'

// VIEWS
import LoginContainer from './LoginContainer/LoginContainer'
import RegisterContainer from './RegisterContainer/RegisterContainer'
import OrganizationsContainer from './OrganizationsContainer/OrganizationsContainer'
import RegisterOrganization from './RegisterOrganization/RegisterOrganization'
import SubmissionsContainer from './Submissions/SubmissionsContainer'
import EditEvent from './EditEvent/EditEvent'
import JoinOrganizationContainer from './JoinOrganizationContainer/JoinOrganizationContainer'
import CreateOffer from './CreateOffer/CreateOffer'
import RouteNotFound from './RouteNotFound'
import ProfileSettings from './ProfileSettings/ProfileSettings'
import EditPartner from './EditPartner/EditPartner'
import ForgotPassword from './ForgotPassword/ForgotPassword'
import ResetPassword from './ForgotPassword/ResetPassword'
import PostJob from './PostJob/PostJob'
import MemberManagement from './MemberManagement/MemberManagement'
import ContentPage from './ContentPage/ContentPage'
import SimpleHeader from '../components/structure/SimpleHeader'
import Header from '../components/structure/Header'
import Footer from '../components/structure/Footer'

/*
 * When adding new routes make sure to use the PublicRoute or PrivateRoute components
 *  unless the route should be available to either
 * PrivateRoute contains the DefaultLayout as at the time of writing this comment
 *  it is used on all authenticated routes
 * */

const Views = ({ handleRouteChange, isAuthenticated, hasPartners }) => (
  <Switch>
    <Route exact path="/user/login">
      <Redirect to={routes.login} />
    </Route>
    <PublicRoute exact path={routes.login} isAuthenticated={isAuthenticated}>
      <LoginContainer handleRouteChange={handleRouteChange} />
    </PublicRoute>
    <PublicRoute
      exact
      path={routes.registration}
      isAuthenticated={isAuthenticated}
    >
      <RegisterContainer handleRouteChange={handleRouteChange} />
    </PublicRoute>
    <Route exact path={routes.forgotPassword}>
      <ForgotPassword />
    </Route>
    <Route exact path={routes.resetPassword}>
      <ResetPassword handleRouteChange={handleRouteChange} />
    </Route>
    <PrivateRoute
      exact
      path={routes.dashboard}
      isAuthenticated={isAuthenticated}
    >
      <OrganizationsContainer handleRouteChange={handleRouteChange} />
    </PrivateRoute>
    <PrivateRoute exact path={routes.events} isAuthenticated={isAuthenticated}>
      <SubmissionsContainer type="event" />
    </PrivateRoute>
    <PrivateRoute exact path={routes.offers} isAuthenticated={isAuthenticated}>
      {hasPartners ? (
        <SubmissionsContainer type="offer" />
      ) : (
        <NoPartnerMessage />
      )}
    </PrivateRoute>
    <PrivateRoute exact path={routes.jobs} isAuthenticated={isAuthenticated}>
      {hasPartners ? <SubmissionsContainer type="job" /> : <NoPartnerMessage />}
    </PrivateRoute>
    <PrivateRoute
      exact
      path={routes.registerOrganization}
      isAuthenticated={isAuthenticated}
    >
      <RegisterOrganization handleRouteChange={handleRouteChange} />
    </PrivateRoute>
    <PrivateRoute
      exact
      path={routes.profileSettings}
      isAuthenticated={isAuthenticated}
    >
      <ProfileSettings handleRouteChange={handleRouteChange} />
    </PrivateRoute>
    <PrivateRoute
      exact
      path={routes.createEvent}
      isAuthenticated={isAuthenticated}
    >
      <EditEvent handleRouteChange={handleRouteChange} />
    </PrivateRoute>
    <PrivateRoute
      exact
      path={`${routes.editEvent}:id`}
      isAuthenticated={isAuthenticated}
    >
      <EditEvent handleRouteChange={handleRouteChange} />
    </PrivateRoute>
    <PrivateRoute
      exact
      isAuthenticated={isAuthenticated}
      path={routes.registrationStepTwo}
    >
      <JoinOrganizationContainer
        handleRouteChange={handleRouteChange}
        title="Create a Personal Profile"
      />
    </PrivateRoute>
    <PrivateRoute
      exact
      isAuthenticated={isAuthenticated}
      path={routes.joinOrganization}
    >
      <JoinOrganizationContainer handleRouteChange={handleRouteChange} />
    </PrivateRoute>
    <PrivateRoute
      exact
      path={routes.createOffer}
      isAuthenticated={isAuthenticated}
    >
      {hasPartners ? (
        <CreateOffer handleRouteChange={handleRouteChange} />
      ) : (
        <NoPartnerMessage />
      )}
    </PrivateRoute>
    <PrivateRoute
      exact
      path={`${routes.editOffer}:id`}
      isAuthenticated={isAuthenticated}
    >
      {hasPartners ? (
        <CreateOffer handleRouteChange={handleRouteChange} />
      ) : (
        <NoPartnerMessage />
      )}
    </PrivateRoute>
    <Route path={routes.faq}>
      <>
        {isAuthenticated ? <Header /> : <SimpleHeader />}
        <ContentPage slug="faq" />
        {isAuthenticated && <Footer />}
      </>
    </Route>
    <Route path={routes.contactUs}>
      <>
        {isAuthenticated ? <Header /> : <SimpleHeader />}
        <ContentPage slug="contact-us" />
        {isAuthenticated && <Footer />}
      </>
    </Route>
    <PrivateRoute exact path={routes.postJob} isAuthenticated={isAuthenticated}>
      <PostJob />
    </PrivateRoute>
    <PrivateRoute
      exact
      path={`${routes.postJob}:id`}
      isAuthenticated={isAuthenticated}
    >
      {hasPartners ? <PostJob /> : <NoPartnerMessage />}
    </PrivateRoute>
    <Route path={routes.termsConditions}>
      <>
        {isAuthenticated ? <Header /> : <SimpleHeader />}
        <ContentPage slug="terms-conditions" />
        {isAuthenticated && <Footer />}
      </>
    </Route>
    <Route path={routes.userGuide}>
      <>
        {isAuthenticated ? <Header /> : <SimpleHeader />}
        <ContentPage slug="user-guide" />
        {isAuthenticated && <Footer />}
      </>
    </Route>
    <PrivateRoute
      exact
      path={`${routes.editPartner}:id`}
      isAuthenticated={isAuthenticated}
    >
      {hasPartners ? (
        <EditPartner handleRouteChange={handleRouteChange} />
      ) : (
        <NoPartnerMessage />
      )}
    </PrivateRoute>
    <PrivateRoute
      exact
      path={`${routes.editPartnerMembers}:id`}
      isAuthenticated={isAuthenticated}
    >
      {hasPartners ? (
        <MemberManagement handleRouteChange={handleRouteChange} />
      ) : (
        <NoPartnerMessage />
      )}
    </PrivateRoute>
    <Route>
      <RouteNotFound />
    </Route>
  </Switch>
)

const NoPartnerMessage = () => (
  <div className="px-2 py-10 container-centered container-md text-center">
    <h1>An organization is required.</h1>
    <p className="f-18">
      <Link
        className="btn btn-text-only no-text-decoration"
        to={routes.joinOrganization}
      >
        Join
      </Link>{' '}
      or{' '}
      <Link
        className="btn btn-text-only no-text-decoration"
        to={routes.registerOrganization}
      >
        Create
      </Link>{' '}
      one to continue
    </p>
  </div>
)

Views.propTypes = {
  handleRouteChange: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  hasPartners: PropTypes.bool.isRequired,
}

export default Views
