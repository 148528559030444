import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import EventList from './EventList'
import OfferList from './OfferList'
import JobList from './JobList'
import {
  selectActivePartner,
  selectActivePartnerUserRole,
} from '../../redux/activePartner/activePartner'
import { selectHasFieldAccountCode } from '../../redux/partners/userPartners'

const SubmissionsContainer = ({ type }) => {
  const activePartnerUserRole = useSelector(selectActivePartnerUserRole)
  const activePartner = useSelector(selectActivePartner)
  const activeAccountCode = useSelector(state =>
    selectHasFieldAccountCode(state, activePartner?.id),
  )

  const hasPermissions = Boolean(
    activePartnerUserRole &&
      activeAccountCode &&
      activePartnerUserRole !== 'pending',
  )

  let ListComponent = EventList
  switch (type) {
    case 'offer':
      ListComponent = OfferList
      break
    case 'event':
      ListComponent = EventList
      break
    case 'job':
      ListComponent = JobList
      break
    default:
      ListComponent = EventList
      break
  }
  return (
    <div className="full-width full-height py-14">
      <ListComponent hasPermissions={hasPermissions} />
    </div>
  )
}

SubmissionsContainer.propTypes = {
  type: PropTypes.string.isRequired,
}

export default SubmissionsContainer
