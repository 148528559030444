import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { selectActivePartnerJobs } from '../../redux/jobs/jobs'

import SubmissionCard from '../../components/elements/SubmissionCard/SubmissionCard'
import routes from '../../configuration/routes'
import { renderDisplayName } from './utilities'
import { selectActivePartner } from '../../redux/activePartner/activePartner'
import { NON_MEMBER_ACCOUNT_ID } from '../../configuration/constants'

export default function JobList({ hasPermissions }) {
  const jobs = useSelector(selectActivePartnerJobs)
  const activePartnerId = useSelector(selectActivePartner)

  return (
    <div className="container-centered-lg">
      <div className="pb-8 pb-0-lg-max flex full-width flex-space-between flex-align-center px-6-lg-max flex-wrap">
        <h1 className="normal remove-margin"> Your Job Submissions</h1>
        {(hasPermissions || activePartnerId?.id === NON_MEMBER_ACCOUNT_ID) && (
          <Link
            to={routes.postJob}
            className="border-none py-6 px-8 my-8-lg-max border-radius-3 color-white font-body f-15 bold bg-primary-color uppercase no-text-decoration"
            style={{ minWidth: '165px' }}
          >
            + Create New
          </Link>
        )}
      </div>
      {(!jobs || jobs.length === 0) && (
        <p className="pb-8 pb-0-lg-max pt-4-lg-max flex full-width flex-space-between flex-align-center px-6-lg-max flex-wrap">
          No jobs were found.
        </p>
      )}
      {jobs?.length > 0 &&
        jobs.map(job => (
          <div key={job.id} className="flex flex-column">
            <SubmissionCard
              id={job.id}
              status={job.status}
              title={job.title}
              type="job"
              date={job.created}
              author={renderDisplayName(job)}
              hasPermissions={job.canEdit}
              isLocked={job?.hasLiveRevision}
              archived={job?.moderation_state === 'archived'}
            />
          </div>
        ))}
    </div>
  )
}

JobList.propTypes = {
  hasPermissions: PropTypes.bool.isRequired,
}
