import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { selectUserToken } from '../../../redux/authentication/authentication'
import { unpublishJob } from '../../../api/api'

import StatusPill from '../StatusPill/StatusPill'

import routes from '../../../configuration/routes'
// ASSETS
import edit from '../../../assets/icons/edit.svg'
// STYLES
import './styles.scss'

const SubmissionCard = ({
  status,
  type,
  title,
  property,
  date,
  author,
  id,
  hasPermissions,
  isLocked,
  archived,
}) => {
  const userToken = useSelector(selectUserToken)

  let editText = 'Event'
  let editLink = `${routes.editEvent}${id}`
  switch (type) {
    case 'event':
      editText = 'Event'
      editLink = `${routes.editEvent}${id}`
      break
    case 'offer':
      editText = 'Offer'
      editLink = `${routes.editOffer}${id}`
      break
    case 'job':
      editText = 'Job'
      editLink = `${routes.postJob}${id}`
      break
    default:
      // do nothing
      break
  }
  return (
    <div className="submission-card bg-white flex flex-align-center-lgr-up flex-wrap flex-column-lgr-max">
      <div className="flex-third">
        <div className="flex flex-row">
          <p
            className={`${
              property === null ? 'm-0' : 'mb-1 mb-3-lgr-max'
            } bold`}
          >
            {title}
          </p>
          <div className="lgr-max submission-card__status-mobile">
            <StatusPill
              status={status}
              isLocked={isLocked}
              pendingBbClass="bg-field-light-grey"
              archived={archived}
            />
          </div>
        </div>
        <p className="f-12 remove-margin">
          {property?.length && (
            <span className="bold">Published on: {property}</span>
          )}
        </p>
      </div>
      <div className="flex flex-half flex-align-end-lgr-max">
        <div className="f-12 flex flex-15 flex-lgr-max flex-column-lgr-max flex-align-center">
          <span className="bold">
            Submitted<span className="mobile-only"> on</span>
          </span>
          {`: ${moment(date).format('MMM D, YYYY')}`}
        </div>
        <div className="f-12 flex flex-15 flex-lgr-max flex-column-lgr-max flex-align-center">
          <span className="bold">Submitted by</span>
          {`: ${author}`}
        </div>
        {hasPermissions && !isLocked && status && (
          <div className="submission-card__edit flex flex-align-center flex-justify-center-lgr-up flex-justify-end-lgr-max flex-10">
            <ul className="submission-card__edit-actions">
              <li>
                <img src={edit} alt="edit" className="pr-2" />
                <Link
                  to={editLink}
                  className="submission-card__edit-link f-14 bold cursor-pointer"
                >
                  Edit
                  <span className="desktop-only"> {editText}</span>
                </Link>
              </li>
              {type === 'job' && status && (
                <li>
                  <button
                    className="submission-card__edit-link f-14 bold cursor-pointer ml-2"
                    type="button"
                    onClick={() => {
                      if (
                        // eslint-disable-next-line no-alert
                        window.confirm(
                          'Are you sure you want to unpublish this job posting?',
                        )
                      ) {
                        unpublishJob({
                          token: userToken,
                          currentJobId: id,
                        })
                      }
                    }}
                  >
                    Unpublish
                  </button>
                </li>
              )}
            </ul>
          </div>
        )}
        <div className="submission-card__status flex-lgr-up flex-align-center flex-justify-end flex-10">
          <StatusPill
            status={status}
            isLocked={isLocked}
            pendingBbClass="bg-field-light-grey"
            archived={archived}
          />
        </div>
      </div>
    </div>
  )
}

SubmissionCard.defaultProps = {
  property: null,
  archived: false,
}

SubmissionCard.propTypes = {
  status: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['event', 'offer', 'job']).isRequired,
  title: PropTypes.string.isRequired,
  property: PropTypes.string,
  date: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  hasPermissions: PropTypes.bool.isRequired,
  isLocked: PropTypes.bool.isRequired,
  archived: PropTypes.bool,
}

export default SubmissionCard
