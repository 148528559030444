import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import {
  formInitialValues,
  formValidation,
} from '../../../utilities/content/generateFormData'
import FormikInput from '../../../components/elements/FormikInput'

import '../styles.scss'
import { NON_MEMBER_ACCOUNT_ID } from '../../../configuration/constants'

const OrganizationSelect = ({
  submit,
  initialValues,
  partnerList,
  organizationSelectFields,
  disabled,
  editing,
}) => {
  const partnerOptions = [
    {
      label: 'Please Select',
      value: '',
      disabled: true,
    },
  ]

  if (partnerList?.length) {
    partnerList.forEach(partner => {
      if (
        partner?.id?.length &&
        partner?.title?.length &&
        (partner?.id === NON_MEMBER_ACCOUNT_ID ||
          (partner?.userRole && partner?.userRole !== 'pending'))
      ) {
        partnerOptions.push({
          label: partner?.title || '',
          value: partner?.id,
          disabled: false,
        })
      }
    })
  }

  return (
    <div className=" flex flex-align-center container-centered-lg flex-column-lg-max">
      <h1 className="f-24 f-16-lg-max normal font-heading mb-0 mr-8">
        {editing ? 'Edit' : 'Create'} an Event For
      </h1>
      <Formik
        initialValues={
          initialValues || formInitialValues(organizationSelectFields)
        }
        onSubmit={submit}
        validationSchema={formValidation(organizationSelectFields)}
      >
        {({ handleSubmit }) => (
          <form
            onChange={handleSubmit}
            className="edit-event__create-event mt-4-lg-max"
          >
            <FormikInput
              {...organizationSelectFields.organizationName}
              options={partnerOptions}
              disabled={disabled}
            />
          </form>
        )}
      </Formik>
    </div>
  )
}

OrganizationSelect.defaultProps = {
  initialValues: {},
  partnerList: [],
  disabled: false,
}

OrganizationSelect.propTypes = {
  submit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  partnerList: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
  organizationSelectFields: PropTypes.shape({
    organizationName: PropTypes.shape({}),
  }).isRequired,
  editing: PropTypes.bool.isRequired,
}

export default OrganizationSelect
