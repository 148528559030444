import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import 'react-dates/initialize'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import BrandLoader from '../../components/elements/BrandLoader'

import Views from '../../views/Views'
import routes from '../../configuration/routes'
import {
  checkAuthState,
  selectIsAuthenticated,
} from '../../redux/authentication/authentication'
import { authAppDataIsLoading } from '../../redux/selectors'
import { selectHasPartners } from '../../redux/partners/userPartners'

function App() {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const hasPartners = useSelector(selectHasPartners)
  const loadingAppData = useSelector(authAppDataIsLoading)

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(checkAuthState())
    }
  }, [])

  function handleRouteChange(target, state = null, searchParams = null) {
    const params = searchParams ?? location.search
    history.push(target + params, state)
    window.scrollTo(0, 0)
  }

  const handleTitleChange = () => {
    if (
      location.pathname === routes.termsConditions ||
      location.pathname === `${routes.termsConditions}/`
    ) {
      return 'Terms & Conditions'
    }
    if (
      location.pathname === routes.privacyPolicy ||
      location.pathname === `${routes.privacyPolicy}/`
    ) {
      return 'Privacy Policy'
    }
    return 'Log In or Create Your Profile | Hilton Head Island - Bluffton Partner Portal'
  }

  return (
    <>
      <Helmet>
        <title>
          {isAuthenticated
            ? 'Hilton Head Island - Bluffton Partner Portal'
            : handleTitleChange()}
        </title>
      </Helmet>

      <div className="hhi_pmc flex flex-column " data-testid="hhi_pmc">
        {loadingAppData ? (
          <div className="container-centered container-md text-center pt-8">
            <BrandLoader />
          </div>
        ) : (
          <Views
            handleRouteChange={handleRouteChange}
            isAuthenticated={isAuthenticated}
            hasPartners={hasPartners}
          />
        )}
      </div>
    </>
  )
}

export default App
