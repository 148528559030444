// https://jpuri.github.io/react-draft-wysiwyg/#/docs under toolbar
const wysiwygSettings = {
  wrapperClassName: 'custom-wysiwyg',
  editorClassName: 'editor',
  toolbarClassName: 'toolbar',
  toolbar: {
    options: ['inline', 'link', 'list', 'blockType', 'remove'],
    inline: {
      options: ['bold', 'italic'],
    },
    // empty will hide these, null breaks
    fontFamily: {},
    image: {},
    blockType: {
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
    },
    textAlign: {},
    list: {
      options: ['unordered', 'ordered'],
    },
  },
}

export default wysiwygSettings
