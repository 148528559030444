import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import * as Yup from 'yup'

import {
  loginUser,
  selectAuthenticationState,
} from '../../redux/authentication/authentication'
import routes from '../../configuration/routes'
import {
  formInitialValues,
  formValidation,
} from '../../utilities/content/generateFormData'
import formErrors from '../../configuration/formErrors'
import { selectUserDataError } from '../../redux/user/fetchUser'

// ASSETS
import logo from '../../assets/images/logo.svg'
import textLogo from '../../assets/images/hhi-text-logo.svg'

// STYLES
import './styles.scss'

import FormikInput from '../../components/elements/FormikInput'
import SimpleSpinner from '../../components/elements/SimpleSpinner'

const formFields = {
  username: {
    name: 'username',
    type: 'text',
    placeholder: 'Username or Email Address',
    validations: Yup.string().required(formErrors.requiredField),
  },
  password: {
    name: 'password',
    type: 'password',
    placeholder: 'Password',
    validations: Yup.string().required(formErrors.requiredField),
  },
}

const LoginContainer = ({ handleRouteChange }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const authState = useSelector(selectAuthenticationState)
  const userDataError = useSelector(selectUserDataError)
  function submit(values) {
    dispatch(loginUser({ ...values })).then(() => {
      handleRouteChange(routes.dashboard)
    })
  }

  return (
    <div
      data-testid="login-container"
      className="login-container bg-dark color-white px-2 px-5-lg-max pb-8 flex-grow"
    >
      <div className="header flex flex-justify-center flex-column flex-align-center pt-12 pb-1-lg-up px-2">
        <img className="hhi-icon-logo" src={logo} alt="HHI Logo" />
        <img
          className="hhi-text-logo mt-2 mb-11 mb-8-lg-max"
          src={textLogo}
          alt="Hilton Head Island-Bluffton Chamber of Commerce"
        />
        <div className="py-9 pt-8-lg-max pb-3-lg-max top-border flex flex-align-center flex-column">
          <h1>Partner Portal</h1>
          <p>
            Need assistance? Please contact Robert Obernier:{' '}
            <a
              href="mailto:robernier@hiltonheadisland.org"
              className="color-white"
            >
              robernier@hiltonheadisland.org
            </a>
          </p>
          <p>
            If you used the previous partner portal, you can continue to access
            your information using the same credentials.
          </p>
        </div>
      </div>
      {/* BODY */}
      <div className="flex-lg-up flex-justify-center login-body">
        {/* LOGIN FORM */}
        <div className="p-15 px-8-lg-max border-custom box-left flex-grow form-container">
          <h2 className="f-24 normal mb-6">Login</h2>
          {location?.state?.message && (
            <p className="color-white">{location?.state?.message}</p>
          )}
          {(authState?.error || userDataError) && (
            <p className="login-error">
              {(authState?.error || userDataError).indexOf(
                'Invalid credentials',
              ) > -1
                ? 'Sorry, unrecognized username or password.'
                : authState?.error || userDataError}
            </p>
          )}
          <Formik
            initialValues={formInitialValues(formFields)}
            onSubmit={submit}
            validationSchema={formValidation(formFields)}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <div className="py-2">
                  <FormikInput {...formFields.username} />
                </div>
                <div className="py-2">
                  <FormikInput {...formFields.password} />
                </div>
                <div className="flex flex-justify-end pt-2 pb-5 font-xs">
                  <Link
                    to={routes.forgotPassword}
                    className="f-14 color-white text-right custom-underline"
                  >
                    I Forgot My Password
                  </Link>
                </div>
                {isSubmitting ? (
                  <div className="flex flex-justify-center">
                    <SimpleSpinner />
                  </div>
                ) : (
                  <button
                    className="btn btn-primary full-width mt-1 py-6"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? '...' : 'Log in'}
                  </button>
                )}
              </form>
            )}
          </Formik>
        </div>

        {/* COPY */}
        <div className="pl-15 pt-15 pb-18 pl-6-lg-max pb-15-lg-max border-custom box-right flex-grow copy-container">
          <h2 className="f-24 normal">Don&apos;t Yet Have a Profile</h2>
          <ul className="py-0 pl-0 pr-5">
            <li className="f-18 normal pl-3 pb-3">
              Register to manage your organizational or business accounts
            </li>
            <li className="f-18 normal pl-3 pb-3">Publish upcoming events</li>
            <li className="f-18 normal pl-3 pb-3">Promote deals and offers</li>
            <li className="f-18 normal pl-3 pb-3">
              Publish open job opportunities
            </li>
            <li className="f-18 normal pl-3 pb-3">
              Manage your business profile on our websites
            </li>
            <li className="f-18 normal pl-3 pb-7">Stay informed</li>
          </ul>
          <Link
            to={routes.registration}
            className="uppercase bold color-white f-15 register-now custom-underline"
          >
            Register now
          </Link>
        </div>
      </div>
    </div>
  )
}

LoginContainer.propTypes = {
  handleRouteChange: PropTypes.func.isRequired,
}

export default LoginContainer
