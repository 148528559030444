import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'

import { useSelector } from 'react-redux'
import businessDetailsFormFields from './formFields/businessDetailsFormFields'
import {
  formInitialValues,
  formValidation,
} from '../../../utilities/content/generateFormData'
import FormikInput from '../../../components/elements/FormikInput'
import { selectBusinessCategories } from '../../../redux/businessCategories/businessCategories'

import LeftChev from '../../../assets/icons/chev-left-blue.svg'
// import WysiwygEditor from '../../../components/elements/WYSIWYG/WysiwygEditor'
import SearchableSelect from '../../../components/elements/SearchableSelect/SearchableSelect'

function renderInitialTextValue(val, options) {
  if (!val || !options?.length) {
    return ''
  }

  const match = options.find(
    option => option.value === val || option.ungerboeckCode === val,
  )

  return match?.label || ''
}

const BusinessDetailsForm = ({
  submit,
  back,
  initialValues,
  assistanceInterestOptions,
}) => {
  const businessCategories = useSelector(selectBusinessCategories)
  return (
    <Formik
      initialValues={
        initialValues || formInitialValues(businessDetailsFormFields)
      }
      onSubmit={submit}
      validationSchema={formValidation(businessDetailsFormFields)}
    >
      {({
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        errors,
        values,
      }) => (
        <form onSubmit={handleSubmit} className="register-org-business-form">
          <div className="py-3 pb-2-lg-max">
            <SearchableSelect
              options={businessCategories}
              handleSelection={(val, obj) => {
                setFieldValue('directoryCategory', obj?.ungerboeckCode || '')
              }}
              placeholder="Business Directory Category *"
              useFloatingLabel
              initialTextValue={renderInitialTextValue(
                values?.directoryCategory,
                businessCategories,
              )}
            />

            {errors[businessDetailsFormFields.directoryCategory] &&
              touched[businessDetailsFormFields.directoryCategory] && (
                <p className="color-error remove-margin">
                  {errors[businessDetailsFormFields.directoryCategory]}
                </p>
              )}
          </div>
          <div className="flex-lg-up pt-3 pt-2-lg-max pb-7">
            <div className="flex-grow pr-4-lg-up pb-2-lg-max">
              <FormikInput {...businessDetailsFormFields.numOfRooms} />
              <p className="f-12 italic font-body remove-margin mt-2">
                (Where Applicable)
              </p>
            </div>

            <div className="flex-grow pl-4-lg-up pt-2-lg-max">
              <FormikInput {...businessDetailsFormFields.numOfRestaurants} />
              <p className="f-12 italic font-body remove-margin mt-2">
                (Where Applicable)
              </p>
            </div>
          </div>
          <div className="pb-7 pb-6-lg-max pt-7 pt-1-lg-max px-2 radios">
            <p className="bold f-18 mb-6-lg-max">
              Is your business handicapped accessible?
            </p>
            <FormikInput {...businessDetailsFormFields.accessible} />
          </div>

          <div className="pb-7 pb-6-lg-max pt-7 pt-6-lg-max px-2 radios">
            <p className="bold f-18 mb-6-lg-max">
              Is your business currently licensed on Hilton Head Island?
            </p>
            <FormikInput {...businessDetailsFormFields.licensed} />
          </div>

          <div className="pb-2 pt-7 pt-6-lg-max px-2">
            <p className="bold f-18 mb-2">
              Are you interested in learning more about how the Chamber may
              assist you with the following?
            </p>
            <p className="mb-6 mb-8-lg-max">
              Choose the one that best describes
            </p>
            <div className="flex flex-wrap flex-column-lg-max assistance-radios">
              <FormikInput
                name="assistanceInterest"
                type="radio"
                options={assistanceInterestOptions}
                checked={values.assistanceInterest}
              />
            </div>
          </div>
          {/*
          <div className="pb-2 pt-8 pt-2-lg-max pb-14 pb-6-lg-max">
            <p className="bold f-18">Description</p>
            <WysiwygEditor
              onChange={value => setFieldValue('body', value)}
              initialState={values?.body || null}
            />
            {touched?.body && errors?.body && (
              <p className="color-error remove-margin">{errors.body}</p>
            )}
            <p className="f-12 my-1">(Max. 500 characters)</p>
          </div>
          
          <div className="py-2 pb-14 pb-6-lg-max">
            <FormikInput {...businessDetailsFormFields.logoUpload} />
          </div>
          <div className="py-2 pb-14 pb-6-lg-max">
            <FormikInput {...businessDetailsFormFields.imageUpload} />
          </div>
          */}
          <div className="pt-10 pt-2-lg-max flex flex-column-lg-max flex-align-center-lg-max">
            <button
              className="btn btn-inverse mr-8-lg-up mb-6-lg-max flex flex-justify-center flex-align-center"
              onClick={back}
              type="button"
            >
              <img src={LeftChev} alt="Left Chevron" className="mr-3" />
              <span>Return to Company Contact Details</span>
            </button>
            <button
              className="btn btn-primary flex-grow"
              type="submit"
              disabled={isSubmitting}
            >
              Continue to Payment
            </button>
          </div>
        </form>
      )}
    </Formik>
  )
}

BusinessDetailsForm.defaultProps = {
  initialValues: null,
  assistanceInterestOptions: [],
}

BusinessDetailsForm.propTypes = {
  submit: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  assistanceInterestOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
}

export default BusinessDetailsForm
