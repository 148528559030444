import * as Yup from 'yup'
import { get, isNil } from 'lodash'

import {
  convertRawToEditorState,
  getTotalLengthFromEditorState,
} from '../../../../components/elements/WYSIWYG/utilities'
import formErrors from '../../../../configuration/formErrors'

export const eventDetailsFormFields = (currentEvent = {}) => {
  const eventBody = currentEvent?.body || null

  const isSiteChecked = (values, valueToCheck) => {
    if (values.indexOf(valueToCheck) > -1) {
      return true
    }
    return false
  }

  const formData = {
    eventName: {
      name: 'eventName',
      type: 'text',
      isRequired: true,
      placeholder: 'Event Name *',
      validations: Yup.string().required(formErrors.requiredField),
      initialValue: get(currentEvent, 'title', ''),
    },
    webURL: {
      name: 'webURL',
      type: 'text',
      placeholder: 'Website URL',
      validations: Yup.string().url(formErrors.invalidURL),
      initialValue: get(currentEvent, 'field_website_url.uri', ''),
    },
    rawDescriptionData: {
      name: 'rawDescriptionData',
      initialValue: isNil(convertRawToEditorState(eventBody?.value))
        ? ''
        : convertRawToEditorState(eventBody?.value),
      validations: Yup.object({}).test({
        message: `${formErrors.requiredField} and has a 500 character max`,
        test: value => {
          const rawLength = getTotalLengthFromEditorState(value)
          return rawLength > 0 && rawLength <= 500
        },
      }),
    },
    admissionDetails: {
      name: 'admissionDetails',
      type: 'text',
      placeholder: 'Admission Details',
      validations: Yup.string().max(200, formErrors.overLimitCharacters),
      initialValue: isNil(currentEvent?.field_price)
        ? ''
        : currentEvent?.field_price,
    },
    imageUpload: {
      name: 'imageUpload',
      type: 'file',
      placeholder: 'Event Image',
      validations: Yup.array(),
      initialValue: currentEvent?.formattedEventImages?.length
        ? currentEvent?.formattedEventImages
        : [],
      count: 2,
      fetchAsFile: true,
    },
    appearSite: {
      // NOTE: these no longer match the mocks. Functionally they need to be checkboxes.
      name: 'appearSite',
      type: 'checkboxGroup',
      errorShowOnTop: true,
      inline: true,
      validations: Yup.string().required(
        'Please select at least one site to publish this event on.',
      ),
      options: [
        {
          name: 'appearSite',
          value: 'community',
          renderLabel: () => 'hiltonheadisland.org',
          label: '',
          meta: 'Visitor and Convention Bureau',
          inline: true,
          type: 'checkbox',
          checked: isSiteChecked(
            get(currentEvent, 'field_calendar', []),
            'community',
          ),
        },
        {
          name: 'appearSite',
          value: 'chamber',
          label: '',
          renderLabel: () => 'hiltonheadchamber.org',
          meta: 'Chamber of Commerce',
          inline: true,
          type: 'checkbox',
          checked: isSiteChecked(
            get(currentEvent, 'field_calendar', []),
            'chamber',
          ),
        },
        {
          name: 'appearSite',
          value: 'bluffton',
          label: '',
          renderLabel: () => 'visitbluffton.org',
          meta: 'Heart of the lowcountry',
          inline: true,
          type: 'checkbox',
          checked: isSiteChecked(
            get(currentEvent, 'field_calendar', []),
            'bluffton',
          ),
        },
      ],
      initialValue: get(currentEvent, 'field_calendar', []).join(','),
      isDisabled: (option, valuesArray) => {
        if (
          option.value === 'community' &&
          valuesArray.indexOf('bluffton') > -1
        ) {
          return true
        }
        if (
          option.value === 'bluffton' &&
          valuesArray.indexOf('community') > -1
        ) {
          return true
        }

        return false
      },
    },

    categories: {
      name: 'categories',
      initialValue: {},
      type: 'checkbox',
      validations: Yup.object({}).test({
        message: formErrors.selectUpToThree,
        test: value => {
          if (value && Object.keys(value).length) {
            const count = Object.values(value).reduce((num, checked) => {
              let total = num
              if (checked) {
                total += 1
              }
              return total
            })
            return count > 0 && count <= 3
          }
          return undefined
        },
      }),
    },
    status: {
      name: 'status',
      type: 'checkbox',
      initialValue: Boolean(currentEvent?.status),
      renderLabel: () => 'Yes, publish event',
    },
  }

  const initialCategories = get(currentEvent, 'field_event_categories', null)

  if (initialCategories?.length) {
    initialCategories.forEach(category => {
      if (category?.uuid) {
        formData.categories.initialValue = {
          ...formData.categories?.initialValue,
          [category?.uuid]: true,
        }
      }
    })
  } else {
    formData.categories.initialValue = {}
  }

  return formData
}
