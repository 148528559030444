import * as Yup from 'yup'
import formErrors from '../../../../configuration/formErrors'

const businessDetailsFormFields = {
  directoryCategory: {
    name: 'directoryCategory',
    type: 'select',
    isRequired: true,
    placeholder: 'Business Directory Category *',
    validations: Yup.string().required(
      `${formErrors.requiredField} and must match an included option`,
    ),
    initialValue: '',
  },
  numOfRooms: {
    name: 'numOfRooms',
    type: 'text',
    placeholder: 'Number of Hotel Rooms',
    initialValue: '',
  },
  numOfRestaurants: {
    name: 'numOfRestaurants',
    type: 'text',
    placeholder: 'Number of Restaurants',
    initialValue: '',
  },
  accessible: {
    name: 'accessible',
    type: 'radio',
    inline: true,
    options: [
      {
        value: 'yes',
        label: 'Yes',
      },
      {
        value: 'no',
        label: 'No',
      },
    ],
    initialValue: '',
  },
  licensed: {
    name: 'licensed',
    type: 'radio',
    inline: true,
    options: [
      {
        value: 'yes',
        label: 'Yes',
      },
      {
        value: 'no',
        label: 'No',
      },
    ],
  },
}

export default businessDetailsFormFields
