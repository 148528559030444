import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '../Checkbox/Checkbox'

const CheckboxGroup = ({
  options,
  name,
  error,
  value,
  onChange,
  isDisabled,
}) => {
  if (!options?.length) {
    return null
  }
  return (
    <div>
      {options.map((option, idx) => (
        <Checkbox
          key={`${option.value}`}
          {...option}
          name={name}
          id={`${name}-${idx}`}
          checked={value.indexOf(option.value) !== -1}
          onChange={onChange}
          groupValue={option.value}
          disabled={isDisabled(option, value)}
        />
      ))}
      {error && <p className="color-error remove-margin">{error}</p>}
    </div>
  )
}

CheckboxGroup.defaultProps = {
  error: null,
  isDisabled: () => false,
  options: [],
  value: [],
}

CheckboxGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
}

export default CheckboxGroup
