import { RRule, RRuleSet } from 'rrule'
import moment from 'moment'
import { weekDayConstants } from './getStaticOptions'
import { getTimeString } from './formatEvent'

export const formatRRule = (values, removeStartDate = false) => {
  const rruleSet = new RRuleSet()
  const ruleObj = {}
  const timestamp = getTimeString(values, true)

  if (!removeStartDate && values.startDate) {
    ruleObj.dtstart = getDateWithOffset(values.startDate, timestamp)
  }
  if (
    values.endDate &&
    values.startDate &&
    new Date(values.endDate) > new Date(values.startDate)
  ) {
    // if we use callForTimes OR allDayEvent when submitting the start time and end time will
    // be converted to 00:00:00 and 01:00:00 respectively.
    // see ./formatEvent.js

    ruleObj.until = getDateWithOffset(values.endDate, timestamp)
  }
  if (values.rruleCount) {
    // The count and until don't work together and based the way drupal needs this count takes priority
    ruleObj.until = null
    ruleObj.count = values.rruleCount
  }
  if (values.rruleFrequency) {
    ruleObj.freq = values.rruleFrequency
  }
  if (values.rruleInterval && values.rruleInterval > 0) {
    ruleObj.interval = values.rruleInterval
  }
  if (values.rruleByWeekDay && Object.keys(values.rruleByWeekDay)?.length) {
    const selectedWeekDays = []
    Object.entries(values.rruleByWeekDay).forEach(([id, checked]) => {
      if (checked) {
        // can only have nth days if frequency is yearly or monthly
        const weekDay =
          values.rruleNth && values?.rruleFrequency && values.rruleFrequency < 2
            ? weekDayConstants[id].nth(values.rruleNth)
            : weekDayConstants[id]
        selectedWeekDays.push(weekDay)
      }
    })
    if (selectedWeekDays.length > 0) {
      ruleObj.byweekday = selectedWeekDays
    }
  }
  if (values.rruleByMonth && Object.keys(values.rruleByMonth)?.length) {
    const selectedMonths = []
    Object.entries(values.rruleByMonth).forEach(([id, checked]) => {
      if (id && checked) {
        selectedMonths.push(id)
      }
    })
    if (selectedMonths.length > 0) {
      ruleObj.bymonth = selectedMonths
    }
  }

  if (Object.keys(ruleObj).length === 0) {
    return null
  }

  const rules = new RRule(ruleObj)
  rruleSet.rrule(rules)

  // Drupal currently can not support included dates.
  // However, if this returns convertDateToUTC needs to be updated to getDateWithOffset
  // if (values.rruleIncluded) {
  //   const dateStringArr = values.rruleIncluded.split(',')
  //   dateStringArr.forEach(dateStr => {
  //     const utcDate = convertDateToUTC(new Date(dateStr))
  //     rruleSet.rdate(utcDate)
  //   })
  // }

  if (values.rruleExcluded) {
    // console.log(values)
    // From HHI203-85: EXDATE value the time must match the time of the start date
    // and not be adjusted when the rule is converted to a string
    // we need to add the offset because the rrule library will use the browser's timezone.

    // if we use callForTimes OR allDayEvent when submitting the start time and end time will
    // be converted to 00:00:00 and 01:00:00 respectively.
    // see ./formatEvent.js
    const timestampStr = getTimeString(values)
    const dateStringArr = values.rruleExcluded.split(',')
    dateStringArr.forEach(dateStr => {
      rruleSet.exdate(getDateWithOffset(dateStr, timestampStr))
    })
  }

  return rruleSet
}

export const getDateWithOffset = (dateStr, timeStr) => {
  const time = timeStr ? moment(timeStr, 'hh:mm:ss') : null
  const dateObj = moment(dateStr)
  dateObj.set('hour', time ? time.get('hour') : '00')
  dateObj.set('minute', time ? time.get('minute') : '00')
  dateObj.set('second', time ? time.get('second') : '00')

  const formattedDate = dateObj.clone().format('YYYY-MM-DDTHH:mm:ss')
  const asDate = new Date(formattedDate)
  const offset = asDate.getTimezoneOffset() * 60000
  return new Date(asDate.getTime() - offset)
}
