import { get } from 'lodash'
import { getUser } from '../../api/api'
// eslint-disable-next-line import/no-cycle
import { getAuthAppData } from '../appData/appData'

export const FETCH_USER_DATA_BEGIN = 'FETCH_USER_DATA_BEGIN'
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS'
export const FETCH_USER_DATA_ERROR = 'FETCH_USER_DATA_ERROR'
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA'

function reduceUser(data) {
  return {
    id: get(data, 'uid', ''),
    name: get(data, 'name', ''),
    email: get(data, 'mail', ''),
    firstName: get(data, 'field_first_name'),
    lastName: get(data, 'field_last_name'),
    phone: get(data, 'field_phone_number', ''),
    uuid: get(data, 'uuid'),
  }
}

export const fetchUser = uid => dispatch => {
  dispatch({ type: FETCH_USER_DATA_BEGIN })
  return getUser({ uid })
    .then(res => {
      const user = reduceUser(res.data)
      dispatch({ type: FETCH_USER_DATA_SUCCESS, payload: user })
      dispatch(getAuthAppData(uid))
    })
    .catch(err => {
      const message = get(err, 'message', 'Unable to fetch user')
      dispatch({ type: FETCH_USER_DATA_ERROR, payload: message })
      return Promise.reject()
    })
}

// REDUCER
const initialState = {
  loading: false,
  error: null,
  data: null,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_DATA_BEGIN:
      return { ...state, loading: true }
    case FETCH_USER_DATA_SUCCESS:
      return { ...state, loading: false, error: null, data: action.payload }
    case FETCH_USER_DATA_ERROR:
      return { ...state, loading: false, error: action.payload }
    case CLEAR_USER_DATA:
      return { ...state, data: null }
    default:
      return state
  }
}

export const selectUserData = state => state.userData

export const selectUserUuid = state => state.userData?.data?.uuid

export const selectUserUid = state => state.userData?.data?.id

export const selectUserDataError = state => state?.userData?.error
