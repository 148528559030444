import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import routes from '../configuration/routes'
import DefaultLayout from '../containers/Layouts/DefaultLayout'

const PrivateRoute = ({ isAuthenticated, children, ...rest }) => (
  <Route
    {...rest}
    render={() =>
      isAuthenticated ? (
        <DefaultLayout>{children}</DefaultLayout>
      ) : (
        <Redirect to={routes.login} />
      )
    }
  />
)

PrivateRoute.defaultProps = {
  isAuthenticated: false,
}
PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  children: PropTypes.element.isRequired,
}

export default PrivateRoute
