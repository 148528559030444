import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { formValidation } from '../../../utilities/content/generateFormData'
import FormikInput from '../../../components/elements/FormikInput'
import SocialLink from '../../../components/elements/SocialLink/SocialLink'

import arrowLeft from '../../../assets/icons/arrow-left.svg'
import { socialLinksData } from '../../../utilities/content/socialLinksData'

import '../styles.scss'
import TextInput from '../../../components/elements/TextInput/TextInput'
import SimpleSpinner from '../../../components/elements/SimpleSpinner'

const ContactDetailsForm = ({
  submit,
  back,
  initialValues,
  contactDetailsFormFields,
  isUpdate,
  submissionError,
  disableSubmit,
  disabledMessage,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={submit}
    validationSchema={formValidation(contactDetailsFormFields)}
  >
    {({
      handleSubmit,
      isSubmitting,
      values,
      setFieldValue,
      errors,
      touched,
    }) => (
      <form onSubmit={handleSubmit}>
        <div className="pt-2">
          <p className="mb-0 font-body f-18 bold">Event Coordinator</p>
          <p className="font-body mb-0 pt-2">
            For Administration Purposes Only (Private) This is the main
            coordinator for this event. The information provided here will not
            be displayed publicly anywhere on the website, nor will any of this
            information be shared with any other parties outside of Hilton Head
            Island management.
          </p>
        </div>
        <div className="pt-4">
          <FormikInput {...contactDetailsFormFields.coordinatorName} />
        </div>
        <div className="pt-4 flex-md-up">
          <div className="flex-half-md-up pr-4-md-up">
            <FormikInput
              {...contactDetailsFormFields.coordinatorEmailAddress}
            />
          </div>
          <div className="flex-half-md-up pl-4-md-up mt-4-md-max">
            <FormikInput {...contactDetailsFormFields.coordinatorPhoneNumber} />
          </div>
        </div>
        <div className="mt-14 mt-6-md-max bg-section-grey border border-border-light">
          <div className="px-6 py-6">
            <p className="mb-2 f-18 bold">Public Contact *</p>
            {errors?.PhoneNumber && touched?.PhoneNumber && (
              <p className="color-error">{errors.PhoneNumber}</p>
            )}
            <p className="mb-0">For Public Display</p>
            <p className="mb-0 f-12 mt-1">
              This is the main contact for any public enquiries about this
              event. The phone number provided here will be displayed publicly
              on the event details page.
            </p>
            <div className="mt-4 flex-md-up">
              <div className="flex-half-md-up pr-4-md-up">
                <TextInput
                  onChange={e => setFieldValue('PhoneNumber', e.target.value)}
                  value={values?.PhoneNumber}
                  placeholder="Phone Number"
                  name="PhoneNumber"
                />
              </div>
              <div className="flex-half-md-up pl-4-md-up mt-4-md-max">
                <FormikInput
                  {...contactDetailsFormFields.tollFreePhoneNumber}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pt-10">
          <p className="mb-0 font-body f-18 bold">Social Media URLs</p>
        </div>
        <div className="social-link1">
          <p className="font-body mb-0 pt-2">
            Enter handles as they appear after the .com/
          </p>
          {socialLinksData.map(link => (
            <div className="pt-4" key={link.name}>
              <SocialLink
                {...link}
                initialValue={contactDetailsFormFields[link.name]?.initialValue}
              />
            </div>
          ))}
        </div>
        {submissionError && (
          <div className="pt-10">
            <p className="bold text-center f18 color-error m0">
              {submissionError}
            </p>
          </div>
        )}
        {isSubmitting ? (
          <div className="flex flex-justify-center pt-10">
            <SimpleSpinner />
          </div>
        ) : (
          <div className="pt-14">
            {disableSubmit && (
              <p className="color-error">
                {disabledMessage || 'Please ensure form is complete'}
              </p>
            )}
            <div className="flex flex-space-between flex-column-md-max ">
              <button
                className="edit-event__backBtn btn btn-inverse mr-7-md-up"
                onClick={back}
                type="button"
              >
                <img src={arrowLeft} alt="Return arrow" className="mr-3" />
                Return to Time and Date
              </button>
              <button
                className="edit-event__btn btn btn-primary mt-4-md-max"
                type="submit"
                disabled={isSubmitting}
              >
                {isUpdate ? 'Update Event' : 'Submit Event'}
              </button>
            </div>
          </div>
        )}
      </form>
    )}
  </Formik>
)

ContactDetailsForm.defaultProps = {
  initialValues: null,
  isUpdate: false,
  submissionError: null,
  disableSubmit: false,
  disabledMessage: '',
}
ContactDetailsForm.propTypes = {
  submit: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  contactDetailsFormFields: PropTypes.shape({
    coordinatorName: PropTypes.shape({}),
    coordinatorEmailAddress: PropTypes.shape({}),
    coordinatorPhoneNumber: PropTypes.shape({}),
    PhoneNumber: PropTypes.shape({}),
    tollFreePhoneNumber: PropTypes.shape({}),
  }).isRequired,
  isUpdate: PropTypes.bool,
  submissionError: PropTypes.string,
  disableSubmit: PropTypes.bool,
  disabledMessage: PropTypes.string,
}

export default ContactDetailsForm
