export default {
  labels: {
    copyright:
      'Hilton Head Island - Bluffton Visitor & Convention Bureau. All rights reserved.',
    url: 'hiltonheadchamber.org',
  },
}

export const NON_MEMBER_ACCOUNT_ID = 'nonMember'
export const NON_MEMBER_LABEL = 'Non Chamber Member'
