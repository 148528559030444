import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import ReactCrop from 'react-image-crop'

import SimpleSpinner from '../SimpleSpinner'
import ContentModal from '../ContentModal/ContentModal'

import ImagePlaceholder from '../../../assets/icons/image-placeholder.svg'
import './styles.scss'
import 'react-image-crop/dist/ReactCrop.css'

export default function UploadInput({
  handleUploads,
  value,
  id,
  loading,
  errorMessage,
}) {
  const [error, setError] = useState(false)
  const [modal, setModal] = useState(false)
  const [image, setImage] = useState(null)
  const [imageFile, setImageFile] = useState(null)
  const [crop, setCrop] = useState({
    unit: '%',
    width: 50,
    height: 50,
    x: 25,
    y: 25,
    aspect: 880 / 690,
  })
  const [completedCrop, setCompletedCrop] = useState(null)

  const hiddenFileInput = React.useRef(null)
  const imgRef = React.useRef(null)

  useEffect(() => {
    if (!completedCrop || !imgRef.current) {
      return
    }

    const canvas = document.createElement('canvas')
    const currentImage = imgRef.current
    const currentCrop = completedCrop

    const scaleX = currentImage.naturalWidth / currentImage.width
    const scaleY = currentImage.naturalHeight / currentImage.height
    const ctx = canvas.getContext('2d')
    const pixelRatio = window.devicePixelRatio

    canvas.width = currentCrop.width * pixelRatio
    canvas.height = currentCrop.height * pixelRatio

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      currentImage,
      currentCrop.x * scaleX,
      currentCrop.y * scaleY,
      currentCrop.width * scaleX,
      currentCrop.height * scaleY,
      0,
      0,
      currentCrop.width,
      currentCrop.height,
    )

    if (currentCrop.height !== 0 && currentCrop.width !== 0) {
      generateBlob(canvas)
    } else {
      setCrop({
        unit: '%',
        width: 100,
        height: 100,
        x: 100,
        y: 100,
      })
    }
  }, [completedCrop])

  const handleFile = e => {
    const file = e.currentTarget.files[0]

    if (file === undefined) {
      // Handle user cancelling upload
      return
    }

    if (
      !file.type.includes('png') &&
      !file.type.includes('gif') &&
      !file.type.includes('jpg') &&
      !file.type.includes('jpeg')
    ) {
      setError('File type not supported')
      return
    }

    const img = new Image()
    img.src = URL.createObjectURL(file)
    img.onload = () => {
      setError(false)
      setModal(true)
      setImage(img.src)
      setImageFile(file)
      setCrop({
        unit: '%',
        width: 50,
        height: 50,
        x: 25,
        y: 25,
        aspect: 880 / 690,
      })
    }
    // Hack to enable reselecting the same file
    e.target.value = null
  }
  const generateBlob = canvas => {
    canvas.toBlob(file => {
      setImageFile(new File([file], imageFile?.name))
    })
  }

  const handleLabelClick = e => {
    e.preventDefault()
    if (e.key === 'Enter' || e.key === ' ' || e.type === 'click') {
      hiddenFileInput.current.click()
    }
  }

  const uploadCrop = e => {
    e.preventDefault()
    handleUploads(imageFile, id)
    setModal(false)
    // Reset cropper default dimensions
    setCrop({
      unit: '%',
      width: 50,
      height: 50,
      x: 25,
      y: 25,
      aspect: 880 / 690,
    })
  }

  const onLoad = useCallback(img => {
    imgRef.current = img
  }, [])

  let imgSource = value?.src

  if (!imgSource) {
    if (value.drupalId) {
      imgSource = URL.createObjectURL(value.file)
    } else {
      imgSource = value.file ? value.file : ImagePlaceholder
    }
  }
  const isLoading = loading === id
  return (
    <div
      className="file-uploader__upload-select bg-section-grey ml-8-md-up px-7 py-4 relative full-width flex-md-max flex-column flex-align-center"
      key={`uploader-${id}`}
    >
      <div className="file-uploader__upload-placeholder flex-md-up flex-justify-center flex-align-center m-6">
        {isLoading ? (
          <div className="loader-container">
            <SimpleSpinner />
          </div>
        ) : (
          <img
            src={imgSource}
            alt="Preview"
            height={124}
            width={124}
            className="file-uploader__preview"
          />
        )}
      </div>
      <div className="file-uploader__upload-btn relative">
        <input
          id="file"
          name="file-upload"
          type="file"
          onChange={handleFile}
          ref={hiddenFileInput}
        />
        {errorMessage?.length > 0 && !isLoading && <p>{errorMessage}</p>}
        <button
          className="btn btn-primary flex-md-up flex-justify-center flex-align-center m-0 full-width py-3 px-8"
          onClick={handleLabelClick}
          onKeyDown={handleLabelClick}
          tabIndex={0}
          type="button"
        >
          {value.file || value.src ? 'CHANGE FILE' : 'CHOOSE FILE'}
        </button>
        {error && (
          <p className="file-error color-error remove-margin absolute">
            {error}
          </p>
        )}
      </div>

      <ContentModal
        isOpen={modal}
        modalTitle="Image Crop"
        closeModal={uploadCrop}
        clickAway={setModal}
      >
        <div className="flex flex-column flex-align-center">
          <ReactCrop
            src={image}
            crop={crop}
            onImageLoaded={onLoad}
            onChange={newCrop => setCrop(newCrop)}
            onComplete={newCrop => setCompletedCrop(newCrop)}
          />
        </div>
      </ContentModal>
    </div>
  )
}

UploadInput.defaultProps = {
  value: {},
  loading: false,
  errorMessage: null,
}

UploadInput.propTypes = {
  handleUploads: PropTypes.func.isRequired,
  value: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    src: PropTypes.string,
    drupalId: PropTypes.string,
  }),
  id: PropTypes.number.isRequired,
  loading: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  errorMessage: PropTypes.string,
}
