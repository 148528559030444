import moment from 'moment'
import {
  convertEditorStateToPlainText,
  convertEditorStateToRaw,
} from '../../../components/elements/WYSIWYG/utilities'

export const formatFormDataForSubmission = (formData = {}) => {
  const {
    offerName,
    expires,
    launches,
    phone,
    website,
    bookNowURL,
    categories,
    paid,
    selectedPartnerId,
    rawDescriptionData,
    imageUpload,
  } = formData

  const requestBody = {
    type: 'node--offer',
    attributes: {
      moderation_state: 'draft',
      title: offerName,
      field_offer_launches: moment(launches).format('YYYY-MM-DD'),
      field_offer_expires: moment(expires).format('YYYY-MM-DD'),
      field_phone_number: phone?.length ? phone : '',
      field_paid_offer: Boolean(paid),
    },
    relationships: {
      field_partner: {
        data: {
          type: 'node--partner',
          id: selectedPartnerId,
        },
      },
    },
  }

  if (rawDescriptionData) {
    requestBody.attributes.body = {
      value: convertEditorStateToRaw(rawDescriptionData),
      format: 'basic_html',
      summary: convertEditorStateToPlainText(rawDescriptionData),
    }
  }

  if (website?.length) {
    requestBody.attributes.field_website_url = {
      uri: website.trim(),
      title: '',
      options: [],
    }
  }

  if (bookNowURL?.length) {
    requestBody.attributes.field_book_now = {
      // Required
      uri: bookNowURL.trim(),
      title: '',
      options: [],
    }
  }

  if (categories && Object.keys(categories)?.length) {
    requestBody.relationships.field_offer_categories = {
      data: [],
    }
    Object.entries(categories).forEach(([id, checked]) => {
      if (checked) {
        requestBody.relationships.field_offer_categories.data.push({
          type: 'taxonomy_term--offer_categories',
          id,
        })
      }
    })
  }
  // only allowed one here, uploader supports multiple for other forms
  if (imageUpload?.[0] && imageUpload?.[0]?.drupalId) {
    requestBody.relationships.field_offer_image = {
      data: {
        type: 'file--file',
        id: imageUpload?.[0]?.drupalId,
      },
    }
  }

  return requestBody
}
