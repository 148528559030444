import { getPage } from '../../api/api'

const FETCH_PAGE_DATA = 'FETCH_PAGE_DATA'
const PAGE_DATA_ERROR = 'PAGE_DATA_ERROR'
const PAGE_DATA_SUCCESS = 'PAGE_DATA_SUCCESS'

const initialState = {}

// ACTIONS
export const fetchPage = slug => dispatch => {
  dispatch({ type: FETCH_PAGE_DATA, payload: { slug } })
  return getPage(slug)
    .then(res => {
      const data = res?.data?.[0]
      if (data) {
        const { title, body } = data

        dispatch({
          type: PAGE_DATA_SUCCESS,
          payload: {
            slug,
            data: {
              title,
              body,
              questions: data?.field_faqs
                ? data?.field_faqs.map(faq => ({
                    question: faq.field_question,
                    answer: faq.field_answer?.value,
                    id: faq.id,
                  }))
                : null,
            },
          },
        })
      } else {
        dispatch({
          type: PAGE_DATA_ERROR,
          payload: {
            slug,
            error: 'Unable to fetch page data',
          },
        })
      }
    })
    .catch(() => {
      dispatch({
        type: PAGE_DATA_ERROR,
        payload: {
          slug,
          error: 'Unable to fetch page data',
        },
      })
    })
}

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PAGE_DATA:
      return {
        ...state,
        [action.payload.slug]: {
          loading: true,
        },
      }
    case PAGE_DATA_ERROR:
      return {
        ...state,
        [action.payload.slug]: {
          loading: false,
          error: action.payload.error,
        },
      }
    case PAGE_DATA_SUCCESS:
      return {
        ...state,
        [action.payload.slug]: {
          loading: false,
          error: null,
          data: action.payload.data,
        },
      }
    default:
      return state
  }
}

export const selectFAQState = (state, slug) => state.contentData?.[slug]

export const selectPageState = (state, slug) => state.contentData?.[slug] || {}
