import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import Header from '../../components/structure/Header'
import Footer from '../../components/structure/Footer'
import routes from '../../configuration/routes'
import SimpleHeader from '../../components/structure/SimpleHeader'

const routesWithSimpleHeader = [routes.registrationStepTwo]

const DefaultLayout = ({ children }) => {
  const location = useLocation()

  if (routesWithSimpleHeader.some(route => route === location.pathname)) {
    return (
      <>
        <SimpleHeader />
        <div className="flex-grow">{children}</div>
      </>
    )
  }

  return (
    <>
      <Header />
      <div className="flex-grow">{children}</div>
      <Footer />
    </>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
}

export default DefaultLayout
