import React from 'react'
import PropTypes from 'prop-types'
import { Editor } from 'react-draft-wysiwyg'
import wysiwygSettings from '../../../configuration/wysiwygSettings'

import '../../../styles/elements/wysiwyg.scss'

const WysiwygEditor = ({ onChange, initialState = null }) => (
  <Editor
    editorState={initialState}
    toolbar={wysiwygSettings.toolbar}
    wrapperClassName={wysiwygSettings.wrapperClassName}
    editorClassName={wysiwygSettings.editorClassName}
    toolbarClassName={wysiwygSettings.toolbarClassName}
    onEditorStateChange={onChange}
  />
)

WysiwygEditor.defaultProps = {
  initialState: null,
}
WysiwygEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialState: PropTypes.shape({}),
}

export default WysiwygEditor
