export const countries = [
  // {
  //   alpha2Code: 'AF',
  //   geoCode: 'PA7',
  //   name: 'Afghanistan',
  //   callingCodes: ['93'],
  //   nationality: {
  //     name: 'Afghan',
  //     isoCode: 'AFG',
  //     geoCode: 'A7',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'AX',
  //   geoCode: 'PA12',
  //   name: 'Aland Islands',
  //   callingCodes: ['358'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'ALA',
  //     geoCode: 'A12',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'AL',
  //   geoCode: 'PAL',
  //   name: 'Albania',
  //   callingCodes: ['355'],
  //   nationality: {
  //     name: 'Albanian',
  //     isoCode: 'ALB',
  //     geoCode: 'AL',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'DZ',
  //   geoCode: 'PDZ',
  //   name: 'Algeria',
  //   callingCodes: ['213'],
  //   nationality: {
  //     name: 'Algerian',
  //     isoCode: 'DZA',
  //     geoCode: 'DZ',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'AD',
  //   geoCode: 'PAD',
  //   name: 'Andorra',
  //   callingCodes: ['376'],
  //   nationality: {
  //     name: 'Andorran',
  //     isoCode: 'AND',
  //     geoCode: 'AD',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'AO',
  //   geoCode: 'PAO',
  //   name: 'Angola',
  //   callingCodes: ['244'],
  //   nationality: {
  //     name: 'Angolan',
  //     isoCode: 'AGO',
  //     geoCode: 'AO',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'AI',
  //   geoCode: 'PA8',
  //   name: 'Anguilla',
  //   callingCodes: ['1264'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'AIA',
  //     geoCode: 'A8',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'AG',
  //   geoCode: 'PAG',
  //   name: 'Antigua and Barbuda',
  //   callingCodes: ['1268'],
  //   nationality: {
  //     name: 'Antiguan and Barbudan',
  //     isoCode: 'ATG',
  //     geoCode: 'AG',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'AR',
  //   geoCode: 'PAR',
  //   name: 'Argentina',
  //   callingCodes: ['54'],
  //   nationality: {
  //     name: 'Argentine',
  //     isoCode: 'ARG',
  //     geoCode: 'AR',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'AM',
  //   geoCode: 'PAM',
  //   name: 'Armenia',
  //   callingCodes: ['374'],
  //   nationality: {
  //     name: 'Armenian',
  //     isoCode: 'ARM',
  //     geoCode: 'AM',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'AW',
  //   geoCode: 'PA11',
  //   name: 'Aruba',
  //   callingCodes: ['297'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'ABW',
  //     geoCode: 'A11',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'AU',
  //   geoCode: 'PAU',
  //   name: 'Australia',
  //   callingCodes: ['61'],
  //   nationality: {
  //     name: 'Australian',
  //     isoCode: 'AUS',
  //     geoCode: 'AU',
  //   },
  //   idCardRequired: false,
  //   states: [
  //     {
  //       code: 'ACT',
  //       codeGeo: 'ACT',
  //       name: 'AUSTRALIAN CAPITAL TERRITORY',
  //     },
  //     {
  //       code: 'JBT',
  //       codeGeo: 'RE50',
  //       name: 'JERVIS BAY TERRITORY',
  //     },
  //     {
  //       code: 'NSW',
  //       codeGeo: 'ANS',
  //       name: 'NEW SOUTH WALES',
  //     },
  //     {
  //       code: 'NT',
  //       codeGeo: 'ANT',
  //       name: 'NORTHERN TERRITORY',
  //     },
  //     {
  //       code: 'QLD',
  //       codeGeo: 'AQL',
  //       name: 'QUEENSLAND',
  //     },
  //     {
  //       code: 'SA',
  //       codeGeo: 'ASA',
  //       name: 'SOUTH AUSTRALIA',
  //     },
  //     {
  //       code: 'TAS',
  //       codeGeo: 'ATS',
  //       name: 'TASMANIA',
  //     },
  //     {
  //       code: 'VIC',
  //       codeGeo: 'AVI',
  //       name: 'VICTORIA',
  //     },
  //     {
  //       code: 'WA',
  //       codeGeo: 'AWA',
  //       name: 'WESTERN AUSTRALIA',
  //     },
  //   ],
  // },
  // {
  //   alpha2Code: 'AT',
  //   geoCode: 'PAT',
  //   name: 'Austria',
  //   callingCodes: ['43'],
  //   nationality: {
  //     name: 'Austrian',
  //     isoCode: 'AUT',
  //     geoCode: 'AT',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'AZ',
  //   geoCode: 'PAZ',
  //   name: 'Azerbaijan',
  //   callingCodes: ['994'],
  //   nationality: {
  //     name: 'Azerbaijani',
  //     isoCode: 'AZE',
  //     geoCode: 'AZ',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'BS',
  //   geoCode: 'PA2',
  //   name: 'Bahamas',
  //   callingCodes: ['1242'],
  //   nationality: {
  //     name: 'Bahamian',
  //     isoCode: 'BHS',
  //     geoCode: 'A2',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'BH',
  //   geoCode: 'PBH',
  //   name: 'Bahrain',
  //   callingCodes: ['973'],
  //   nationality: {
  //     name: 'Bahraini',
  //     isoCode: 'BHR',
  //     geoCode: 'BH',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'BD',
  //   geoCode: 'PBD',
  //   name: 'Bangladesh',
  //   callingCodes: ['880'],
  //   nationality: {
  //     name: 'Bangladeshi',
  //     isoCode: 'BGD',
  //     geoCode: 'BD',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'BB',
  //   geoCode: 'PBB',
  //   name: 'Barbados',
  //   callingCodes: ['1246'],
  //   nationality: {
  //     name: 'Barbadian',
  //     isoCode: 'BRB',
  //     geoCode: 'BB',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'BY',
  //   geoCode: 'PBY',
  //   name: 'Belarus',
  //   callingCodes: ['375'],
  //   nationality: {
  //     name: 'Belarusian',
  //     isoCode: 'BLR',
  //     geoCode: 'BY',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'BE',
  //   geoCode: 'PBE',
  //   name: 'Belgium',
  //   callingCodes: ['32'],
  //   nationality: {
  //     name: 'Belgian',
  //     isoCode: 'BEL',
  //     geoCode: 'BE',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'BZ',
  //   geoCode: 'PBZ',
  //   name: 'Belize',
  //   callingCodes: ['501'],
  //   nationality: {
  //     name: 'Belizean',
  //     isoCode: 'BLZ',
  //     geoCode: 'BZ',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'BJ',
  //   geoCode: 'PBJ',
  //   name: 'Benin',
  //   callingCodes: ['229'],
  //   nationality: {
  //     name: 'Beninese',
  //     isoCode: 'BEN',
  //     geoCode: 'BJ',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'BM',
  //   geoCode: 'PA13',
  //   name: 'Bermuda',
  //   callingCodes: ['1441'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'BMU',
  //     geoCode: 'A13',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'BT',
  //   geoCode: 'PA14',
  //   name: 'Bhutan',
  //   callingCodes: ['975'],
  //   nationality: {
  //     name: 'Bhutanese',
  //     isoCode: 'BTN',
  //     geoCode: 'A14',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'BO',
  //   geoCode: 'PBO',
  //   name: 'Bolivia',
  //   callingCodes: ['591'],
  //   nationality: {
  //     name: 'Bolivian',
  //     isoCode: 'BOL',
  //     geoCode: 'BO',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'BA',
  //   geoCode: 'PBA',
  //   name: 'Bosnia And Hercegovina',
  //   callingCodes: ['387'],
  //   nationality: {
  //     name: 'Bosnian',
  //     isoCode: 'BIH',
  //     geoCode: 'BA',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'BW',
  //   geoCode: 'PBW',
  //   name: 'Botswana',
  //   callingCodes: ['267'],
  //   nationality: {
  //     name: 'Batswana',
  //     isoCode: 'BWA',
  //     geoCode: 'BW',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'BV',
  //   geoCode: 'PA27',
  //   name: 'Bouvet Island',
  //   callingCodes: ['47'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'BVT',
  //     geoCode: 'A27',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'BR',
  //   geoCode: 'PBR',
  //   name: 'Brazil',
  //   callingCodes: ['55'],
  //   nationality: {
  //     name: 'Brazilian',
  //     isoCode: 'BRA',
  //     geoCode: 'BR',
  //   },
  //   idCardRequired: true,
  //   states: [
  //     {
  //       code: 'AC',
  //       codeGeo: 'BAC',
  //       name: 'Acre',
  //     },
  //     {
  //       code: 'AL',
  //       codeGeo: 'BAL',
  //       name: 'Alagoas',
  //     },
  //     {
  //       code: 'AP',
  //       codeGeo: 'BAP',
  //       name: 'Amapá',
  //     },
  //     {
  //       code: 'AM',
  //       codeGeo: 'BAM',
  //       name: 'Amazonas',
  //     },
  //     {
  //       code: 'BA',
  //       codeGeo: 'BBA',
  //       name: 'Bahia',
  //     },
  //     {
  //       code: 'CE',
  //       codeGeo: 'BCE',
  //       name: 'Ceará',
  //     },
  //     {
  //       code: 'DF',
  //       codeGeo: 'BDF',
  //       name: 'Distrito Federal',
  //     },
  //     {
  //       code: 'ES',
  //       codeGeo: 'BES',
  //       name: 'Espirito Santo',
  //     },
  //     {
  //       code: 'GO',
  //       codeGeo: 'BGO',
  //       name: 'Goiás',
  //     },
  //     {
  //       code: 'MA',
  //       codeGeo: 'BMA',
  //       name: 'Maranhão',
  //     },
  //     {
  //       code: 'MT',
  //       codeGeo: 'BMT',
  //       name: 'Mato Grosso',
  //     },
  //     {
  //       code: 'MS',
  //       codeGeo: 'BMS',
  //       name: 'Mato Grosso do Sul',
  //     },
  //     {
  //       code: 'MG',
  //       codeGeo: 'BMG',
  //       name: 'Minas Gerais',
  //     },
  //     {
  //       code: 'PR',
  //       codeGeo: 'BPR',
  //       name: 'Paraná',
  //     },
  //     {
  //       code: 'PB',
  //       codeGeo: 'BPB',
  //       name: 'Paraíba',
  //     },
  //     {
  //       code: 'PA',
  //       codeGeo: 'BPA',
  //       name: 'Pará',
  //     },
  //     {
  //       code: 'PE',
  //       codeGeo: 'BPE',
  //       name: 'Pernambuco',
  //     },
  //     {
  //       code: 'PI',
  //       codeGeo: 'BPI',
  //       name: 'Piaui',
  //     },
  //     {
  //       code: 'RN',
  //       codeGeo: 'BRN',
  //       name: 'Rio Grande do Norte',
  //     },
  //     {
  //       code: 'RS',
  //       codeGeo: 'BRS',
  //       name: 'Rio Grande do Sul',
  //     },
  //     {
  //       code: 'RJ',
  //       codeGeo: 'BRJ',
  //       name: 'Rio de Janeiro',
  //     },
  //     {
  //       code: 'RO',
  //       codeGeo: 'BRO',
  //       name: 'Rondônia',
  //     },
  //     {
  //       code: 'RR',
  //       codeGeo: 'BRR',
  //       name: 'Roraima',
  //     },
  //     {
  //       code: 'SC',
  //       codeGeo: 'BSC',
  //       name: 'Santa Catarina',
  //     },
  //     {
  //       code: 'SE',
  //       codeGeo: 'BSE',
  //       name: 'Sergipe',
  //     },
  //     {
  //       code: 'SP',
  //       codeGeo: 'BSP',
  //       name: 'São Paulo',
  //     },
  //     {
  //       code: 'TO',
  //       codeGeo: 'BTO',
  //       name: 'Tocantins',
  //     },
  //   ],
  // },
  // {
  //   alpha2Code: 'IO',
  //   geoCode: 'PA32',
  //   name: 'British Indian Ocean Territory',
  //   callingCodes: ['246'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'IOT',
  //     geoCode: 'A32',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'VG',
  //   geoCode: 'PA60',
  //   name: 'British Virgin Islands',
  //   callingCodes: ['1284'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'VGB',
  //     geoCode: 'A60',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'BN',
  //   geoCode: 'PBN',
  //   name: 'Brunei',
  //   callingCodes: ['673'],
  //   nationality: {
  //     name: 'Bruneian',
  //     isoCode: 'BRN',
  //     geoCode: 'BN',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'BG',
  //   geoCode: 'PBG',
  //   name: 'Bulgaria',
  //   callingCodes: ['359'],
  //   nationality: {
  //     name: 'Bulgarian',
  //     isoCode: 'BGR',
  //     geoCode: 'BG',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'BF',
  //   geoCode: 'PBF',
  //   name: 'Burkina-Faso',
  //   callingCodes: ['226'],
  //   nationality: {
  //     name: 'Burkinabe',
  //     isoCode: 'BFA',
  //     geoCode: 'BF',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'BI',
  //   geoCode: 'PBI',
  //   name: 'Burundi',
  //   callingCodes: ['257'],
  //   nationality: {
  //     name: 'Burundian',
  //     isoCode: 'BDI',
  //     geoCode: 'BI',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'KH',
  //   geoCode: 'PKH',
  //   name: 'Cambodia',
  //   callingCodes: ['855'],
  //   nationality: {
  //     name: 'Cambodian',
  //     isoCode: 'KHM',
  //     geoCode: 'KH',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'CM',
  //   geoCode: 'PCM',
  //   name: 'Cameroon',
  //   callingCodes: ['237'],
  //   nationality: {
  //     name: 'Cameroonian',
  //     isoCode: 'CMR',
  //     geoCode: 'CM',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'CA',
  //   geoCode: 'PCA',
  //   name: 'Canada',
  //   callingCodes: ['1'],
  //   nationality: {
  //     name: 'Canadian',
  //     isoCode: 'CAN',
  //     geoCode: 'CA',
  //   },
  //   idCardRequired: true,
  //   states: [
  //     {
  //       code: 'AB',
  //       codeGeo: 'CAL',
  //       name: 'Alberta',
  //     },
  //     {
  //       code: 'BC',
  //       codeGeo: 'CBC',
  //       name: 'British Columbia',
  //     },
  //     {
  //       code: 'MB',
  //       codeGeo: 'CMB',
  //       name: 'Manitoba',
  //     },
  //     {
  //       code: 'NB',
  //       codeGeo: 'CNB',
  //       name: 'New Brunswick',
  //     },
  //     {
  //       code: 'NL',
  //       codeGeo: 'CNF',
  //       name: 'Newfounland and Labrador',
  //     },
  //     {
  //       code: 'NT',
  //       codeGeo: 'CNWT',
  //       name: 'Northwest Territory',
  //     },
  //     {
  //       code: 'NS',
  //       codeGeo: 'CNS',
  //       name: 'Nova Scotia',
  //     },
  //     {
  //       code: 'NU',
  //       codeGeo: 'CNU',
  //       name: 'Nunavut',
  //     },
  //     {
  //       code: 'ON',
  //       codeGeo: 'CON',
  //       name: 'Ontario',
  //     },
  //     {
  //       code: 'PE',
  //       codeGeo: 'CPI',
  //       name: 'Prince Edward Island',
  //     },
  //     {
  //       code: 'QC',
  //       codeGeo: 'CQC',
  //       name: 'Quebec',
  //     },
  //     {
  //       code: 'SK',
  //       codeGeo: 'CSK',
  //       name: 'Saskatchewan',
  //     },
  //     {
  //       code: 'YT',
  //       codeGeo: 'CYT',
  //       name: 'Yukon',
  //     },
  //   ],
  // },
  // {
  //   alpha2Code: 'CV',
  //   geoCode: 'PA17',
  //   name: 'Cape Verde',
  //   callingCodes: ['238'],
  //   nationality: {
  //     name: 'Cape Verdean',
  //     isoCode: 'CPV',
  //     geoCode: 'A17',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'KY',
  //   geoCode: 'PA36',
  //   name: 'Cayman Islands',
  //   callingCodes: ['1345'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'CYM',
  //     geoCode: 'A36',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'CF',
  //   geoCode: 'PCF',
  //   name: 'Central African Republic',
  //   callingCodes: ['236'],
  //   nationality: {
  //     name: 'Central African',
  //     isoCode: 'CAF',
  //     geoCode: 'CF',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'TD',
  //   geoCode: 'PTD',
  //   name: 'Chad',
  //   callingCodes: ['235'],
  //   nationality: {
  //     name: 'Chadian',
  //     isoCode: 'TCD',
  //     geoCode: 'TD',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'CL',
  //   geoCode: 'PCL',
  //   name: 'Chile',
  //   callingCodes: ['56'],
  //   nationality: {
  //     name: 'Chilean',
  //     isoCode: 'CHL',
  //     geoCode: 'CL',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'CN',
  //   geoCode: 'PCN',
  //   name: 'China',
  //   callingCodes: ['86'],
  //   nationality: {
  //     name: 'Chinese',
  //     isoCode: 'CHN',
  //     geoCode: 'CN',
  //   },
  //   idCardRequired: true,
  //   states: [
  //     {
  //       code: 'AH',
  //       codeGeo: 'RE15',
  //       name: 'ANHUI',
  //     },
  //     {
  //       code: 'BJ',
  //       codeGeo: 'RE16',
  //       name: 'BEIJING - municipality',
  //     },
  //     {
  //       code: 'CQ',
  //       codeGeo: 'RE17',
  //       name: 'CHONGQING - municipality',
  //     },
  //     {
  //       code: 'FJ',
  //       codeGeo: 'RE18',
  //       name: 'FUJIAN',
  //     },
  //     {
  //       code: 'HN',
  //       codeGeo: 'RE29',
  //       name: 'HUNAN',
  //     },
  //   ],
  // },
  // {
  //   alpha2Code: 'CX',
  //   geoCode: 'PA18',
  //   name: 'Christmas Island',
  //   callingCodes: ['618'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'CXR',
  //     geoCode: 'A18',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'CC',
  //   geoCode: 'PA15',
  //   name: 'Cocos - Keeling Islands',
  //   callingCodes: ['61'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'CCK',
  //     geoCode: 'A15',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'CO',
  //   geoCode: 'PCO',
  //   name: 'Colombia',
  //   callingCodes: ['57'],
  //   nationality: {
  //     name: 'Colombian',
  //     isoCode: 'COL',
  //     geoCode: 'CO',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'KM',
  //   geoCode: 'PA35',
  //   name: 'Comoros',
  //   callingCodes: ['269'],
  //   nationality: {
  //     name: 'Comoran',
  //     isoCode: 'COM',
  //     geoCode: 'A35',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'CG',
  //   geoCode: 'PCG',
  //   name: 'Congo-Brazzaville',
  //   callingCodes: ['242'],
  //   nationality: {
  //     name: 'Congolese',
  //     isoCode: 'COG',
  //     geoCode: 'CG',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'CK',
  //   geoCode: 'PA16',
  //   name: 'Cook Islands',
  //   callingCodes: ['682'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'COK',
  //     geoCode: 'A16',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'CR',
  //   geoCode: 'PCR',
  //   name: 'Costa Rica',
  //   callingCodes: ['506'],
  //   nationality: {
  //     name: 'Costa Rican',
  //     isoCode: 'CRI',
  //     geoCode: 'CR',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'HR',
  //   geoCode: 'PHR',
  //   name: 'Croatia',
  //   callingCodes: ['385'],
  //   nationality: {
  //     name: 'Croatian',
  //     isoCode: 'HRV',
  //     geoCode: 'HR',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'CU',
  //   geoCode: 'PCU',
  //   name: 'Cuba',
  //   callingCodes: ['53'],
  //   nationality: {
  //     name: 'Cuban',
  //     isoCode: 'CUB',
  //     geoCode: 'CU',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'CY',
  //   geoCode: 'PCY',
  //   name: 'Cyprus',
  //   callingCodes: ['357'],
  //   nationality: {
  //     name: 'Cypriot',
  //     isoCode: 'CYP',
  //     geoCode: 'CY',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'CZ',
  //   geoCode: 'PCZ',
  //   name: 'Czech Republic',
  //   callingCodes: ['420'],
  //   nationality: {
  //     name: 'Czech',
  //     isoCode: 'CZE',
  //     geoCode: 'CZ',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'CD',
  //   geoCode: 'PCD',
  //   name: 'Democratic Repub. of Congo',
  //   callingCodes: ['243'],
  //   nationality: {
  //     name: 'Congolese (DRC)',
  //     isoCode: 'COD',
  //     geoCode: 'CD',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'DK',
  //   geoCode: 'PDK',
  //   name: 'Denmark',
  //   callingCodes: ['45'],
  //   nationality: {
  //     name: 'Danish',
  //     isoCode: 'DNK',
  //     geoCode: 'DK',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'DJ',
  //   geoCode: 'PDJ',
  //   name: 'Djibouti',
  //   callingCodes: ['253'],
  //   nationality: {
  //     name: 'Djiboutian',
  //     isoCode: 'DJI',
  //     geoCode: 'DJ',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'DM',
  //   geoCode: 'PDM',
  //   name: 'Dominica',
  //   callingCodes: ['1767'],
  //   nationality: {
  //     name: 'Dominican (Dominica)',
  //     isoCode: 'DMA',
  //     geoCode: 'DM',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'DO',
  //   geoCode: 'PDO',
  //   name: 'Dominican Republic',
  //   callingCodes: ['1'],
  //   nationality: {
  //     name: 'Dominican (Dominican Republic)',
  //     isoCode: 'DOM',
  //     geoCode: 'DO',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'EC',
  //   geoCode: 'PEC',
  //   name: 'Ecuador',
  //   callingCodes: ['593'],
  //   nationality: {
  //     name: 'Ecuadorian',
  //     isoCode: 'ECU',
  //     geoCode: 'EC',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'EG',
  //   geoCode: 'PEG',
  //   name: 'Egypt',
  //   callingCodes: ['20'],
  //   nationality: {
  //     name: 'Egyptian',
  //     isoCode: 'EGY',
  //     geoCode: 'EG',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'SV',
  //   geoCode: 'PSV',
  //   name: 'El Salvador',
  //   callingCodes: ['503'],
  //   nationality: {
  //     name: 'Salvadoran',
  //     isoCode: 'SLV',
  //     geoCode: 'SV',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'GQ',
  //   geoCode: 'PGQ',
  //   name: 'Equatorial Guinea',
  //   callingCodes: ['240'],
  //   nationality: {
  //     name: 'Equatorial Guinean',
  //     isoCode: 'GNQ',
  //     geoCode: 'GQ',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'ER',
  //   geoCode: 'PER',
  //   name: 'Eritrea',
  //   callingCodes: ['291'],
  //   nationality: {
  //     name: 'Eritrean',
  //     isoCode: 'ERI',
  //     geoCode: 'ER',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'EE',
  //   geoCode: 'PEE',
  //   name: 'Estonia',
  //   callingCodes: ['372'],
  //   nationality: {
  //     name: 'Estonian',
  //     isoCode: 'EST',
  //     geoCode: 'EE',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'ET',
  //   geoCode: 'PET',
  //   name: 'Ethiopia',
  //   callingCodes: ['251'],
  //   nationality: {
  //     name: 'Ethiopian',
  //     isoCode: 'ETH',
  //     geoCode: 'ET',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'FK',
  //   geoCode: 'PA20',
  //   name: 'Falkland Islands/Malvinas',
  //   callingCodes: ['500'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'FLK',
  //     geoCode: 'A20',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'FO',
  //   geoCode: 'PA22',
  //   name: 'Faroe Islands',
  //   callingCodes: ['298'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'FRO',
  //     geoCode: 'A22',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'FJ',
  //   geoCode: 'PA1',
  //   name: 'Fiji Islands',
  //   callingCodes: ['679'],
  //   nationality: {
  //     name: 'Fijian',
  //     isoCode: 'FJI',
  //     geoCode: 'A1',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'FI',
  //   geoCode: 'PFI',
  //   name: 'Finland',
  //   callingCodes: ['358'],
  //   nationality: {
  //     name: 'Finnish',
  //     isoCode: 'FIN',
  //     geoCode: 'FI',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'FR',
  //   geoCode: 'PFR',
  //   name: 'France',
  //   callingCodes: ['33'],
  //   nationality: {
  //     name: 'French',
  //     isoCode: 'FRA',
  //     geoCode: 'FR',
  //   },
  //   idCardRequired: false,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'GF',
  //   geoCode: 'PGF',
  //   name: 'French Guiana',
  //   callingCodes: ['594'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'GUF',
  //     geoCode: 'GF',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'PF',
  //   geoCode: 'PPF',
  //   name: 'French Polynesia',
  //   callingCodes: ['689'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'PYF',
  //     geoCode: 'PF',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'GA',
  //   geoCode: 'PGA',
  //   name: 'Gabon',
  //   callingCodes: ['241'],
  //   nationality: {
  //     name: 'Gabonese',
  //     isoCode: 'GAB',
  //     geoCode: 'GA',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'GM',
  //   geoCode: 'PGM',
  //   name: 'Gambia',
  //   callingCodes: ['220'],
  //   nationality: {
  //     name: 'Gambian',
  //     isoCode: 'GMB',
  //     geoCode: 'GM',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'GE',
  //   geoCode: 'PGE',
  //   name: 'Georgia',
  //   callingCodes: ['995'],
  //   nationality: {
  //     name: 'Georgian',
  //     isoCode: 'GEO',
  //     geoCode: 'GE',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'DE',
  //   geoCode: 'PDE',
  //   name: 'Germany',
  //   callingCodes: ['49'],
  //   nationality: {
  //     name: 'German',
  //     isoCode: 'DEU',
  //     geoCode: 'DE',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'GH',
  //   geoCode: 'PGH',
  //   name: 'Ghana',
  //   callingCodes: ['233'],
  //   nationality: {
  //     name: 'Ghanaian',
  //     isoCode: 'GHA',
  //     geoCode: 'GH',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'GI',
  //   geoCode: 'PGI',
  //   name: 'Gibraltar',
  //   callingCodes: ['350'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'GIB',
  //     geoCode: 'GI',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'GR',
  //   geoCode: 'PGR',
  //   name: 'Greece',
  //   callingCodes: ['30'],
  //   nationality: {
  //     name: 'Greek',
  //     isoCode: 'GRC',
  //     geoCode: 'GR',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'GL',
  //   geoCode: 'PA28',
  //   name: 'Greenland',
  //   callingCodes: ['299'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'GRL',
  //     geoCode: 'A28',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'GD',
  //   geoCode: 'PA23',
  //   name: 'Grenada',
  //   callingCodes: ['1473'],
  //   nationality: {
  //     name: 'Grenadian',
  //     isoCode: 'GRD',
  //     geoCode: 'A23',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'GP',
  //   geoCode: 'PGP',
  //   name: 'Guadeloupe',
  //   callingCodes: ['590'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'GLP',
  //     geoCode: 'GP',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'GU',
  //   geoCode: 'PA26',
  //   name: 'Guam',
  //   callingCodes: ['1671'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'GUM',
  //     geoCode: 'A26',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'GT',
  //   geoCode: 'PGT',
  //   name: 'Guatemala',
  //   callingCodes: ['502'],
  //   nationality: {
  //     name: 'Guatemalan',
  //     isoCode: 'GTM',
  //     geoCode: 'GT',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'GG',
  //   geoCode: 'PA24',
  //   name: 'Guernsey',
  //   callingCodes: ['44'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'GGY',
  //     geoCode: 'A24',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'GN',
  //   geoCode: 'PGN',
  //   name: 'Guinea',
  //   callingCodes: ['224'],
  //   nationality: {
  //     name: 'Guinean',
  //     isoCode: 'GIN',
  //     geoCode: 'GN',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'GW',
  //   geoCode: 'PA29',
  //   name: 'Guinea Bissau',
  //   callingCodes: ['245'],
  //   nationality: {
  //     name: 'Bissau-Guinean',
  //     isoCode: 'GNB',
  //     geoCode: 'A29',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'GY',
  //   geoCode: 'PGY',
  //   name: 'Guyana',
  //   callingCodes: ['594'],
  //   nationality: {
  //     name: 'Guyanese',
  //     isoCode: 'GUY',
  //     geoCode: 'GY',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'HT',
  //   geoCode: 'PHT',
  //   name: 'Haiti',
  //   callingCodes: ['509'],
  //   nationality: {
  //     name: 'Haitian',
  //     isoCode: 'HTI',
  //     geoCode: 'HT',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'HM',
  //   geoCode: 'PA30',
  //   name: 'Heard Island McDonald Islands',
  //   callingCodes: ['61'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'HMD',
  //     geoCode: 'A30',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'HN',
  //   geoCode: 'PHN',
  //   name: 'Honduras',
  //   callingCodes: ['504'],
  //   nationality: {
  //     name: 'Honduran',
  //     isoCode: 'HND',
  //     geoCode: 'HN',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'HK',
  //   geoCode: 'PA69',
  //   name: 'Hong Kong S.A.R., China',
  //   callingCodes: ['852'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'HKG',
  //     geoCode: 'A69',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'HU',
  //   geoCode: 'PHU',
  //   name: 'Hungary',
  //   callingCodes: ['36'],
  //   nationality: {
  //     name: 'Hungarian',
  //     isoCode: 'HUN',
  //     geoCode: 'HU',
  //   },
  //   idCardRequired: false,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'IS',
  //   geoCode: 'PIS',
  //   name: 'Iceland',
  //   callingCodes: ['354'],
  //   nationality: {
  //     name: 'Icelandic',
  //     isoCode: 'ISL',
  //     geoCode: 'IS',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'IN',
  //   geoCode: 'PIN',
  //   name: 'India',
  //   callingCodes: ['91'],
  //   nationality: {
  //     name: 'Indian',
  //     isoCode: 'IND',
  //     geoCode: 'IN',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'ID',
  //   geoCode: 'PID',
  //   name: 'Indonesia',
  //   callingCodes: ['62'],
  //   nationality: {
  //     name: 'Indonesian',
  //     isoCode: 'IDN',
  //     geoCode: 'ID',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'IR',
  //   geoCode: 'PIR',
  //   name: 'Iran',
  //   callingCodes: ['98'],
  //   nationality: {
  //     name: 'Iranian',
  //     isoCode: 'IRN',
  //     geoCode: 'IR',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'IQ',
  //   geoCode: 'PIQ',
  //   name: 'Iraq',
  //   callingCodes: ['964'],
  //   nationality: {
  //     name: 'Iraqi',
  //     isoCode: 'IRQ',
  //     geoCode: 'IQ',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'IE',
  //   geoCode: 'PIE',
  //   name: 'Ireland',
  //   callingCodes: ['353'],
  //   nationality: {
  //     name: 'Irish',
  //     isoCode: 'IRL',
  //     geoCode: 'IE',
  //   },
  //   idCardRequired: false,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'IM',
  //   geoCode: 'PA31',
  //   name: 'Isle of Man',
  //   callingCodes: ['44'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'IMN',
  //     geoCode: 'A31',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'IL',
  //   geoCode: 'PIL',
  //   name: 'Israel',
  //   callingCodes: ['972'],
  //   nationality: {
  //     name: 'Israeli',
  //     isoCode: 'ISR',
  //     geoCode: 'IL',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'IT',
  //   geoCode: 'PIT',
  //   name: 'Italy',
  //   callingCodes: ['39'],
  //   nationality: {
  //     name: 'Italian',
  //     isoCode: 'ITA',
  //     geoCode: 'IT',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'CI',
  //   geoCode: 'PCI',
  //   name: 'Ivory  Coast',
  //   callingCodes: ['225'],
  //   nationality: {
  //     name: 'Ivorian',
  //     isoCode: 'CIV',
  //     geoCode: 'CI',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'JM',
  //   geoCode: 'PJM',
  //   name: 'Jamaica',
  //   callingCodes: ['1876'],
  //   nationality: {
  //     name: 'Jamaican',
  //     isoCode: 'JAM',
  //     geoCode: 'JM',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'JP',
  //   geoCode: 'PJP',
  //   name: 'Japan',
  //   callingCodes: ['81'],
  //   nationality: {
  //     name: 'Japanese',
  //     isoCode: 'JPN',
  //     geoCode: 'JP',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'JE',
  //   geoCode: 'PA33',
  //   name: 'Jersey',
  //   callingCodes: ['44'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'JEY',
  //     geoCode: 'A33',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'JO',
  //   geoCode: 'PJO',
  //   name: 'Jordan',
  //   callingCodes: ['962'],
  //   nationality: {
  //     name: 'Jordanian',
  //     isoCode: 'JOR',
  //     geoCode: 'JO',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'KZ',
  //   geoCode: 'PKZ',
  //   name: 'Kazakhstan',
  //   callingCodes: ['7'],
  //   nationality: {
  //     name: 'Kazakhstani',
  //     isoCode: 'KAZ',
  //     geoCode: 'KZ',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'KE',
  //   geoCode: 'PKE',
  //   name: 'Kenya',
  //   callingCodes: ['254'],
  //   nationality: {
  //     name: 'Kenyan',
  //     isoCode: 'KEN',
  //     geoCode: 'KE',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'KI',
  //   geoCode: 'PA34',
  //   name: 'Kiribati',
  //   callingCodes: ['686'],
  //   nationality: {
  //     name: 'Kiribatian',
  //     isoCode: 'KIR',
  //     geoCode: 'A34',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'XK',
  //   geoCode: 'PA71',
  //   name: 'Kosovo',
  //   callingCodes: ['381'],
  //   nationality: {
  //     name: 'Kosovan',
  //     isoCode: 'UNK',
  //     geoCode: 'A71',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'KW',
  //   geoCode: 'PKW',
  //   name: 'Kuwait',
  //   callingCodes: ['965'],
  //   nationality: {
  //     name: 'Kuwaiti',
  //     isoCode: 'KWT',
  //     geoCode: 'KW',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'KG',
  //   geoCode: 'PKG',
  //   name: 'Kyrgyzstan',
  //   callingCodes: ['996'],
  //   nationality: {
  //     name: 'Kyrgyzstani',
  //     isoCode: 'KGZ',
  //     geoCode: 'KG',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'LA',
  //   geoCode: 'PLA',
  //   name: "Lao people's democratic republic",
  //   callingCodes: ['856'],
  //   nationality: {
  //     name: 'Lao',
  //     isoCode: 'LAO',
  //     geoCode: 'LA',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'LV',
  //   geoCode: 'PLV',
  //   name: 'Latvia',
  //   callingCodes: ['371'],
  //   nationality: {
  //     name: 'Latvian',
  //     isoCode: 'LVA',
  //     geoCode: 'LV',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'LB',
  //   geoCode: 'PLB',
  //   name: 'Lebanon',
  //   callingCodes: ['961'],
  //   nationality: {
  //     name: 'Lebanese',
  //     isoCode: 'LBN',
  //     geoCode: 'LB',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'LS',
  //   geoCode: 'PA40',
  //   name: 'Lesotho',
  //   callingCodes: ['266'],
  //   nationality: {
  //     name: 'Basotho',
  //     isoCode: 'LSO',
  //     geoCode: 'A40',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'LR',
  //   geoCode: 'PA39',
  //   name: 'Liberia',
  //   callingCodes: ['231'],
  //   nationality: {
  //     name: 'Liberian',
  //     isoCode: 'LBR',
  //     geoCode: 'A39',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'LY',
  //   geoCode: 'PLY',
  //   name: 'Libya',
  //   callingCodes: ['218'],
  //   nationality: {
  //     name: 'Libyan',
  //     isoCode: 'LBY',
  //     geoCode: 'LY',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'LI',
  //   geoCode: 'PA38',
  //   name: 'Liechtenstein',
  //   callingCodes: ['423'],
  //   nationality: {
  //     name: 'Liechtenstein',
  //     isoCode: 'LIE',
  //     geoCode: 'A38',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'LT',
  //   geoCode: 'PLT',
  //   name: 'Lithuania',
  //   callingCodes: ['370'],
  //   nationality: {
  //     name: 'Lithuanian',
  //     isoCode: 'LTU',
  //     geoCode: 'LT',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'LU',
  //   geoCode: 'PLU',
  //   name: 'Luxembourg',
  //   callingCodes: ['352'],
  //   nationality: {
  //     name: 'Luxembourg',
  //     isoCode: 'LUX',
  //     geoCode: 'LU',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'MO',
  //   geoCode: 'PA70',
  //   name: 'Macau',
  //   callingCodes: ['853'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'MAC',
  //     geoCode: 'A70',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'MK',
  //   geoCode: 'PMK',
  //   name: 'Macedonia',
  //   callingCodes: ['389'],
  //   nationality: {
  //     name: 'Macedonian',
  //     isoCode: 'MKD',
  //     geoCode: 'MK',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'MG',
  //   geoCode: 'PMG',
  //   name: 'Madagascar',
  //   callingCodes: ['261'],
  //   nationality: {
  //     name: 'Malagasy',
  //     isoCode: 'MDG',
  //     geoCode: 'MG',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'MW',
  //   geoCode: 'PMW',
  //   name: 'Malawi',
  //   callingCodes: ['265'],
  //   nationality: {
  //     name: 'Malawian',
  //     isoCode: 'MWI',
  //     geoCode: 'MW',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'MY',
  //   geoCode: 'PMY',
  //   name: 'Malaysia',
  //   callingCodes: ['60'],
  //   nationality: {
  //     name: 'Malaysian',
  //     isoCode: 'MYS',
  //     geoCode: 'MY',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'MV',
  //   geoCode: 'PA3',
  //   name: 'Maldives',
  //   callingCodes: ['960'],
  //   nationality: {
  //     name: 'Maldivian',
  //     isoCode: 'MDV',
  //     geoCode: 'A3',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'ML',
  //   geoCode: 'PML',
  //   name: 'Mali',
  //   callingCodes: ['223'],
  //   nationality: {
  //     name: 'Malian',
  //     isoCode: 'MLI',
  //     geoCode: 'ML',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'MT',
  //   geoCode: 'PMT',
  //   name: 'Malta',
  //   callingCodes: ['356'],
  //   nationality: {
  //     name: 'Maltese',
  //     isoCode: 'MLT',
  //     geoCode: 'MT',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'MH',
  //   geoCode: 'PA41',
  //   name: 'Marshall Island Republic',
  //   callingCodes: ['692'],
  //   nationality: {
  //     name: 'Marshallese',
  //     isoCode: 'MHL',
  //     geoCode: 'A41',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'MQ',
  //   geoCode: 'PMQ',
  //   name: 'Martinique',
  //   callingCodes: ['596'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'MTQ',
  //     geoCode: 'MQ',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'MR',
  //   geoCode: 'PMR',
  //   name: 'Mauritania',
  //   callingCodes: ['222'],
  //   nationality: {
  //     name: 'Mauritanian',
  //     isoCode: 'MRT',
  //     geoCode: 'MR',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'MU',
  //   geoCode: 'PMU',
  //   name: 'Mauritius',
  //   callingCodes: ['230'],
  //   nationality: {
  //     name: 'Mauritian',
  //     isoCode: 'MUS',
  //     geoCode: 'MU',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'YT',
  //   geoCode: 'PYT',
  //   name: 'Mayotte',
  //   callingCodes: ['262'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'MYT',
  //     geoCode: 'YT',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'MX',
  //   geoCode: 'PMX',
  //   name: 'Mexico',
  //   callingCodes: ['52'],
  //   nationality: {
  //     name: 'Mexican',
  //     isoCode: 'MEX',
  //     geoCode: 'MX',
  //   },
  //   idCardRequired: true,
  //   states: [
  //     {
  //       code: 'AGU',
  //       codeGeo: 'AGU',
  //       name: 'Aguascalientes',
  //     },
  //     {
  //       code: 'BCN',
  //       codeGeo: 'BCN',
  //       name: 'Baja California',
  //     },
  //     {
  //       code: 'BCS',
  //       codeGeo: 'BCS',
  //       name: 'Baja California Sur',
  //     },
  //     {
  //       code: 'CAM',
  //       codeGeo: 'CAM',
  //       name: 'Campeche',
  //     },
  //     {
  //       code: 'CHP',
  //       codeGeo: 'CHP',
  //       name: 'Chiapas',
  //     },
  //     {
  //       code: 'CHH',
  //       codeGeo: 'CHH',
  //       name: 'Chihuahua',
  //     },
  //     {
  //       code: 'COA',
  //       codeGeo: 'COA',
  //       name: 'Coahuila',
  //     },
  //     {
  //       code: 'COL',
  //       codeGeo: 'COL',
  //       name: 'Colima',
  //     },
  //     {
  //       code: 'DUR',
  //       codeGeo: 'DUR',
  //       name: 'Durango',
  //     },
  //     {
  //       code: 'DIF',
  //       codeGeo: 'DIF',
  //       name: 'Federal District',
  //     },
  //     {
  //       code: 'GUA',
  //       codeGeo: 'GUA',
  //       name: 'Guanajuato',
  //     },
  //     {
  //       code: 'GRO',
  //       codeGeo: 'GRO',
  //       name: 'Guerrero',
  //     },
  //     {
  //       code: 'HID',
  //       codeGeo: 'HID',
  //       name: 'Hidalgo',
  //     },
  //     {
  //       code: 'JAL',
  //       codeGeo: 'JAL',
  //       name: 'Jalisco',
  //     },
  //     {
  //       code: 'MEX',
  //       codeGeo: 'MEX',
  //       name: 'Mexico State',
  //     },
  //     {
  //       code: 'MIC',
  //       codeGeo: 'MIC',
  //       name: 'Michoacán',
  //     },
  //     {
  //       code: 'MOR',
  //       codeGeo: 'MOR',
  //       name: 'Morelos',
  //     },
  //     {
  //       code: 'NAY',
  //       codeGeo: 'NAY',
  //       name: 'Nayarit',
  //     },
  //     {
  //       code: 'NLE',
  //       codeGeo: 'NLE',
  //       name: 'Nuevo León',
  //     },
  //     {
  //       code: 'OAX',
  //       codeGeo: 'OAX',
  //       name: 'Oaxaca',
  //     },
  //     {
  //       code: 'PUE',
  //       codeGeo: 'PUE',
  //       name: 'Puebla',
  //     },
  //     {
  //       code: 'QUE',
  //       codeGeo: 'QUE',
  //       name: 'Querétaro',
  //     },
  //     {
  //       code: 'ROO',
  //       codeGeo: 'ROO',
  //       name: 'Quintana Roo',
  //     },
  //     {
  //       code: 'SLP',
  //       codeGeo: 'SLP',
  //       name: 'San Luis Potosí',
  //     },
  //     {
  //       code: 'SIN',
  //       codeGeo: 'SIN',
  //       name: 'Sinaloa',
  //     },
  //     {
  //       code: 'SON',
  //       codeGeo: 'SON',
  //       name: 'Sonora',
  //     },
  //     {
  //       code: 'TAB',
  //       codeGeo: 'TAB',
  //       name: 'Tabasco',
  //     },
  //     {
  //       code: 'TAM',
  //       codeGeo: 'TAM',
  //       name: 'Tamaulipas',
  //     },
  //     {
  //       code: 'TLA',
  //       codeGeo: 'TLA',
  //       name: 'Tlaxcala',
  //     },
  //     {
  //       code: 'VER',
  //       codeGeo: 'VER',
  //       name: 'Veracruz',
  //     },
  //     {
  //       code: 'YUC',
  //       codeGeo: 'YUC',
  //       name: 'Yucatán',
  //     },
  //     {
  //       code: 'ZAC',
  //       codeGeo: 'ZAC',
  //       name: 'Zacatecas',
  //     },
  //   ],
  // },
  // {
  //   alpha2Code: 'FM',
  //   geoCode: 'PA21',
  //   name: 'Micronesia, Federated States Of',
  //   callingCodes: ['691'],
  //   nationality: {
  //     name: 'Micronesian',
  //     isoCode: 'FSM',
  //     geoCode: 'A21',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'MD',
  //   geoCode: 'PMD',
  //   name: 'Moldova',
  //   callingCodes: ['373'],
  //   nationality: {
  //     name: 'Moldovan',
  //     isoCode: 'MDA',
  //     geoCode: 'MD',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'MC',
  //   geoCode: 'PMC',
  //   name: 'Monaco',
  //   callingCodes: ['377'],
  //   nationality: {
  //     name: 'Monacan',
  //     isoCode: 'MCO',
  //     geoCode: 'MC',
  //   },
  //   idCardRequired: false,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'MN',
  //   geoCode: 'PMN',
  //   name: 'Mongolia',
  //   callingCodes: ['976'],
  //   nationality: {
  //     name: 'Mongolian',
  //     isoCode: 'MNG',
  //     geoCode: 'MN',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'ME',
  //   geoCode: 'PA68',
  //   name: 'Montenegro',
  //   callingCodes: ['382'],
  //   nationality: {
  //     name: 'Montenegrin',
  //     isoCode: 'MNE',
  //     geoCode: 'A68',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'MS',
  //   geoCode: 'PA43',
  //   name: 'Montserrat',
  //   callingCodes: ['1664'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'MSR',
  //     geoCode: 'A43',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'MA',
  //   geoCode: 'PMA',
  //   name: 'Morocco',
  //   callingCodes: ['212'],
  //   nationality: {
  //     name: 'Moroccan',
  //     isoCode: 'MAR',
  //     geoCode: 'MA',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'MZ',
  //   geoCode: 'PMZ',
  //   name: 'Mozambique',
  //   callingCodes: ['258'],
  //   nationality: {
  //     name: 'Mozambican',
  //     isoCode: 'MOZ',
  //     geoCode: 'MZ',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'MM',
  //   geoCode: 'PMM',
  //   name: 'Myanmar',
  //   callingCodes: ['95'],
  //   nationality: {
  //     name: 'Burmese',
  //     isoCode: 'MMR',
  //     geoCode: 'MM',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'NA',
  //   geoCode: 'PNA',
  //   name: 'Namibia',
  //   callingCodes: ['264'],
  //   nationality: {
  //     name: 'Namibian',
  //     isoCode: 'NAM',
  //     geoCode: 'NA',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'NR',
  //   geoCode: 'PA44',
  //   name: 'Nauru',
  //   callingCodes: ['674'],
  //   nationality: {
  //     name: 'Nauruan',
  //     isoCode: 'NRU',
  //     geoCode: 'A44',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'NP',
  //   geoCode: 'PNP',
  //   name: 'Nepal',
  //   callingCodes: ['977'],
  //   nationality: {
  //     name: 'Nepali',
  //     isoCode: 'NPL',
  //     geoCode: 'NP',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'NL',
  //   geoCode: 'PNL',
  //   name: 'Netherlands',
  //   callingCodes: ['31'],
  //   nationality: {
  //     name: 'Dutch',
  //     isoCode: 'NLD',
  //     geoCode: 'NL',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'AN',
  //   geoCode: 'PA9',
  //   name: 'Netherlands Antilles',
  //   callingCodes: ['599'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'ANT',
  //     geoCode: 'A9',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'NC',
  //   geoCode: 'PNC',
  //   name: 'New Caledonia',
  //   callingCodes: ['687'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'NCL',
  //     geoCode: 'NC',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'NZ',
  //   geoCode: 'PNZ',
  //   name: 'New Zealand',
  //   callingCodes: ['64'],
  //   nationality: {
  //     name: 'New Zealand',
  //     isoCode: 'NZL',
  //     geoCode: 'NZ',
  //   },
  //   idCardRequired: false,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'NI',
  //   geoCode: 'PNI',
  //   name: 'Nicaragua',
  //   callingCodes: ['505'],
  //   nationality: {
  //     name: 'Nicaraguan',
  //     isoCode: 'NIC',
  //     geoCode: 'NI',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'NE',
  //   geoCode: 'PNE',
  //   name: 'Niger',
  //   callingCodes: ['227'],
  //   nationality: {
  //     name: 'Nigerien',
  //     isoCode: 'NER',
  //     geoCode: 'NE',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'NG',
  //   geoCode: 'PNG',
  //   name: 'Nigeria',
  //   callingCodes: ['234'],
  //   nationality: {
  //     name: 'Nigerian',
  //     isoCode: 'NGA',
  //     geoCode: 'NG',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'NU',
  //   geoCode: 'PA45',
  //   name: 'Niue',
  //   callingCodes: ['683'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'NIU',
  //     geoCode: 'A45',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'NF',
  //   geoCode: 'PA6',
  //   name: 'Norfolk Island',
  //   callingCodes: ['672'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'NFK',
  //     geoCode: 'A6',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'KP',
  //   geoCode: 'PKP',
  //   name: 'North Korea',
  //   callingCodes: ['850'],
  //   nationality: {
  //     name: 'North Korean',
  //     isoCode: 'PRK',
  //     geoCode: 'KP',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'MP',
  //   geoCode: 'PA42',
  //   name: 'Northern Mariana Islands',
  //   callingCodes: ['1670'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'MNP',
  //     geoCode: 'A42',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'NO',
  //   geoCode: 'PNO',
  //   name: 'Norway',
  //   callingCodes: ['47'],
  //   nationality: {
  //     name: 'Norwegian',
  //     isoCode: 'NOR',
  //     geoCode: 'NO',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'OM',
  //   geoCode: 'POM',
  //   name: 'Oman',
  //   callingCodes: ['968'],
  //   nationality: {
  //     name: 'Omani',
  //     isoCode: 'OMN',
  //     geoCode: 'OM',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'PK',
  //   geoCode: 'PPK',
  //   name: 'Pakistan',
  //   callingCodes: ['92'],
  //   nationality: {
  //     name: 'Pakistani',
  //     isoCode: 'PAK',
  //     geoCode: 'PK',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'PW',
  //   geoCode: 'PA48',
  //   name: 'Palau',
  //   callingCodes: ['680'],
  //   nationality: {
  //     name: 'Palauan',
  //     isoCode: 'PLW',
  //     geoCode: 'A48',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'PS',
  //   geoCode: 'PA47',
  //   name: 'Palestinian Territory',
  //   callingCodes: ['970'],
  //   nationality: {
  //     name: 'Palestinian',
  //     isoCode: 'PSE',
  //     geoCode: 'A47',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'PA',
  //   geoCode: 'PPA',
  //   name: 'Panama',
  //   callingCodes: ['507'],
  //   nationality: {
  //     name: 'Panamanian',
  //     isoCode: 'PAN',
  //     geoCode: 'PA',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'PG',
  //   geoCode: 'PPG',
  //   name: 'Papua new guinea',
  //   callingCodes: ['675'],
  //   nationality: {
  //     name: 'Papuan-New-Guinean',
  //     isoCode: 'PNG',
  //     geoCode: 'PG',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'PY',
  //   geoCode: 'PPY',
  //   name: 'Paraguay',
  //   callingCodes: ['595'],
  //   nationality: {
  //     name: 'Paraguayan',
  //     isoCode: 'PRY',
  //     geoCode: 'PY',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'PE',
  //   geoCode: 'PPE',
  //   name: 'Peru',
  //   callingCodes: ['51'],
  //   nationality: {
  //     name: 'Peruvian',
  //     isoCode: 'PER',
  //     geoCode: 'PE',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'PH',
  //   geoCode: 'PPH',
  //   name: 'Philippines',
  //   callingCodes: ['63'],
  //   nationality: {
  //     name: 'Philippine',
  //     isoCode: 'PHL',
  //     geoCode: 'PH',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'PN',
  //   geoCode: 'PA46',
  //   name: 'Pitcairn',
  //   callingCodes: ['672'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'PCN',
  //     geoCode: 'A46',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'PL',
  //   geoCode: 'PPL',
  //   name: 'Poland',
  //   callingCodes: ['48'],
  //   nationality: {
  //     name: 'Polish',
  //     isoCode: 'POL',
  //     geoCode: 'PL',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'PT',
  //   geoCode: 'PPT',
  //   name: 'Portugal',
  //   callingCodes: ['351'],
  //   nationality: {
  //     name: 'Portuguese',
  //     isoCode: 'PRT',
  //     geoCode: 'PT',
  //   },
  //   idCardRequired: false,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'PR',
  //   geoCode: 'PPR',
  //   name: 'Puerto Rico',
  //   callingCodes: ['1'],
  //   nationality: {
  //     name: 'Puerto Rican',
  //     isoCode: 'PRI',
  //     geoCode: 'PR',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'QA',
  //   geoCode: 'PQA',
  //   name: 'Qatar',
  //   callingCodes: ['974'],
  //   nationality: {
  //     name: 'Qatari',
  //     isoCode: 'QAT',
  //     geoCode: 'QA',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'RE',
  //   geoCode: 'PRE',
  //   name: 'Reunion',
  //   callingCodes: ['262'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'REU',
  //     geoCode: 'RE',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'RO',
  //   geoCode: 'PRO',
  //   name: 'Romania',
  //   callingCodes: ['40'],
  //   nationality: {
  //     name: 'Romanian',
  //     isoCode: 'ROU',
  //     geoCode: 'RO',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'RU',
  //   geoCode: 'PRU',
  //   name: 'Russia',
  //   callingCodes: ['7'],
  //   nationality: {
  //     name: 'Russian',
  //     isoCode: 'RUS',
  //     geoCode: 'RU',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'RW',
  //   geoCode: 'PRW',
  //   name: 'Rwanda',
  //   callingCodes: ['250'],
  //   nationality: {
  //     name: 'Rwandan',
  //     isoCode: 'RWA',
  //     geoCode: 'RW',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'BL',
  //   geoCode: 'PSF',
  //   name: 'Saint Barthelemy',
  //   callingCodes: ['590'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'BLM',
  //     geoCode: 'SF',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'SH',
  //   geoCode: 'PA66',
  //   name: 'Saint Helena',
  //   callingCodes: ['590'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'SHN',
  //     geoCode: 'A66',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'KN',
  //   geoCode: 'PKN',
  //   name: 'Saint Kitts and Nevis',
  //   callingCodes: ['1869'],
  //   nationality: {
  //     name: 'Kittitian',
  //     isoCode: 'KNA',
  //     geoCode: 'KN',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'LC',
  //   geoCode: 'PA37',
  //   name: 'Saint Lucia',
  //   callingCodes: ['1758'],
  //   nationality: {
  //     name: 'Saint Lucian',
  //     isoCode: 'LCA',
  //     geoCode: 'A37',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'MF',
  //   geoCode: 'PA65',
  //   name: 'Saint Martin',
  //   callingCodes: ['590'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'MAF',
  //     geoCode: 'A65',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'AS',
  //   geoCode: 'PA10',
  //   name: 'Samoa American',
  //   callingCodes: ['684'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'ASM',
  //     geoCode: 'A10',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'WS',
  //   geoCode: 'PA64',
  //   name: 'Samoa Western',
  //   callingCodes: ['685'],
  //   nationality: {
  //     name: 'Samoan',
  //     isoCode: 'WSM',
  //     geoCode: 'A64',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'SM',
  //   geoCode: 'PSM',
  //   name: 'San Marino',
  //   callingCodes: ['378'],
  //   nationality: {
  //     name: 'Sammarinese',
  //     isoCode: 'SMR',
  //     geoCode: 'SM',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'ST',
  //   geoCode: 'PA52',
  //   name: 'Sao Tome and Principe',
  //   callingCodes: ['239'],
  //   nationality: {
  //     name: 'Sao Tomean',
  //     isoCode: 'STP',
  //     geoCode: 'A52',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'SA',
  //   geoCode: 'PSA',
  //   name: 'Saudi Arabia',
  //   callingCodes: ['966'],
  //   nationality: {
  //     name: 'Saudi',
  //     isoCode: 'SAU',
  //     geoCode: 'SA',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'SN',
  //   geoCode: 'PSN',
  //   name: 'Senegal',
  //   callingCodes: ['221'],
  //   nationality: {
  //     name: 'Senegalese',
  //     isoCode: 'SEN',
  //     geoCode: 'SN',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'RS',
  //   geoCode: 'PA67',
  //   name: 'Serbia',
  //   callingCodes: ['381'],
  //   nationality: {
  //     name: 'Serbian',
  //     isoCode: 'SRB',
  //     geoCode: 'A67',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'SC',
  //   geoCode: 'PA50',
  //   name: 'Seychelles Islands',
  //   callingCodes: ['248'],
  //   nationality: {
  //     name: 'Seychellois',
  //     isoCode: 'SYC',
  //     geoCode: 'A50',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'SL',
  //   geoCode: 'PSL',
  //   name: 'Sierra Leone',
  //   callingCodes: ['232'],
  //   nationality: {
  //     name: 'Sierra Leonean',
  //     isoCode: 'SLE',
  //     geoCode: 'SL',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'SG',
  //   geoCode: 'PSG',
  //   name: 'Singapore',
  //   callingCodes: ['65'],
  //   nationality: {
  //     name: 'Singapore',
  //     isoCode: 'SGP',
  //     geoCode: 'SG',
  //   },
  //   idCardRequired: false,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'SK',
  //   geoCode: 'PSK',
  //   name: 'Slovakia',
  //   callingCodes: ['421'],
  //   nationality: {
  //     name: 'Slovak',
  //     isoCode: 'SVK',
  //     geoCode: 'SK',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'SI',
  //   geoCode: 'PSI',
  //   name: 'Slovenia',
  //   callingCodes: ['386'],
  //   nationality: {
  //     name: 'Slovenian',
  //     isoCode: 'SVN',
  //     geoCode: 'SI',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'SB',
  //   geoCode: 'PA49',
  //   name: 'Solomon Islands',
  //   callingCodes: ['677'],
  //   nationality: {
  //     name: 'Solomon Islander',
  //     isoCode: 'SLB',
  //     geoCode: 'A49',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'SO',
  //   geoCode: 'PSO',
  //   name: 'Somalia',
  //   callingCodes: ['252'],
  //   nationality: {
  //     name: 'Somali',
  //     isoCode: 'SOM',
  //     geoCode: 'SO',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'ZA',
  //   geoCode: 'PZA',
  //   name: 'South Africa',
  //   callingCodes: ['27'],
  //   nationality: {
  //     name: 'South African',
  //     isoCode: 'ZAF',
  //     geoCode: 'ZA',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'GS',
  //   geoCode: 'PA25',
  //   name: 'South Georgia South Sandwich Isl',
  //   callingCodes: ['54'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'SGS',
  //     geoCode: 'A25',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'KR',
  //   geoCode: 'PKR',
  //   name: 'South Korea',
  //   callingCodes: ['82'],
  //   nationality: {
  //     name: 'South Korean',
  //     isoCode: 'KOR',
  //     geoCode: 'KR',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'ES',
  //   geoCode: 'PES',
  //   name: 'Spain',
  //   callingCodes: ['34'],
  //   nationality: {
  //     name: 'Spanish',
  //     isoCode: 'ESP',
  //     geoCode: 'ES',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'LK',
  //   geoCode: 'PLK',
  //   name: 'Sri lanka',
  //   callingCodes: ['94'],
  //   nationality: {
  //     name: 'Sri Lankan',
  //     isoCode: 'LKA',
  //     geoCode: 'LK',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'PM',
  //   geoCode: 'PPM',
  //   name: 'St Pierre and Miquelon',
  //   callingCodes: ['508'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'SPM',
  //     geoCode: 'PM',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'VC',
  //   geoCode: 'PA59',
  //   name: 'St Vincent and The Grenadines',
  //   callingCodes: ['1784'],
  //   nationality: {
  //     name: 'Saint Vincentian',
  //     isoCode: 'VCT',
  //     geoCode: 'A59',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'SD',
  //   geoCode: 'PSD',
  //   name: 'Sudan',
  //   callingCodes: ['249'],
  //   nationality: {
  //     name: 'Sudanese',
  //     isoCode: 'SDN',
  //     geoCode: 'SD',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'SR',
  //   geoCode: 'PSR',
  //   name: 'Suriname',
  //   callingCodes: ['597'],
  //   nationality: {
  //     name: 'Surinamese',
  //     isoCode: 'SUR',
  //     geoCode: 'SR',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'SJ',
  //   geoCode: 'PA51',
  //   name: 'Svalbard and Jan Mayen',
  //   callingCodes: ['47'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'SJM',
  //     geoCode: 'A51',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'SZ',
  //   geoCode: 'PA53',
  //   name: 'Swaziland',
  //   callingCodes: ['268'],
  //   nationality: {
  //     name: 'Swazi',
  //     isoCode: 'SWZ',
  //     geoCode: 'A53',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'SE',
  //   geoCode: 'PSE',
  //   name: 'Sweden',
  //   callingCodes: ['46'],
  //   nationality: {
  //     name: 'Swedish',
  //     isoCode: 'SWE',
  //     geoCode: 'SE',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'CH',
  //   geoCode: 'PCH',
  //   name: 'Switzerland',
  //   callingCodes: ['41'],
  //   nationality: {
  //     name: 'Swiss',
  //     isoCode: 'CHE',
  //     geoCode: 'CH',
  //   },
  //   idCardRequired: false,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'SY',
  //   geoCode: 'PSY',
  //   name: 'Syria',
  //   callingCodes: ['963'],
  //   nationality: {
  //     name: 'Syrian',
  //     isoCode: 'SYR',
  //     geoCode: 'SY',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'TW',
  //   geoCode: 'PTW',
  //   name: 'Taiwan',
  //   callingCodes: ['886'],
  //   nationality: {
  //     name: 'Taiwan, China',
  //     isoCode: 'TWN',
  //     geoCode: 'TW',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'TJ',
  //   geoCode: 'PTJ',
  //   name: 'Tajikistan',
  //   callingCodes: ['992'],
  //   nationality: {
  //     name: 'Tajik',
  //     isoCode: 'TJK',
  //     geoCode: 'TJ',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'TZ',
  //   geoCode: 'PTZ',
  //   name: 'Tanzania',
  //   callingCodes: ['255'],
  //   nationality: {
  //     name: 'Tanzanian',
  //     isoCode: 'TZA',
  //     geoCode: 'TZ',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'TH',
  //   geoCode: 'PTH',
  //   name: 'Thailand',
  //   callingCodes: ['66'],
  //   nationality: {
  //     name: 'Thai',
  //     isoCode: 'THA',
  //     geoCode: 'TH',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'TL',
  //   geoCode: 'PA55',
  //   name: 'Timor Leste',
  //   callingCodes: ['670'],
  //   nationality: {
  //     name: 'East Timorese',
  //     isoCode: 'TLS',
  //     geoCode: 'A55',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'TG',
  //   geoCode: 'PTG',
  //   name: 'Togo',
  //   callingCodes: ['228'],
  //   nationality: {
  //     name: 'Togolese',
  //     isoCode: 'TGO',
  //     geoCode: 'TG',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'TK',
  //   geoCode: 'PA54',
  //   name: 'Tokelau',
  //   callingCodes: ['690'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'TKL',
  //     geoCode: 'A54',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'TO',
  //   geoCode: 'PA56',
  //   name: 'Tonga',
  //   callingCodes: ['676'],
  //   nationality: {
  //     name: 'Tongan',
  //     isoCode: 'TON',
  //     geoCode: 'A56',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'TT',
  //   geoCode: 'PTT',
  //   name: 'Trinidad and Tobago',
  //   callingCodes: ['1868'],
  //   nationality: {
  //     name: 'Trinidadian',
  //     isoCode: 'TTO',
  //     geoCode: 'TT',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'TN',
  //   geoCode: 'PTN',
  //   name: 'Tunisia',
  //   callingCodes: ['216'],
  //   nationality: {
  //     name: 'Tunisian',
  //     isoCode: 'TUN',
  //     geoCode: 'TN',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'TR',
  //   geoCode: 'PTR',
  //   name: 'Turkey',
  //   callingCodes: ['90'],
  //   nationality: {
  //     name: 'Turkish',
  //     isoCode: 'TUR',
  //     geoCode: 'TR',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'TM',
  //   geoCode: 'PTM',
  //   name: 'Turkmenistan',
  //   callingCodes: ['993'],
  //   nationality: {
  //     name: 'Turkmen',
  //     isoCode: 'TKM',
  //     geoCode: 'TM',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'TC',
  //   geoCode: 'PA4',
  //   name: 'Turks and Caicos',
  //   callingCodes: ['1649'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'TCA',
  //     geoCode: 'A4',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'TV',
  //   geoCode: 'PA57',
  //   name: 'Tuvalu',
  //   callingCodes: ['688'],
  //   nationality: {
  //     name: 'Tuvaluan',
  //     isoCode: 'TUV',
  //     geoCode: 'A57',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'UG',
  //   geoCode: 'PUG',
  //   name: 'Uganda',
  //   callingCodes: ['256'],
  //   nationality: {
  //     name: 'Ugandan',
  //     isoCode: 'UGA',
  //     geoCode: 'UG',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'UA',
  //   geoCode: 'PUA',
  //   name: 'Ukraine',
  //   callingCodes: ['380'],
  //   nationality: {
  //     name: 'Ukrainian',
  //     isoCode: 'UKR',
  //     geoCode: 'UA',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'AE',
  //   geoCode: 'PAE',
  //   name: 'United Arab Emirates',
  //   callingCodes: ['971'],
  //   nationality: {
  //     name: 'Emirati',
  //     isoCode: 'ARE',
  //     geoCode: 'AE',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'GB',
  //   geoCode: 'PGB',
  //   name: 'United Kingdom',
  //   callingCodes: ['44'],
  //   nationality: {
  //     name: 'British',
  //     isoCode: 'GBR',
  //     geoCode: 'GB',
  //   },
  //   idCardRequired: false,
  //   states: [],
  // },
  {
    alpha2Code: 'US',
    geoCode: 'PUS',
    name: 'United States',
    callingCodes: ['1'],
    nationality: {
      name: 'American',
      isoCode: 'USA',
      geoCode: 'US',
    },
    idCardRequired: false,
    states: [
      {
        code: 'AL',
        codeGeo: 'UAL',
        name: 'Alabama',
      },
      {
        code: 'AK',
        codeGeo: 'UAK',
        name: 'Alaska',
      },
      {
        code: 'AZ',
        codeGeo: 'UAZ',
        name: 'Arizona',
      },
      {
        code: 'AR',
        codeGeo: 'UAR',
        name: 'Arkansas',
      },
      {
        code: 'CA',
        codeGeo: 'UCA',
        name: 'California',
      },
      {
        code: 'CO',
        codeGeo: 'UCO',
        name: 'Colorado',
      },
      {
        code: 'CT',
        codeGeo: 'UCT',
        name: 'Connecticut',
      },
      {
        code: 'DE',
        codeGeo: 'UDE',
        name: 'Delaware',
      },
      {
        code: 'DC',
        codeGeo: 'UDC',
        name: 'District of Columbia',
      },
      {
        code: 'FL',
        codeGeo: 'UFL',
        name: 'Florida',
      },
      {
        code: 'GA',
        codeGeo: 'UGA',
        name: 'Georgia',
      },
      {
        code: 'HI',
        codeGeo: 'UHI',
        name: 'Hawaii',
      },
      {
        code: 'ID',
        codeGeo: 'UID',
        name: 'Idaho',
      },
      {
        code: 'IL',
        codeGeo: 'UIL',
        name: 'Illinois',
      },
      {
        code: 'IN',
        codeGeo: 'UIN',
        name: 'Indiana',
      },
      {
        code: 'IA',
        codeGeo: 'UIA',
        name: 'Iowa',
      },
      {
        code: 'KS',
        codeGeo: 'UKS',
        name: 'Kansas',
      },
      {
        code: 'KY',
        codeGeo: 'UKY',
        name: 'Kentucky',
      },
      {
        code: 'LA',
        codeGeo: 'ULA',
        name: 'Louisiana',
      },
      {
        code: 'ME',
        codeGeo: 'UME',
        name: 'Maine',
      },
      {
        code: 'MD',
        codeGeo: 'UMD',
        name: 'Maryland',
      },
      {
        code: 'MA',
        codeGeo: 'UMA',
        name: 'Massachusetts',
      },
      {
        code: 'MI',
        codeGeo: 'UMI',
        name: 'Michigan',
      },
      {
        code: 'MN',
        codeGeo: 'UMN',
        name: 'Minnesota',
      },
      {
        code: 'MS',
        codeGeo: 'UMS',
        name: 'Mississippi',
      },
      {
        code: 'MO',
        codeGeo: 'UMO',
        name: 'Missouri',
      },
      {
        code: 'MT',
        codeGeo: 'UMT',
        name: 'Montana',
      },
      {
        code: 'NE',
        codeGeo: 'UNE',
        name: 'Nebraska',
      },
      {
        code: 'NV',
        codeGeo: 'UNV',
        name: 'Nevada',
      },
      {
        code: 'NH',
        codeGeo: 'UNH',
        name: 'New Hampshire',
      },
      {
        code: 'NJ',
        codeGeo: 'UNJ',
        name: 'New Jersey',
      },
      {
        code: 'NM',
        codeGeo: 'UNM',
        name: 'New Mexico',
      },
      {
        code: 'NY',
        codeGeo: 'UNY',
        name: 'New York',
      },
      {
        code: 'NC',
        codeGeo: 'UNC',
        name: 'North Carolina',
      },
      {
        code: 'ND',
        codeGeo: 'UND',
        name: 'North Dakota',
      },
      {
        code: 'OH',
        codeGeo: 'UOH',
        name: 'Ohio',
      },
      {
        code: 'OK',
        codeGeo: 'UOK',
        name: 'Oklahoma',
      },
      {
        code: 'OR',
        codeGeo: 'UOR',
        name: 'Oregon',
      },
      {
        code: 'PA',
        codeGeo: 'UPA',
        name: 'Pennsylvania',
      },
      {
        code: 'PR',
        codeGeo: 'UPR',
        name: 'Puerto Rico',
      },
      {
        code: 'RI',
        codeGeo: 'URI',
        name: 'Rhode Island',
      },
      {
        code: 'SC',
        codeGeo: 'USC',
        name: 'South Carolina',
      },
      {
        code: 'SD',
        codeGeo: 'USD',
        name: 'South Dakota',
      },
      {
        code: 'TN',
        codeGeo: 'UTN',
        name: 'Tennessee',
      },
      {
        code: 'TX',
        codeGeo: 'UTX',
        name: 'Texas',
      },
      {
        code: 'UT',
        codeGeo: 'UUT',
        name: 'Utah',
      },
      {
        code: 'VT',
        codeGeo: 'UVT',
        name: 'Vermont',
      },
      {
        code: 'VA',
        codeGeo: 'UVA',
        name: 'Virginia',
      },
      {
        code: 'WA',
        codeGeo: 'UWA',
        name: 'Washington',
      },
      {
        code: 'WV',
        codeGeo: 'UWV',
        name: 'West Virginia',
      },
      {
        code: 'WI',
        codeGeo: 'UWI',
        name: 'Wisconsin',
      },
      {
        code: 'WY',
        codeGeo: 'UWY',
        name: 'Wyoming',
      },
    ],
  },
  // {
  //   alpha2Code: 'UM',
  //   geoCode: 'PA58',
  //   name: 'United States Minor Outlying Isl',
  //   callingCodes: ['1'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'UMI',
  //     geoCode: 'A58',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'UY',
  //   geoCode: 'PUY',
  //   name: 'Uruguay',
  //   callingCodes: ['598'],
  //   nationality: {
  //     name: 'Uruguayan',
  //     isoCode: 'URY',
  //     geoCode: 'UY',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'UZ',
  //   geoCode: 'PUZ',
  //   name: 'Uzbekistan',
  //   callingCodes: ['998'],
  //   nationality: {
  //     name: 'Uzbek',
  //     isoCode: 'UZB',
  //     geoCode: 'UZ',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'VU',
  //   geoCode: 'PA62',
  //   name: 'Vanuatu',
  //   callingCodes: ['678'],
  //   nationality: {
  //     name: 'Ni-Vanuatu',
  //     isoCode: 'VUT',
  //     geoCode: 'A62',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'VA',
  //   geoCode: 'PVA',
  //   name: 'Vatican',
  //   callingCodes: ['379'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'VAT',
  //     geoCode: 'VA',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'VE',
  //   geoCode: 'PVE',
  //   name: 'Venezuela',
  //   callingCodes: ['58'],
  //   nationality: {
  //     name: 'Venezuelan',
  //     isoCode: 'VEN',
  //     geoCode: 'VE',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'VN',
  //   geoCode: 'PVN',
  //   name: 'Vietnam',
  //   callingCodes: ['84'],
  //   nationality: {
  //     name: 'Vietnamese',
  //     isoCode: 'VNM',
  //     geoCode: 'VN',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'VI',
  //   geoCode: 'PA61',
  //   name: 'Virgin Islands US',
  //   callingCodes: ['1340'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'VIR',
  //     geoCode: 'A61',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'WF',
  //   geoCode: 'PA63',
  //   name: 'Wallis and Futuna Islands',
  //   callingCodes: ['681'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'WLF',
  //     geoCode: 'A63',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'EH',
  //   geoCode: 'PA19',
  //   name: 'Western Sahara',
  //   callingCodes: ['212'],
  //   nationality: {
  //     name: '',
  //     isoCode: 'ESH',
  //     geoCode: 'A19',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'YE',
  //   geoCode: 'PYE',
  //   name: 'Yemen',
  //   callingCodes: ['967'],
  //   nationality: {
  //     name: 'Yemeni',
  //     isoCode: 'YEM',
  //     geoCode: 'YE',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'ZM',
  //   geoCode: 'PZM',
  //   name: 'Zambia',
  //   callingCodes: ['260'],
  //   nationality: {
  //     name: 'Zambian',
  //     isoCode: 'ZMB',
  //     geoCode: 'ZM',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
  // {
  //   alpha2Code: 'ZW',
  //   geoCode: 'PZW',
  //   name: 'Zimbabwe',
  //   callingCodes: ['263'],
  //   nationality: {
  //     name: 'Zimbabwean',
  //     isoCode: 'ZWE',
  //     geoCode: 'ZW',
  //   },
  //   idCardRequired: true,
  //   states: [],
  // },
]

export const getCountrySelectOptions = () =>
  countries.map(country => ({
    label: country.name,
    value: country.alpha2Code,
  }))

export const getCountrySelectOptionsISO = () =>
  countries.map(country => ({
    label: country.name,
    value: country.nationality.isoCode,
  }))

export const getCountryStates = alpha2Code => {
  const matchingCountry = countries.find(
    country => country.alpha2Code === alpha2Code,
  )
  const states = [{ label: 'State *', value: '' }]
  if (matchingCountry?.states) {
    matchingCountry.states.forEach(state =>
      states.push({
        label: state.name,
        value: state.code,
      }),
    )
    return states
  }

  return []
}

export const getCountryStatesISO = isoCode => {
  const matchingCountry = countries.find(
    country => country.nationality.isoCode === isoCode,
  )
  const states = [{ label: 'State *', value: '' }]
  if (matchingCountry?.states) {
    matchingCountry.states.forEach(state =>
      states.push({
        label: state.name,
        value: state.code,
      }),
    )
    return states
  }

  return []
}
