import { getBusinessCategories } from '../../api/api'

// CONSTANTS
const FETCH_BUSINESS_CATEGORIES = 'FETCH_BUSINESS_CATEGORIES'
const RECEIVE_BUSINESS_CATEGORIES = 'RECEIVE_BUSINESS_CATEGORIES'
const BUSINESS_CATEGORIES_ERROR = 'BUSINESS_CATEGORIES_ERROR'
export const CLEAR_BUSINESS_CATEGORIES = 'CLEAR_BUSINESS_CATEGORIES'

// ACTIONS
export const fetchBusinessCategories = () => dispatch =>
  getBusinessCategories()
    .then(res => {
      dispatch({ type: FETCH_BUSINESS_CATEGORIES })
      if (res?.data) {
        const data = res?.data
          .map(category => ({
            label: category?.name,
            value: category.uuid,
            order: category?.weight,
            ungerboeckCode: category?.field_ungerboeck_code?.[0],
          }))
          .sort((a, b) => (a.order < b.order ? -1 : 1))
          .filter(item => !!(item.label && item.value))

        dispatch({
          type: RECEIVE_BUSINESS_CATEGORIES,
          payload: data,
        })
      } else {
        dispatch({
          type: BUSINESS_CATEGORIES_ERROR,
          payload: 'Unable to fetch business categories',
        })
      }
    })
    .catch(() => {
      dispatch({
        type: BUSINESS_CATEGORIES_ERROR,
        payload: 'Unable to fetch business categories',
      })
    })

// REDUCER
export default function businessCategoriesReducer(
  state = { loading: false, data: null, error: null },
  action,
) {
  switch (action.type) {
    case FETCH_BUSINESS_CATEGORIES:
      return {
        ...state,
        loading: true,
      }
    case RECEIVE_BUSINESS_CATEGORIES:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }
    case BUSINESS_CATEGORIES_ERROR:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }
    case CLEAR_BUSINESS_CATEGORIES:
      return { ...state, data: null }
    default:
      return { ...state }
  }
}

// SELECTORS
export const selectBusinessCategories = state =>
  state.businessCategoriesData?.data || []
