import { getAccountFormTaxonomies } from '../../api/api'

// CONSTANTS
const FETCH_ACCOUNT_FORM_DATA = 'FETCH_ACCOUNT_FORM_DATA'
const RECEIVE_ACCOUNT_FORM_DATA = 'RECEIVE_ACCOUNT_FORM_DATA'
const ACCOUNT_FORM_DATA_ERROR = 'ACCOUNT_FORM_DATA_ERROR'

const taxonomyArray = [
  'assistanceInterest',
  'companySize',
  'contactMethod',
  'industry',
  'paymentOptions',
]

// ACTIONS
export const fetchAccountFormData = () => dispatch => {
  dispatch({ type: FETCH_ACCOUNT_FORM_DATA })
  return getAccountFormTaxonomies()
    .then(res => {
      const payload = res.reduce((obj, response, index) => {
        const key = taxonomyArray[index]
        const data = response?.data
          ?.map(term => ({
            id: term?.uuid,
            label: term?.name,
            value: term?.field_value,
          }))
          .filter(item => !!(item.label && item.id))
        return {
          ...obj,
          [key]: data,
        }
      }, {})

      dispatch({
        type: RECEIVE_ACCOUNT_FORM_DATA,
        payload,
      })
    })
    .catch(() =>
      dispatch({
        type: ACCOUNT_FORM_DATA_ERROR,
        payload: 'Unable to retrieve form data',
      }),
    )
}

// REDUCER
export default function accountFormDataReducer(
  state = { loading: false, data: null, error: null },
  action,
) {
  switch (action.type) {
    case FETCH_ACCOUNT_FORM_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case RECEIVE_ACCOUNT_FORM_DATA:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }
    case ACCOUNT_FORM_DATA_ERROR:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }
    default:
      return state
  }
}

// SELECTORS
export const selectAccountFormData = state => state?.accountFormData?.data
