import { getOfferCategories } from '../../api/api'

// CONSTANTS
const FETCH_OFFER_CATEGORIES = 'FETCH_OFFER_CATEGORIES'
const RECEIVE_OFFER_CATEGORIES = 'RECEIVE_OFFER_CATEGORIES'
const OFFER_CATEGORIES_ERROR = 'OFFER_CATEGORIES_ERROR'
export const CLEAR_OFFER_CATEGORIES = 'CLEAR_OFFER_CATEGORIES'

// ACTIONS
export const fetchOfferCategories = () => dispatch =>
  getOfferCategories()
    .then(res => {
      dispatch({ type: FETCH_OFFER_CATEGORIES })
      if (res?.data) {
        const data = res?.data
          .map(category => ({
            label: category?.name,
            value: category.uuid,
            order: category?.weight,
          }))
          .sort((a, b) => (a.order < b.order ? -1 : 1))

        dispatch({
          type: RECEIVE_OFFER_CATEGORIES,
          payload: data,
        })
      } else {
        dispatch({
          type: OFFER_CATEGORIES_ERROR,
          payload: 'Unable to fetch offer categories',
        })
      }
    })
    .catch(() => {
      dispatch({
        type: OFFER_CATEGORIES_ERROR,
        payload: 'Unable to fetch offer categories',
      })
    })

// REDUCER
export default function offerCategoriesReducer(
  state = { loading: false, data: null, error: null },
  action,
) {
  switch (action.type) {
    case FETCH_OFFER_CATEGORIES:
      return {
        ...state,
        loading: true,
      }
    case RECEIVE_OFFER_CATEGORIES:
      return {
        loading: false,
        data: action.payload,
        error: null,
      }
    case OFFER_CATEGORIES_ERROR:
      return {
        loading: false,
        data: null,
        error: action.payload,
      }
    case CLEAR_OFFER_CATEGORIES:
      return { ...state, data: null }
    default:
      return { ...state }
  }
}

// SELECTORS
export const selectOfferCategoryState = state => state.offerCategoriesData
