/* eslint-disable import/no-cycle */
import {
  getCSRFToken,
  getLoggedInStatus,
  getLogoutToken,
  login,
  logout,
} from '../../api/api'
import { fetchUser, CLEAR_USER_DATA } from '../user/fetchUser'

import { CLEAR_EVENTS } from '../events/events'
import { CLEAR_OFFERS } from '../offers/offers'
import { CLEAR_JOBS } from '../jobs/jobs'
import { CLEAR_USER_PARTNERS } from '../partners/userPartners'
import { CLEAR_REGISTRATION } from '../registration/registration'

import { CLEAR_EVENTS_CATEGORIES } from '../eventCategories/eventCategories'
import { CLEAR_OFFER_CATEGORIES } from '../offerCategories/offerCategories'
import { CLEAR_LOCATION_SERVICES } from '../locationFilters/locationFilters'
import { CLEAR_JOB_CATEGORIES } from '../jobCategories/jobCategories'

// CONSTANTS
const LOGIN_USER_BEGIN = 'LOGIN_USER_BEGIN'
const LOGIN_USER_ENDS = 'LOGIN_USER_ENDS'
const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'
const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGOUT = 'LOGOUT'
const UPDATE_CRSF_TOKEN = 'UPDATE_CRSF_TOKEN'
const UPDATE_LOGOUT_TOKEN = 'UPDATE_LOGOUT_TOKEN'
const CHECK_AUTH_START = 'CHECK_AUTH_START'
const CHECK_AUTH_END = 'CHECK_AUTH_END'

// ACTIONS
export const loginUser =
  ({ username, password }) =>
  dispatch => {
    dispatch({ type: LOGIN_USER_BEGIN })
    return login({ username, password })
      .then(res => {
        dispatch({ type: LOGIN_USER_SUCCESS, payload: res.data })
        if (res.data?.current_user?.uid) {
          dispatch(fetchUser(res.data?.current_user?.uid))
        }
      })
      .catch(err => {
        dispatch({
          type: LOGIN_USER_ERROR,
          payload: err?.response?.data?.message || 'Error logging in',
        })
      })
  }

export const logoutUser = () => (dispatch, getState) => {
  const state = getState()
  const csrfToken = selectUserToken(state)
  const logoutToken = selectLogoutToken(state)

  dispatch({ type: LOGOUT })
  dispatch({ type: CLEAR_EVENTS })
  dispatch({ type: CLEAR_OFFERS })
  dispatch({ type: CLEAR_JOBS })
  dispatch({ type: CLEAR_USER_PARTNERS })
  dispatch({ type: CLEAR_REGISTRATION })
  dispatch({ type: CLEAR_USER_DATA })
  dispatch({ type: CLEAR_EVENTS_CATEGORIES })
  dispatch({ type: CLEAR_OFFER_CATEGORIES })
  dispatch({ type: CLEAR_JOB_CATEGORIES })
  dispatch({ type: CLEAR_LOCATION_SERVICES })
  return logout({
    csrfToken,
    logoutToken,
  })
}

export const checkAuthState = () => dispatch => {
  dispatch({ type: CHECK_AUTH_START })
  return getLoggedInStatus()
    .then(res => {
      if (Number(res?.data) > 0) {
        return Promise.all([
          dispatch(fetchUser(res?.data)),
          getLogoutToken().then(tokenRes => {
            dispatch(updateLogoutToken(tokenRes?.data?.logout_token))
          }),
          getCSRFToken().then(tokenRes => {
            dispatch(updateCsrfToken(tokenRes?.data))
          }),
        ]).finally(() => dispatch({ type: CHECK_AUTH_END }))
      }
      return dispatch({ type: CHECK_AUTH_END })
    })
    .catch(() => dispatch({ type: CHECK_AUTH_END }))
}

export const updateCsrfToken = token => dispatch => {
  dispatch({ type: UPDATE_CRSF_TOKEN, payload: token })
}

export const updateLogoutToken = token => dispatch => {
  dispatch({ type: UPDATE_LOGOUT_TOKEN, payload: token })
}

// REDUCER
const initialState = {
  loading: false,
  error: null,
  data: null,
  checkAuthLoading: true,
}

export default function authenticationReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER_BEGIN:
      return { ...state, loading: true }
    case LOGIN_USER_ENDS:
      return { ...state, loading: false }
    case LOGIN_USER_SUCCESS:
      return { ...state, loading: false, data: action.payload }
    case LOGIN_USER_ERROR:
      return { ...state, loading: false, error: action.payload }
    case LOGOUT:
      return {
        loading: false,
        error: null,
        data: null,
      }
    case UPDATE_CRSF_TOKEN: {
      return {
        ...state,
        data: {
          ...state.data,
          csrf_token: action.payload,
        },
      }
    }
    case UPDATE_LOGOUT_TOKEN: {
      return {
        ...state,
        data: {
          ...state.data,
          logout_token: action.payload,
        },
      }
    }
    case CHECK_AUTH_START: {
      return {
        ...state,
        checkAuthLoading: true,
      }
    }
    case CHECK_AUTH_END: {
      return {
        ...state,
        checkAuthLoading: false,
      }
    }
    default:
      return state
  }
}

// SELECTORS
export const selectIsAuthenticated = state =>
  Boolean(state?.authenticationData?.data && state?.userData?.data)

export const selectAuthenticationState = state => state.authenticationData

export const selectUserToken = state =>
  state.authenticationData?.data?.csrf_token

const selectLogoutToken = state => state.authenticationData?.data?.logout_token
