/* eslint-disable import/no-cycle */
import { LOGOUT } from '../authentication/authentication'

// CONSTANTS
const SET_ACTIVE_PARTNER_ID = 'SET_ACTIVE_PARTNER_ID'

// ACTIONS
export const setActivePartner = (id, title) => dispatch => {
  dispatch({ type: SET_ACTIVE_PARTNER_ID, payload: { id, title } })
}

// REDUCER
const initialState = {
  id: '',
  title: '',
}

export default function activePartnerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_PARTNER_ID:
      return { ...state, ...action.payload }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

// SELECTORS
export const selectActivePartner = state => state.activePartner

export const selectActivePartnerUserRole = state =>
  state?.userPartnerData?.data?.[state?.activePartner?.id]?.userRole
