import { register } from '../../api/api'

// CONSTANTS
export const REGISTER_USER_BEGIN = 'REGISTER_USER_BEGIN'
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const CLEAR_REGISTRATION = 'CLEAR_REGISTRATION'

// ACTIONS
export const registerUser =
  ({ username, email, password, firstName, lastName, phone }) =>
  async dispatch => {
    dispatch({ type: REGISTER_USER_BEGIN })
    return register({
      username,
      email,
      password,
      firstName,
      lastName,
      phone,
    })
      .then(res => {
        if (res?.data?.uid) {
          dispatch({
            type: REGISTER_USER_SUCCESS,
            payload: res.data,
          })
        } else {
          dispatch({
            type: REGISTER_USER_ERROR,
            payload: 'There was an error creating user account',
          })
        }
      })
      .catch(err => {
        dispatch({ type: REGISTER_USER_ERROR, payload: err.message })
        throw new Error(
          err?.response?.data?.message || 'Unable to register account',
        )
      })
  }

// REDUCER
const initialState = {
  loading: false,
  error: null,
  data: null,
}

export default function registrationReducer(state = initialState, action) {
  switch (action.type) {
    case REGISTER_USER_BEGIN:
      return { ...state, loading: true }
    case REGISTER_USER_SUCCESS:
      return { ...state, loading: false, data: action.payload }
    case REGISTER_USER_ERROR:
      return { ...state, loading: false, error: action.payload }
    case CLEAR_REGISTRATION:
      return { ...state, data: null }
    default:
      return state
  }
}

// SELECTORS
export const selectRegistrationState = state => state.registrationData
