/* eslint-disable import/no-cycle */

import { fetchPartners } from '../partners/userPartners'
import { fetchEventCategories } from '../eventCategories/eventCategories'
import { fetchOfferCategories } from '../offerCategories/offerCategories'
import { fetchLocationServices } from '../locationFilters/locationFilters'
import { fetchEvents } from '../events/events'
import { fetchOffers } from '../offers/offers'
import { fetchJobs } from '../jobs/jobs'
import { fetchBusinessCategories } from '../businessCategories/businessCategories'
import { fetchAccountFormData } from '../accountFormData/accountFormData'

// CONSTANTS
const FETCH_APP_DATA = 'FETCH_APP_DATA'
const APP_DATA_END = 'APP_DATA_END'

export const getAuthAppData = uid => dispatch => {
  dispatch({ type: FETCH_APP_DATA })
  Promise.all([
    dispatch(fetchPartners(uid)),
    dispatch(fetchEventCategories()),
    dispatch(fetchOfferCategories()),
    dispatch(fetchLocationServices()),
    dispatch(fetchEvents(uid)),
    dispatch(fetchOffers(uid)),
    dispatch(fetchBusinessCategories()),
    dispatch(fetchAccountFormData()),
    dispatch(fetchJobs(uid)),
  ]).finally(() => dispatch({ type: APP_DATA_END }))
}

export default function appDataReducer(state = { loading: false }, action) {
  switch (action.type) {
    case FETCH_APP_DATA:
      return {
        loading: true,
      }
    case APP_DATA_END:
      return {
        loading: false,
      }
    default:
      return { ...state }
  }
}
