import React from 'react'
import PropTypes from 'prop-types'

const StatusPill = ({
  status,
  pendingBbClass = 'bg-pending-grey',
  archived = false,
  isLocked,
}) => {
  let statusText = status ? 'LIVE' : 'PENDING'
  if (!status && archived) {
    statusText = 'ARCHIVED'
  } else if (!status && isLocked) {
    statusText = 'EDIT PENDING'
  }

  return (
    <div
      className={`flex flex-align-center flex-justify-center border-radius-4 ${
        status ? 'bg-status-live' : pendingBbClass
      }`}
    >
      <p className="py-1 px-2 f-12 remove-margin color-white bold">
        {statusText}
      </p>
    </div>
  )
}

StatusPill.defaultProps = {
  pendingBbClass: 'bg-pending-grey',
  archived: false,
}

StatusPill.propTypes = {
  status: PropTypes.bool.isRequired,
  pendingBbClass: PropTypes.string,
  archived: PropTypes.bool,
  isLocked: PropTypes.bool.isRequired,
}

export default StatusPill
