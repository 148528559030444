import React from 'react'

const paymentFormFields = {
  contactMethod: {
    name: 'contactMethod',
    type: 'radio',
  },
  optIn: {
    name: 'optIn',
    type: 'checkbox',
    renderLabel: () => (
      <p>
        Yes, keep me up to date with Chamber news and the latest information
        about products and services from the Chamber and Visitor and Convention
        Bureau.
      </p>
    ),
  },
}

export default paymentFormFields
