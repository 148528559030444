// CONSTANTS
import { getPartners } from '../../api/api'

const FETCH_AVAILABLE_PARTNERS_BEGIN = 'FETCH_AVAILABLE_PARTNERS_BEGIN'
const FETCH_AVAILABLE_PARTNERS_ERROR = 'FETCH_AVAILABLE_PARTNERS_ERROR'
const FETCH_AVAILABLE_PARTNERS_SUCCESS = 'FETCH_AVAILABLE_PARTNERS_SUCCESS'
export const CLEAR_AVAILABLE_PARTNERS = 'CLEAR_AVAILABLE_PARTNERS'

// ACTIONS
export const fetchAvailablePartners = () => dispatch => {
  dispatch({ type: FETCH_AVAILABLE_PARTNERS_BEGIN })

  return getPartners(null)
    .then(res => {
      const payload = res?.data?.data.reduce((obj, partner) => {
        if (!partner) {
          return {
            ...obj,
          }
        }

        const id = partner.uuid
        return {
          ...obj,
          [id]: {
            id,
            ...partner,
          },
        }
      }, {})

      dispatch({
        type: FETCH_AVAILABLE_PARTNERS_SUCCESS,
        payload,
      })
    })
    .catch(err => {
      dispatch({
        type: FETCH_AVAILABLE_PARTNERS_ERROR,
        payload: err?.response?.message || 'Unable to retrieve organizations',
      })
    })
}

// REDUCER
const initialState = { loading: false, error: null, data: null }

export default function availablePartnerReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_AVAILABLE_PARTNERS_BEGIN:
      return {
        ...state,
        loading: true,
      }
    case FETCH_AVAILABLE_PARTNERS_ERROR:
      return {
        loading: false,
        error: action.payload || 'Something went wrong',
        data: null,
      }

    case FETCH_AVAILABLE_PARTNERS_SUCCESS:
      return {
        loading: false,
        error: null,
        data: action.payload,
      }
    case CLEAR_AVAILABLE_PARTNERS:
      return { ...initialState }
    default:
      return state
  }
}

// SELECTORS
export const selectAvailablePartnerState = state => state.availablePartnerData

export const selectAvailablePartners = state =>
  state.availablePartnerData?.data || {}
