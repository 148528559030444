import * as Yup from 'yup'
import formErrors from '../../../../configuration/formErrors'
import { getCountrySelectOptionsISO } from '../../../../utilities/content/supportedCountries'

const companyDetailsFormFields = {
  companyName: {
    name: 'companyName',
    type: 'text',
    isRequired: true,
    placeholder: 'Company Name *',
    validations: Yup.string().required(formErrors.requiredField),
    initialValue: '',
  },
  contactTitle: {
    name: 'contactTitle',
    type: 'select',
    placeholder: 'Title',
    options: [
      { label: 'Title', value: '' },
      { label: 'Mrs', value: 'Mrs' },
      { label: 'Ms', value: 'Ms' },
      { label: 'Mx', value: 'Mx' },
      { label: 'Mr', value: 'Mr' },
      { label: 'Dr', value: 'Dr' },
      { label: 'Other', value: 'other' },
    ],
    initialValue: '',
  },
  contactFirstName: {
    name: 'contactFirstName',
    type: 'text',
    isRequired: true,
    placeholder: 'Contact First Name *',
    validations: Yup.string().required(formErrors.requiredField),
    initialValue: '',
  },
  contactLastName: {
    name: 'contactLastName',
    type: 'text',
    isRequired: true,
    placeholder: 'Contact Last Name *',
    validations: Yup.string().required(formErrors.requiredField),
    initialValue: '',
  },
  mailingAddress: {
    name: 'mailingAddress',
    type: 'text',
    placeholder: 'Mailing Address *',
    initialValue: '',
    validations: Yup.string().required(formErrors.requiredField),
  },
  mailingAddress2: {
    name: 'mailingAddress2',
    type: 'text',
    placeholder: 'Mailing Address Line 2',
    initialValue: '',
  },
  city: {
    name: 'city',
    type: 'text',
    placeholder: 'City *',
    initialValue: '',
    validations: Yup.string().required(formErrors.requiredField),
  },
  state: {
    name: 'state',
    type: 'select',
    placeholder: 'State',
    options: [{ label: 'State', value: '' }],
    defaultOption: [{ label: 'State', value: '' }],
    initialValue: '',
    validations: Yup.string().required(formErrors.requiredField),
  },
  country: {
    name: 'country',
    type: 'select',
    placeholder: 'Country',
    initialValue: 'USA',
    options: getCountrySelectOptionsISO(),
    clearFieldOnChange: 'state',
  },
  zip: {
    name: 'zip',
    type: 'text',
    placeholder: 'Zip Code *',
    initialValue: '',
    validations: Yup.string()
      .required(formErrors.requiredField)
      .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/),
  },
  /*
  streetSameAsMail: {
    name: 'streetSameAsMail',
    type: 'checkbox',
    renderLabel: () => (
      <p key="mailing" className="m-0">
        Street address same as mailing address
      </p>
    ),
    initialValue: true,
  },
  streetMailingAddress: {
    name: 'streetMailingAddress',
    type: 'text',
    placeholder: 'Street Address',
    initialValue: '',
    validations: Yup.string().when('streetSameAsMail', {
      is: false,
      then: Yup.string().required(formErrors.requiredField),
    }),
  },
  streetMailingAddress2: {
    name: 'streetMailingAddress2',
    type: 'text',
    placeholder: 'Street Address Line 2',
    initialValue: '',
  },
  streetCity: {
    name: 'streetCity',
    type: 'text',
    placeholder: 'City *',
    initialValue: '',
    validations: Yup.string().when('streetSameAsMail', {
      is: false,
      then: Yup.string().required(formErrors.requiredField),
    }),
  },
  streetState: {
    name: 'streetState',
    type: 'select',
    placeholder: 'State',
    options: [{ label: 'State', value: '' }],
    defaultOption: [{ label: 'State', value: '' }],
    initialValue: '',
    validations: Yup.string().when('streetSameAsMail', {
      is: false,
      then: Yup.string().required(formErrors.requiredField),
    }),
  },
  streetCountry: {
    name: 'streetCountry',
    type: 'select',
    placeholder: 'Country',
    initialValue: 'USA',
    options: getCountrySelectOptionsISO(),
    clearFieldOnChange: 'state',
  },
  streetZip: {
    name: 'streetZip',
    type: 'text',
    placeholder: 'Zip Code *',
    initialValue: '',
    validations: Yup.string().when('streetSameAsMail', {
      is: false,
      then: Yup.string().required(formErrors.requiredField),
    }),
  },
  */
  phone: {
    name: 'phone',
    type: 'text',
    placeholder: 'Phone Number *',
    validations: Yup.string().required(formErrors.requiredField),
    initialValue: '',
  },
  fax: {
    name: 'fax',
    type: 'text',
    placeholder: 'Fax Number',
    initialValue: '',
  },
  email: {
    name: 'email',
    type: 'text',
    placeholder: 'Email Address *',
    validations: Yup.string().email().required(formErrors.requiredField),
    initialValue: '',
  },
  website: {
    name: 'website',
    type: 'text',
    placeholder: 'Company Website *',
    validations: Yup.string()
      .trim()
      .strict()
      .url()
      .required(formErrors.invalidURL),
    initialValue: '',
  },
  industry: {
    name: 'industry',
    type: 'select',
    placeholder: 'Please Select Your Industry *',
    options: [
      { label: 'Please Select Your Industry *', value: '' },
      { label: 'finance', value: 'finance' },
    ],
    defaultOption: [{ label: 'Industry', value: '' }],
    renderOptionsBasedOnValue: 'industry',
    validations: Yup.string().required(formErrors.requiredField),
    initialValue: '',
    isRequired: true,
  },
  companySize: {
    name: 'companySize',
    type: 'select',
    placeholder: 'Company Size *',
    options: [
      { label: 'Company Size *', value: '' },
      { label: '1-10', value: '1-10' },
    ],
    defaultOption: [{ label: 'Company Size', value: '' }],
    renderOptionsBasedOnValue: 'companySize',
    validations: Yup.string().required(formErrors.requiredField),
    initialValue: '',
    isRequired: true,
  },
  contactMethod: {
    name: 'contactMethod',
    type: 'select',
    placeholder: 'Preferred Method of Contact *',
    options: [
      { label: 'Preferred Method of Contact *', value: '' },
      { label: 'email', value: 'email' },
    ],
    renderOptionsBasedOnValue: 'contactMethod',
    validations: Yup.string().required(formErrors.requiredField),
    initialValue: '',
    isRequired: true,
  },
}

export default companyDetailsFormFields
