import React from 'react'
import * as Yup from 'yup'
import { get } from 'lodash'
import formErrors from '../../../../configuration/formErrors'
import {
  convertRawToEditorState,
  getTotalLengthFromEditorState,
} from '../../../../components/elements/WYSIWYG/utilities'

export const renderInitialFormFields = (currentJob, firstPartnerId) => {
  const formData = {
    jobName: {
      name: 'jobName',
      type: 'text',
      isRequired: true,
      placeholder: 'Job Name *',
      validations: Yup.string().required(formErrors.requiredField),
      initialValue: get(currentJob, 'title', ''),
    },
    rawDescriptionData: {
      name: 'rawDescriptionData',
      initialValue: convertRawToEditorState(currentJob?.body?.value),
      validations: Yup.object({}).test({
        message: formErrors.requiredField,
        test: value => {
          const rawLength = getTotalLengthFromEditorState(value)
          return rawLength > 0
        },
      }),
    },
    rawShortDescriptionData: {
      name: 'rawShortDescriptionData',
      initialValue: convertRawToEditorState(currentJob?.body?.value),
      validations: Yup.object({}).test({
        message: formErrors.overLimitCharacters,
        test: value => {
          const rawLength = getTotalLengthFromEditorState(value)
          return rawLength > 1 && rawLength <= 500
        },
      }),
    },
    imageUpload: {
      name: 'imageUpload',
      type: 'file',
      placeholder: 'Featured Image',
      validations: Yup.array(),
      initialValue: [get(currentJob, 'formattedJobImage', {})],
      count: 1,
      fetchAsFile: true,
    },
    bookNowURL: {
      name: 'bookNowURL',
      type: 'text',
      isRequired: false,
      placeholder: 'Apply URL',
      initialValue: get(currentJob, 'field_book_now.uri', ''),
      validations: Yup.string().url(formErrors.invalidURL),
    },
    website: {
      name: 'website',
      type: 'text',
      isRequired: false,
      placeholder: 'Website URL',
      initialValue: get(currentJob, 'field_website_url.uri', ''),
      validations: Yup.string().url(formErrors.invalidURL),
    },
    selectedPartnerId: {
      name: 'selectedPartnerId',
      validations: Yup.string().required(formErrors.requiredField),
      type: 'select',
      initialValue: get(currentJob, 'field_partner.id', firstPartnerId || ''),
    },
    status: {
      name: 'status',
      initialValue: Boolean(currentJob?.status),
      type: 'checkbox',
      renderLabel: () => <>Yes, publish job</>,
    },
    categories: {
      name: 'categories',
      type: 'checkbox',
      initialValue: {},
    },
    jobLocation: {
      name: 'jobLocation',
      initialValue: get(currentJob, 'field_job_location.uuid', ''),
      type: 'select',
    },
  }

  const initialCategories = get(currentJob, 'field_job_category', null)

  if (initialCategories?.length) {
    initialCategories.forEach(category => {
      if (category?.uuid) {
        formData.categories.initialValue = {
          ...formData.categories?.initialValue,
          [category?.uuid]: true,
        }
      }
    })
  } else {
    formData.categories.initialValue = {}
  }
  return formData
}
