import React from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from './DatePicker/DatePicker'

const FormikDateInput = ({
  disabled,
  name,
  placeholder,
  minDate = null,
  forceDateAfterMine,
}) => (
  <Field name={name}>
    {({ form: { errors, touched, values, setFieldValue } }) => {
      const error = errors[name] && touched[name] ? errors[name] : null
      return (
        <DatePicker
          name={name}
          onChange={val => {
            // cloning to avoid mutating the original value
            setFieldValue(name, val ? val.clone().toISOString() : null)
            if (val && forceDateAfterMine.length > 0) {
              forceDateAfterMine.forEach(fieldName => {
                if (
                  !values[fieldName] ||
                  val.isAfter(moment(values[fieldName]))
                ) {
                  setFieldValue(fieldName, val.clone().toISOString())
                }
              })
            }
          }}
          value={values[name] || null}
          placeholder={placeholder}
          error={error}
          disabled={disabled}
          minDate={minDate}
        />
      )
    }}
  </Field>
)

FormikDateInput.defaultProps = {
  disabled: false,
  placeholder: '',
  minDate: null,
  forceDateAfterMine: [],
}

FormikDateInput.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  minDate: PropTypes.string,
  forceDateAfterMine: PropTypes.arrayOf(PropTypes.string),
}

export default FormikDateInput
