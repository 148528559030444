import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import {
  formInitialValues,
  formValidation,
} from '../../../utilities/content/generateFormData'
import FormikInput from '../../../components/elements/FormikInput'

const ProfileSettingsForm = ({
  submit,
  initialValues,
  profileSettingsFormFields,
  error,
}) => (
  <Formik
    initialValues={
      initialValues || formInitialValues(profileSettingsFormFields)
    }
    onSubmit={submit}
    validationSchema={formValidation(profileSettingsFormFields)}
  >
    {({ handleSubmit, isSubmitting, values }) => (
      <form
        onSubmit={e => {
          e.preventDefault()
          handleSubmit(values)
        }}
        className="create-offer-form"
      >
        <h1 className="normal remove-margin">Profile Settings</h1>
        <p className="text-right">Items Marked with a * are required</p>
        <div className="bg-white p-4 p-10-md-up p-20-lg-up border border-medium-grey border-radius-4">
          <h2 className="mb-4-md-up mr-8 normal f-24">Personal Information</h2>
          <div className="pb-4 flex-md-up">
            <div className="flex-half pr-2-md-up py-2-md-max">
              <FormikInput {...profileSettingsFormFields.firstName} />
            </div>
            <div className="flex-half pl-2-md-up py-2-md-max flex-align-self-center">
              <FormikInput {...profileSettingsFormFields.lastName} />
            </div>
          </div>
          <div className="pb-2">
            <FormikInput {...profileSettingsFormFields.phone} />
          </div>
          <div className="py-2">
            <FormikInput {...profileSettingsFormFields.email} />
          </div>
          <h2 className="mt-8 pb-0 mb-2 mr-8 normal f-24">Change Password</h2>
          <div className="pb-4 flex-md-up">
            <div className="flex-half pr-2-md-up py-2-md-max">
              <FormikInput {...profileSettingsFormFields.currentPassword} />
            </div>
          </div>
          <div className="py-2 flex-md-up pb-4">
            <div className="flex-half pr-2-md-up py-2-md-max">
              <FormikInput {...profileSettingsFormFields.password} />
            </div>
            <div className="flex-half pl-2-md-up py-2-md-max flex-align-self-center">
              <FormikInput {...profileSettingsFormFields.confirmPassword} />
            </div>
          </div>
          <hr />
          {error && <p className="f-18 bold color-error">{error}</p>}
          <div className="flex flex-column flex-align-start pt-14">
            <button
              className="btn btn-primary px-16 py-5"
              type="submit"
              disabled={isSubmitting}
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    )}
  </Formik>
)

ProfileSettingsForm.defaultProps = {
  initialValues: null,
  error: null,
}

ProfileSettingsForm.propTypes = {
  submit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  profileSettingsFormFields: PropTypes.shape({
    firstName: PropTypes.shape({}),
    lastName: PropTypes.shape({}),
    email: PropTypes.shape({}),
    phone: PropTypes.shape({}),
    currentPassword: PropTypes.shape({}),
    password: PropTypes.shape({}),
    confirmPassword: PropTypes.shape({}),
  }).isRequired,
  error: PropTypes.string,
}

export default ProfileSettingsForm
