import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { SingleDatePicker } from 'react-dates'

// STYLES
import 'react-dates/lib/css/_datepicker.css'
import './style.scss'

import calIcon from '../../../assets/icons/calendar-grey.svg'

const DatePicker = ({
  disabled,
  name,
  onChange,
  value,
  placeholder,
  error,
  minDate,
}) => {
  const [focused, setFocused] = useState(false)
  const minDateValue = minDate ? moment(minDate).utc() : undefined

  let renderValue = value ? moment(value).utc() : minDateValue
  if (renderValue && minDateValue && renderValue < minDateValue) {
    renderValue = minDateValue
  }

  const onFocusChangeHandler = focusedInput => {
    setFocused(focusedInput.focused)
  }

  const isOutsideRange = day => day.isBefore(minDateValue)

  return (
    <div
      className={`custom-date-picker ${value && 'active'} ${error && 'error'}`}
    >
      <label className="floating-label" htmlFor={name}>
        {placeholder}
      </label>
      <div className="relative">
        <SingleDatePicker
          placeholder={placeholder}
          date={renderValue}
          id={name}
          focused={focused}
          onFocusChange={onFocusChangeHandler}
          onDateChange={onChange}
          noBorder
          disabled={disabled}
          isOutsideRange={isOutsideRange}
          displayFormat="YYYY-MM-DD"
          transitionDuration={0}
          numberOfMonths={window?.innerWidth <= 678 ? 1 : 2}
        />
        <img className="absolute cal-icon" src={calIcon} alt="calendar" />
      </div>
      {error && <p className="color-error remove-margin">{error}</p>}
    </div>
  )
}

DatePicker.defaultProps = {
  disabled: false,
  error: null,
  minDate: '',
  value: '',
}

DatePicker.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  minDate: PropTypes.string,
  value: PropTypes.string,
}

export default DatePicker
