import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import searchIcon from '../../../assets/icons/search.svg'
import ClickAwayHandler from '../ClickAwayHandler'

// STYLES
import './styles.scss'

const SearchableSelect = ({
  options,
  initialTextValue = '',
  placeholder = 'Start typing to search',
  handleSelection,
  submitOnEmptyInput,
  useFloatingLabel,
}) => {
  const [textValue, setTextValue] = useState(initialTextValue)
  const inputRef = useRef(null)
  const [isActive, setIsActive] = useState(false)
  const matches = textValue
    ? options.filter(option =>
        option.label.toLowerCase().includes(textValue.toLowerCase()),
      )
    : options

  function handleClickAway() {
    if (isActive) {
      setIsActive(false)
    }
    if (inputRef.current) {
      inputRef.current.blur()
    }
  }

  return (
    <ClickAwayHandler handleClickAway={handleClickAway}>
      <div
        className={`relative searchable-select full-width ${
          useFloatingLabel ? 'with-floating-label' : ''
        }`}
      >
        <div className="input-container">
          <input
            className={`f-16 bold search-field full-height  px-4 ${
              useFloatingLabel && textValue ? 'pt-8 pb-4' : 'py-6'
            }`}
            type="text"
            placeholder={placeholder}
            value={textValue}
            onChange={e => {
              if (!e.target.value.length && submitOnEmptyInput) {
                handleSelection('', null)
              }
              setTextValue(e.target.value)
            }}
            onFocus={() => {
              setIsActive(true)
            }}
            ref={inputRef}
          />
          {useFloatingLabel && (
            <p className={`m-0 floating-label ${textValue ? 'active' : ''}`}>
              {placeholder}
            </p>
          )}
          <div className="search-icon absolute">
            <img className="" src={searchIcon} alt={placeholder} />
          </div>
        </div>
        <div
          className={`absolute bg-white border full-width ${
            isActive ? '' : 'display-none'
          }`}
          style={{ zIndex: 3 }}
        >
          <div
            className=" options-container"
            style={{
              overflowY: 'scroll',
            }}
          >
            {matches?.length ? (
              matches.map(match => (
                <div key={match.value} className="border-bottom pointer bold">
                  <button
                    type="button"
                    className="btn f-14 text-left full-width"
                    onClick={() => {
                      handleSelection(match.value, match)
                      setIsActive(false)
                      setTextValue(match.label)
                    }}
                  >
                    {match.label}
                  </button>
                </div>
              ))
            ) : (
              <div className="p-8">
                <p className="m-0">No matches</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </ClickAwayHandler>
  )
}
SearchableSelect.defaultProps = {
  initialTextValue: '',
  placeholder: 'Start typing to search',
  submitOnEmptyInput: true,
  useFloatingLabel: false,
}
SearchableSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  initialTextValue: PropTypes.string,
  placeholder: PropTypes.string,
  handleSelection: PropTypes.func.isRequired,
  submitOnEmptyInput: PropTypes.bool,
  useFloatingLabel: PropTypes.bool,
}
export default SearchableSelect
