import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const ClickAwayHandler = ({ handleClickAway, children }) => {
  const ref = useRef(null)

  useEffect(() => {
    function handleOutSideClick(e) {
      if (!ref.current || !ref.current.contains(e.target)) {
        handleClickAway()
      }
    }

    function handleEscKey(e) {
      if (e.keyCode === 27) {
        handleClickAway()
      }
    }

    window.addEventListener('click', handleOutSideClick)
    window.addEventListener('keydown', handleEscKey)
    return () => {
      window.removeEventListener('click', handleOutSideClick)
      window.removeEventListener('keydown', handleEscKey)
    }
  })

  return <div ref={ref}>{children}</div>
}

ClickAwayHandler.propTypes = {
  handleClickAway: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
}

export default ClickAwayHandler
