import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import formErrors from '../../configuration/formErrors'
import FormikInput from '../../components/elements/FormikInput'
import SimpleHeader from '../../components/structure/SimpleHeader'
import { lostPassword } from '../../api/api'
import SimpleSpinner from '../../components/elements/SimpleSpinner'

const ForgotPassword = () => {
  const [view, setView] = useState('form')

  function submit(values) {
    return lostPassword(values.email).finally(() => {
      setView('success')
      return values
    })
  }

  return (
    <>
      <SimpleHeader />
      <div className="container-centered container-md px-2 py-20">
        {view === 'form' ? (
          <div>
            <h1>Forgot Password?</h1>
            <p>
              Please enter your email below and we will send you a link to reset
              your password.
            </p>
            <Formik
              onSubmit={submit}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email(formErrors.invalidEmail)
                  .required(formErrors.requiredField),
              })}
              initialValues={{ email: '' }}
            >
              {({ isSubmitting, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <FormikInput name="email" type="text" placeholder="Email *" />
                  <div className="pt-10">
                    {isSubmitting ? (
                      <SimpleSpinner />
                    ) : (
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Send
                      </button>
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </div>
        ) : (
          <div>
            <h1>Thank You.</h1>
            <p>An email with instructions was just sent to your inbox.</p>
            <button
              type="button"
              className="btn btn-text-only no-text-transform"
              onClick={() => setView('form')}
            >
              Resend
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default ForgotPassword
