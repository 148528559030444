import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { selectUserToken } from '../../redux/authentication/authentication'
import { createEvent, updateEvent } from '../../api/api'
import { fetchEvents, selectEventById } from '../../redux/events/events'
import { selectUserUid } from '../../redux/user/fetchUser'
import TimeDateForm from './forms/TimeDateForm'
import { formatFormDataForSubmission } from './utilities/formatEvent'

import { formatRRule } from './utilities/formatRRule'
import Stepper from '../../components/elements/Stepper/Stepper'
import OrganizationSelect from './forms/OrganizationSelect'
import { organizationSelectFields } from './forms/formFields/organizationSelectFields'
import EventDetailsForm from './forms/EventDetailsForm'
import { eventDetailsFormFields } from './forms/formFields/eventDetailsFormFields'
import LocationDetailsForm from './forms/LocationDetailsForm'
import { locationDetailsFormFields } from './forms/formFields/locationDetailsFormFields'
import { timeDateFormFields } from './forms/formFields/timeDateFormFields'
import ContactDetailsForm from './forms/ContactDetailsForm'
import { contactDetailsFormFields } from './forms/formFields/contactDetailsFormFields'

import { formInitialValues } from '../../utilities/content/generateFormData'
import {
  fetchEventCategories,
  selectEventCategoryState,
} from '../../redux/eventCategories/eventCategories'
import {
  getUserTypeFromPartnerId,
  selectUserPartnerOptionsWithNonMemberAccountCode,
} from '../../redux/partners/userPartners'
import { selectActivePartner } from '../../redux/activePartner/activePartner'
import BrandLoader from '../../components/elements/BrandLoader'
import {
  fetchLocationServices,
  selectLocationServicesState,
} from '../../redux/locationFilters/locationFilters'
import routes from '../../configuration/routes'

const formSteps = [
  'General Event Details',
  'Location Details',
  'Time and Date',
  'Contact Details',
]

const EditEvent = () => {
  const { id: currentEventId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const userToken = useSelector(selectUserToken)
  const eventCategoryState = useSelector(selectEventCategoryState)
  const locationServicesState = useSelector(selectLocationServicesState)
  const partnerList = useSelector(
    selectUserPartnerOptionsWithNonMemberAccountCode,
  )
  const activePartner = useSelector(selectActivePartner)

  const currentEvent = useSelector(state =>
    selectEventById(state, currentEventId),
  )

  const uid = useSelector(selectUserUid)

  const isEdit = currentEvent !== null

  useEffect(() => {
    const { loading, data, error } = eventCategoryState

    if (!loading && !data && !error) {
      dispatch(fetchEventCategories())
    }

    if (
      !locationServicesState.loading &&
      !locationServicesState.data &&
      !locationServicesState.error
    ) {
      dispatch(fetchLocationServices())
    }
  }, [eventCategoryState])

  const initialOrganizationSelectFields = organizationSelectFields(currentEvent)
  const initialEventDetails = eventDetailsFormFields(currentEvent)
  const initialLocationDetails = locationDetailsFormFields(currentEvent)
  const initialTimeDateDetails = timeDateFormFields(currentEvent)
  const initialContactDetails = contactDetailsFormFields(currentEvent)

  const [activeStep, setActiveStep] = useState(0)
  const [formValues, setFormValues] = useState({
    organizationSelectDetails: formInitialValues(
      organizationSelectFields(currentEvent, activePartner.id),
    ),
    eventDetails: formInitialValues(initialEventDetails),
    locationDetails: formInitialValues(initialLocationDetails),
    timeDateDetails: formInitialValues(initialTimeDateDetails),
    contactDetails: formInitialValues(initialContactDetails),
  })

  const [submissionError, setSubmissionError] = useState(null)

  const userRole = useSelector(state =>
    getUserTypeFromPartnerId(
      state,
      formValues?.organizationSelectDetails?.organizationName,
    ),
  )

  function handleStepChange(step) {
    setActiveStep(step)
    window.scrollTo(0, 0)
  }

  if (
    ((eventCategoryState?.loading || !eventCategoryState.data) &&
      !eventCategoryState.error) ||
    ((locationServicesState?.loading || !locationServicesState.data) &&
      !locationServicesState.error)
  ) {
    return (
      <div className="pt-14 text-center container-centered container-sm">
        <BrandLoader />
      </div>
    )
  }

  if (currentEvent?.hasLiveRevision || activeStep === 4) {
    return (
      <div className="px-2 py-10 full-height">
        <div className="py-10 container-centered-lg">
          <div className="bg-white py-8 border border-medium-grey border-radius-4">
            <p className="text-center f-18 text-center m-0">
              {activeStep === 4
                ? 'Thank You. Your event has been submitted and is pending approval.'
                : 'This event is pending review and can not be updated at this time'}
            </p>
            <div className="pt-8 text-center">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => history.push(routes.events)}
              >
                Return to Events
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  function renderForm() {
    const shouldDisableForms =
      !formValues?.organizationSelectDetails?.organizationName

    const formDisabledMessage = 'Please select an organization above'

    switch (activeStep) {
      case 1:
        return (
          <LocationDetailsForm
            submit={locationDetails => {
              setFormValues({
                ...formValues,
                locationDetails,
              })
              handleStepChange(2)
            }}
            back={() => handleStepChange(0)}
            locationDetailsFormFields={initialLocationDetails}
            initialValues={formValues.locationDetails}
            locations={locationServicesState.data}
            disableSubmit={shouldDisableForms}
            disabledMessage={formDisabledMessage}
          />
        )
      case 2:
        return (
          <TimeDateForm
            submit={timeDateDetails => {
              setFormValues({
                ...formValues,
                timeDateDetails,
              })
              handleStepChange(3)
            }}
            back={() => handleStepChange(1)}
            timeDateFormFields={initialTimeDateDetails}
            initialValues={formValues.timeDateDetails}
            disableSubmit={shouldDisableForms}
            disabledMessage={formDisabledMessage}
          />
        )
      case 3:
        return (
          <ContactDetailsForm
            submit={contactDetails => {
              setFormValues({
                ...formValues,
                contactDetails,
              })

              if (!formValues.organizationSelectDetails.organizationName) {
                window.scrollTo(0, 0)
                return false
              }

              const rule = formatRRule(formValues.timeDateDetails, true)

              setSubmissionError(null)
              const reqBody = formatFormDataForSubmission({
                selectedPartnerId:
                  formValues.organizationSelectDetails.organizationName,
                eventDetails: formValues.eventDetails,
                locationDetails: formValues.locationDetails,
                timeDateDetails: formValues.timeDateDetails,
                contactDetails,
                rrule: formValues.timeDateDetails.isRepeating
                  ? rule.toString()
                  : '',
              })

              if (currentEventId) {
                reqBody.id = currentEventId
              }
              const eventFunction = currentEventId ? updateEvent : createEvent
              const eventOptions = { reqBody, token: userToken }
              if (currentEventId) {
                eventOptions.currentEventId = currentEventId
              }
              return eventFunction(eventOptions)
                .then(res => {
                  if (res.data?.data) {
                    dispatch(fetchEvents(uid))
                    handleStepChange(4)
                    return res.data?.data
                  }
                  setSubmissionError('Unable to create event')
                  return false
                })
                .catch(err => {
                  if (process.env.NODE_ENV === 'development') {
                    // eslint-disable-next-line
                    console.log(err)
                  }
                  setSubmissionError(
                    err?.response?.data?.errors?.[0]?.detail ||
                      'Unable to create event',
                  )
                  return false
                })
            }}
            back={() => handleStepChange(2)}
            initialValues={formValues.contactDetails}
            contactDetailsFormFields={initialContactDetails}
            isUpdate={Boolean(currentEventId)}
            submissionError={submissionError}
            disableSubmit={shouldDisableForms}
            disabledMessage={formDisabledMessage}
          />
        )
      case 0:
      default:
        return (
          <EventDetailsForm
            submit={eventDetails => {
              setFormValues({
                ...formValues,
                eventDetails,
              })
              handleStepChange(1)
            }}
            eventDetailsFormFields={initialEventDetails}
            initialValues={formValues.eventDetails}
            categories={eventCategoryState?.data}
            isAdmin={userRole === 'admin'}
            disableSubmit={shouldDisableForms}
            disabledMessage={formDisabledMessage}
          />
        )
    }
  }
  return (
    <div className="px-2 py-14 edit-event">
      <div className="pb-8">
        <OrganizationSelect
          submit={organizationSelectDetails => {
            setFormValues({
              ...formValues,
              organizationSelectDetails,
            })
          }}
          organizationSelectFields={initialOrganizationSelectFields}
          partnerList={partnerList}
          initialValues={formValues.organizationSelectDetails}
          disabled={Boolean(currentEventId)}
          editing={isEdit}
        />
      </div>
      <div className="container-centered-lg">
        <p className="text-right-lg-up text-center-lg-max mb-4 mt-2-lg-max">
          Items Marked with a * are required
        </p>
        <div className="bg-white edit-event__form py-16 px-8-md-max border border-medium-grey border-radius-4">
          {activeStep !== 4 && (
            <Stepper
              activeStep={activeStep}
              steps={formSteps}
              handleStepChange={handleStepChange}
            />
          )}
          <div className="py-8">{renderForm(activeStep)}</div>
        </div>
      </div>
    </div>
  )
}

export default EditEvent
