import React from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'

import { get } from 'lodash'
import Checkbox from './Checkbox/Checkbox'
import CheckboxGroup from './CheckboxGroup/CheckboxGroup'
import TextInput from './TextInput/TextInput'
import Select from './Select/Select'
import Radio from './Radio/Radio'
import TextArea from './TextArea/TextArea'
import UploadContainer from './FileUploader/UploadContainer'

const FormikInput = ({
  disabled,
  name,
  options,
  placeholder,
  renderLabel,
  type,
  rows,
  clearFieldOnChange,
  errorShowOnTop,
  count,
  fetchAsFile,
  onFocus,
  onBlur,
  isDisabled,
  onChangeCallback,
}) => {
  function renderInput({ error, value, handleChange, setFieldValue }) {
    switch (type) {
      case 'radio':
        return (
          <Radio
            name={name}
            onChange={handleChange}
            renderLabel={renderLabel}
            checked={value}
            options={options}
            error={error}
            errorShowOnTop={errorShowOnTop}
          />
        )
      case 'checkbox':
        return (
          <Checkbox
            name={name}
            onChange={e => {
              if (clearFieldOnChange) {
                setFieldValue(clearFieldOnChange, '')
              }
              const newValue = !value
              setFieldValue(e.target.name, newValue)
              if (onChangeCallback) {
                onChangeCallback(newValue)
              }
            }}
            renderLabel={renderLabel}
            checked={value || false}
            error={error}
            disabled={disabled}
          />
        )
      case 'checkboxGroup':
        return (
          <CheckboxGroup
            name={name}
            options={options}
            onChange={e => {
              const values = value.trim() !== '' ? value.split(',') : []
              const valueIndex = values.indexOf(e.target.dataset.groupvalue)
              if (valueIndex !== -1) {
                values.splice(valueIndex, 1)
              } else {
                values.push(e.target.dataset.groupvalue)
              }

              setFieldValue(name, values.length > 0 ? values.join(',') : '')
            }}
            renderLabel={renderLabel}
            error={error}
            value={value && value.trim() !== '' ? value.split(',') : []}
            isDisabled={isDisabled}
          />
        )
      case 'select':
        return (
          <Select
            name={name}
            onChange={
              clearFieldOnChange
                ? e => {
                    setFieldValue(e.target.name, e.target.value)
                    setFieldValue(clearFieldOnChange, '')
                  }
                : handleChange
            }
            options={options}
            placeholder={placeholder}
            value={value || ''}
            error={error}
            disabled={disabled}
          />
        )
      case 'textArea':
        return (
          <TextArea
            type={type}
            name={name}
            rows={rows}
            onChange={handleChange}
            value={value || ''}
            error={error}
          />
        )
      case 'file':
        return (
          <UploadContainer
            uploadCount={count}
            fieldValue={name}
            setFieldValue={setFieldValue}
            value={value || []}
            placeholder={placeholder}
            fetchAsFile={fetchAsFile}
          />
        )
      case 'hidden':
        return <input value={value || ''} type={type} />
      default:
        return (
          <TextInput
            type={type}
            name={name}
            onChange={handleChange}
            value={value || ''}
            placeholder={placeholder}
            error={error}
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        )
    }
  }

  return (
    <Field name={name}>
      {({ form: { errors, touched, values, handleChange, setFieldValue } }) =>
        renderInput({
          error:
            get(errors, name) && get(touched, name) ? get(errors, name) : null,
          value: get(values, name),
          handleChange,
          setFieldValue,
        })
      }
    </Field>
  )
}

FormikInput.defaultProps = {
  clearFieldOnChange: null,
  disabled: false,
  isDisabled: () => false,
  options: null,
  placeholder: '',
  renderLabel: () => null,
  type: 'text',
  rows: null,
  errorShowOnTop: false,
  count: 1,
  fetchAsFile: false,
  onFocus: null,
  onBlur: null,
  onChangeCallback: null,
}

FormikInput.propTypes = {
  clearFieldOnChange: PropTypes.string,
  disabled: PropTypes.bool,
  isDisabled: PropTypes.func,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  placeholder: PropTypes.string,
  renderLabel: PropTypes.func,
  type: PropTypes.string,
  rows: PropTypes.string,
  errorShowOnTop: PropTypes.bool,
  count: PropTypes.number,
  fetchAsFile: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChangeCallback: PropTypes.func,
}

export default FormikInput
