import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { Formik } from 'formik'
import {
  formInitialValues,
  formValidation,
} from '../../../utilities/content/generateFormData'
import FormikInput from '../../../components/elements/FormikInput'
import FormikDateInput from '../../../components/elements/FormikDateInput'
import WysiwygEditor from '../../../components/elements/WYSIWYG/WysiwygEditor'
import Select from '../../../components/elements/Select/Select'
import SimpleSpinner from '../../../components/elements/SimpleSpinner'

const OfferForm = ({
  submit,
  initialValues,
  categories,
  partnerList,
  offerFormFields,
  error,
  editing,
}) => {
  const { id: currentOfferId } = useParams()

  const partnerOptions = [
    {
      label: 'Please Select',
      value: '',
      disabled: true,
    },
  ]

  if (partnerList?.length) {
    partnerList.forEach(partner => {
      if (
        partner?.id?.length &&
        partner?.title?.length &&
        partner?.userRole &&
        !!partner?.field_account?.field_account_code &&
        partner?.userRole !== 'pending'
      ) {
        partnerOptions.push({
          ...partner,
          label: partner?.title || '',
          value: partner?.id,
          disabled: false,
        })
      }
    })
  }

  function renderOrgName(id) {
    if (!id) {
      return ''
    }
    const matchingPartner = partnerOptions.find(partner => partner.value === id)

    return matchingPartner?.label || ''
  }

  return (
    <Formik
      initialValues={initialValues || formInitialValues(offerFormFields)}
      onSubmit={submit}
      validationSchema={formValidation(offerFormFields)}
    >
      {({
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values,
        errors,
        touched,
      }) => (
        <form
          onSubmit={e => {
            e.preventDefault()
            handleSubmit(values)
          }}
          className="create-offer-form"
        >
          <div className="text-center-lg-max flex-lg-up pb-2-lg-max pb-8-lg-up flex-align-center">
            <h1 className="mb-0-lg-up mr-8 normal f-24 f-16-lg-max mb-4-lg-max text-center-lg-max">
              {editing ? 'Edit' : 'Create'} an Offer For
            </h1>
            <Select
              onChange={e => {
                setFieldValue('selectedPartnerId', e.target.value)
                setFieldValue('status', Boolean(initialValues?.status))
              }}
              value={values?.selectedPartnerId}
              name="selectedPartnerId"
              options={partnerOptions}
              disabled={Boolean(currentOfferId)}
            />
            {touched?.selectedPartnerId && error?.selectedPartnerId && (
              <p>{error.selectedPartnerId}</p>
            )}
          </div>

          <p className="text-right-lg-up text-center-lg-max pt-8-lg-max">
            Items Marked with a * are required
          </p>
          <div className="offer-form bg-white px-8 py-10 p-20-lg-up border border-medium-grey border-radius-4">
            <h2 className="mb-16-md-up mr-8 normal f-24">
              General Offer Details
            </h2>
            <div className="pb-6">
              <FormikInput {...offerFormFields.offerName} />
            </div>
            <div className="pb-6">
              <p className="bold f-18 mb-2">Long Description *</p>

              <WysiwygEditor
                onChange={value => setFieldValue('rawDescriptionData', value)}
                initialState={values?.rawDescriptionData || null}
              />
              {touched?.rawDescriptionData && errors?.rawDescriptionData && (
                <p className="color-error remove-margin">
                  {errors.rawDescriptionData}
                </p>
              )}
              <p className="f-12 my-1">(Max. 500 characters)</p>
            </div>

            <div className="py flex-md-up pb-6">
              <div className="flex-half pr-2-md-up pb-4-md-max">
                <p className="bold f-18 mb-2">Launches *</p>
                <FormikDateInput
                  name="launches"
                  forceDateAfterMine={['expires']}
                />
              </div>
              <div className="flex-half pr-2-md-up">
                <p className="bold f-18 mb-2">Expires *</p>
                <FormikDateInput
                  name="expires"
                  minDate={values?.launches || null}
                />
              </div>
            </div>
            <div className="py-2 pb-8">
              <FormikInput {...offerFormFields.imageUpload} />
            </div>
            <div className="pb-4-md-up flex-md-up">
              <div className="flex-half pr-2-md-up">
                <FormikInput {...offerFormFields.phone} />
              </div>
              <div className="flex-half pr-2-md-up">
                <FormikInput
                  {...offerFormFields.website}
                  onFocus={() => {
                    if (!values?.website) {
                      setFieldValue(offerFormFields.website.name, 'https://')
                    }
                  }}
                  onBlur={() => {
                    if (
                      values?.website === 'https://' ||
                      values?.website === 'http://'
                    ) {
                      setFieldValue(offerFormFields.website.name, '')
                    }
                  }}
                />
                <p className="f-12 my-1">
                  These must be external URLs such as http://example.com
                </p>
              </div>
            </div>
            <div className="pb-4 flex-md-up">
              <div className="flex-half pr-2-md-up pt-2-md-max pb-4">
                <FormikInput
                  {...offerFormFields.bookNowURL}
                  onFocus={() => {
                    if (!values?.bookNowURL) {
                      setFieldValue(offerFormFields.bookNowURL.name, 'https://')
                    }
                  }}
                  onBlur={() => {
                    if (
                      values?.bookNowURL === 'https://' ||
                      values?.bookNowURL === 'http://'
                    ) {
                      setFieldValue(offerFormFields.bookNowURL.name, '')
                    }
                  }}
                />
                <p className="f-12 my-1">
                  These must be external URLs such as http://example.com
                </p>
              </div>
              <div className="flex-half pl-2-md-up" />
            </div>
            <div className="px-2 pb-6">
              <p className="bold f-18 mb-2">Offer Categories</p>
              <p>Select all that apply for your offer</p>
              <div className="flex flex-wrap">
                {categories.map(option => (
                  <div key={option.value} className="flex-half">
                    <FormikInput
                      name={`categories.${option.value}`}
                      type="checkbox"
                      placeholder={option.label}
                      renderLabel={() => option.label}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="pb-8 px-2 radios">
              <p className="bold f-18">Paid Status</p>
              <FormikInput {...offerFormFields.paid} />
            </div>

            <hr />
            <div className="flex flex-column flex-align-center pt-14">
              {values?.selectedPartnerId ? (
                <p className="mb-8 f-18">
                  You are creating this offer on behalf of:{' '}
                  <strong className="italic">
                    {renderOrgName(values?.selectedPartnerId)}{' '}
                  </strong>
                </p>
              ) : (
                <p className="mb-8">Please select an organization above</p>
              )}
              {error && <p className="bold f18 color-error">{error}</p>}
              {isSubmitting ? (
                <div className="flex flex-justify-center">
                  <SimpleSpinner />
                </div>
              ) : (
                <button
                  className="btn btn-primary px-16 py-5"
                  type="submit"
                  disabled={isSubmitting || !values?.selectedPartnerId}
                >
                  Submit Offer
                </button>
              )}
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}

OfferForm.defaultProps = {
  initialValues: null,
  error: null,
}

OfferForm.propTypes = {
  submit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    status: PropTypes.bool,
  }),
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  partnerList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  offerFormFields: PropTypes.shape({
    selectedPartnerId: PropTypes.shape({}),
    offerName: PropTypes.shape({}),
    imageUpload: PropTypes.shape({}),
    phone: PropTypes.shape({}),
    website: PropTypes.shape({
      name: PropTypes.string,
    }),
    bookNowURL: PropTypes.shape({
      name: PropTypes.string,
    }),
    publishOn: PropTypes.shape({}),
    paid: PropTypes.shape({}),
    status: PropTypes.shape({}),
  }).isRequired,
  error: PropTypes.string,
  editing: PropTypes.bool.isRequired,
}

export default OfferForm
