import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { Formik } from 'formik'
import {
  formInitialValues,
  formValidation,
} from '../../../utilities/content/generateFormData'
import FormikInput from '../../../components/elements/FormikInput'
import WysiwygEditor from '../../../components/elements/WYSIWYG/WysiwygEditor'
import Select from '../../../components/elements/Select/Select'
import SimpleSpinner from '../../../components/elements/SimpleSpinner'

const JobForm = ({
  submit,
  initialValues,
  categories,
  locations,
  partnerList,
  jobFormFields,
  error,
  editing,
}) => {
  const { id: currentJobId } = useParams()

  const partnerOptions = [
    {
      label: 'Please Select',
      value: '',
      disabled: true,
    },
  ]

  if (partnerList?.length) {
    partnerList.forEach(partner => {
      if (
        partner?.id?.length &&
        partner?.title?.length &&
        partner?.userRole &&
        !!partner?.field_account?.field_account_code &&
        partner?.userRole !== 'pending'
      ) {
        partnerOptions.push({
          ...partner,
          label: partner?.title || '',
          value: partner?.id,
          disabled: false,
        })
      }
    })
  }

  function renderOrgName(id) {
    if (!id) {
      return ''
    }
    const matchingPartner = partnerOptions.find(partner => partner.value === id)

    return matchingPartner?.label || ''
  }

  if (partnerOptions.length <= 1) {
    return <p>You do not belong to any organizations currently.</p>
  }

  return (
    <Formik
      initialValues={initialValues || formInitialValues(jobFormFields)}
      onSubmit={submit}
      validationSchema={formValidation(jobFormFields)}
    >
      {({
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        values,
        errors,
        touched,
        handleBlur,
      }) => (
        <form
          onSubmit={e => {
            e.preventDefault()
            handleSubmit(values)
          }}
          className="create-job-form"
        >
          <div className="text-center-lg-max flex-lg-up pb-2-lg-max pb-8-lg-up flex-align-center">
            <h1 className="mb-0-lg-up mr-8 normal f-24 f-16-lg-max mb-4-lg-max text-center-lg-max">
              {editing ? 'Edit' : 'Post'} a Job For
            </h1>
            <Select
              onChange={e => {
                setFieldValue('selectedPartnerId', e.target.value)
                setFieldValue('status', Boolean(initialValues?.status))
              }}
              value={values?.selectedPartnerId}
              name="selectedPartnerId"
              options={partnerOptions}
              disabled={Boolean(currentJobId)}
              onBlur={handleBlur}
            />
            {touched?.selectedPartnerId && error?.selectedPartnerId && (
              <p>{error.selectedPartnerId}</p>
            )}
          </div>
          {values.selectedPartnerId ? (
            <>
              <p className="text-right-lg-up text-center-lg-max pt-8-lg-max">
                Items Marked with a * are required
              </p>
              <div className="offer-form bg-white px-8 py-10 p-20-lg-up border border-medium-grey border-radius-4">
                <h2 className="mb-16-md-up mr-8 normal f-24">
                  General Job Details
                </h2>
                <div className="pb-6">
                  <FormikInput {...jobFormFields.jobName} onBlur={handleBlur} />
                </div>
                <div className="pb-6">
                  <p className="bold f-18 mb-2">Short Description *</p>

                  <WysiwygEditor
                    onChange={value => {
                      setFieldValue('rawShortDescriptionData', value)
                      if (value !== '') {
                        setFieldTouched('rawShortDescriptionData', value)
                      }
                    }}
                    initialState={values?.rawShortDescriptionData || null}
                  />
                  {touched?.rawShortDescriptionData &&
                    errors?.rawShortDescriptionData && (
                      <p className="color-error remove-margin">
                        {errors.rawShortDescriptionData}
                      </p>
                    )}
                  <p className="f-12 my-1">(Max. 500 characters)</p>
                </div>
                <div className="pb-6">
                  <p className="bold f-18 mb-2">Long Description *</p>

                  <WysiwygEditor
                    onChange={value => {
                      setFieldValue('rawDescriptionData', value)
                      if (value !== '') {
                        setFieldTouched('rawDescriptionData', value)
                      }
                    }}
                    initialState={values?.rawDescriptionData || null}
                  />
                  {touched?.rawDescriptionData &&
                    errors?.rawDescriptionData && (
                      <p className="color-error remove-margin">
                        {errors.rawDescriptionData}
                      </p>
                    )}
                </div>

                <div className="py-2 pb-8">
                  <FormikInput {...jobFormFields.imageUpload} />
                </div>
                <div className="pb-4-md-up flex-md-up">
                  <div className="flex-half pr-2-md-up">
                    <FormikInput
                      {...jobFormFields.website}
                      onFocus={() => {
                        if (!values?.website) {
                          setFieldValue(jobFormFields.website.name, 'https://')
                        }
                      }}
                      onBlur={e => {
                        if (
                          values?.website === 'https://' ||
                          values?.website === 'http://'
                        ) {
                          setFieldValue(jobFormFields.website.name, '')
                        }
                        handleBlur(e)
                      }}
                    />
                    <p className="f-12 my-1">
                      These must be external URLs such as http://example.com
                    </p>
                  </div>
                  <div className="flex-half pt-2-md-max pb-4">
                    <FormikInput
                      {...jobFormFields.bookNowURL}
                      onFocus={() => {
                        if (!values?.bookNowURL) {
                          setFieldValue(
                            jobFormFields.bookNowURL.name,
                            'https://',
                          )
                        }
                      }}
                      onBlur={e => {
                        if (
                          values?.bookNowURL === 'https://' ||
                          values?.bookNowURL === 'http://'
                        ) {
                          setFieldValue(jobFormFields.bookNowURL.name, '')
                        }
                        handleBlur(e)
                      }}
                    />
                    <p className="f-12 my-1">
                      These must be external URLs such as http://example.com
                    </p>
                  </div>
                  <div className="flex-half pl-2-md-up" />
                </div>
                <div className="px-2 pb-6">
                  <p className="bold f-18 mb-2">Category</p>
                  <p>Select category for this job</p>
                  <div className="flex-md-up flex-wrap-md-up pt-6">
                    {categories?.length > 0 &&
                      categories.map(category => (
                        <div className="flex-half" key={category.value}>
                          <FormikInput
                            {...jobFormFields.categories}
                            name={`categories.${category.value}`}
                            placeholder={category.label}
                            renderLabel={() => (
                              <p className="mb-0">{category.label}</p>
                            )}
                          />
                        </div>
                      ))}

                    {touched?.categories && errors?.categories && (
                      <p className="color-error">{errors?.categories}</p>
                    )}
                  </div>
                </div>
                <div className="px-2 pb-6">
                  <p className="bold f-18 mb-2">Location</p>
                  <p>Select location for this job</p>
                  <div className="flex flex-wrap">
                    <FormikInput
                      name="jobLocation"
                      type="select"
                      placeholder="Select location for this job"
                      renderLabel={() => ''}
                      {...jobFormFields.jobLocation}
                      options={[
                        {
                          label: 'Please Select',
                          value: '',
                          disabled: true,
                        },
                        ...locations,
                      ]}
                    />
                  </div>
                </div>
                <hr />
                <div className="flex flex-column flex-align-center pt-14">
                  {values?.selectedPartnerId ? (
                    <p className="mb-8 f-18">
                      You are creating this offer on behalf of:{' '}
                      <strong className="italic">
                        {renderOrgName(values?.selectedPartnerId)}{' '}
                      </strong>
                    </p>
                  ) : (
                    <p className="mb-8">Please select an organization above</p>
                  )}
                  {error && <p className="bold f18 color-error">{error}</p>}
                  {isSubmitting ? (
                    <div className="flex flex-justify-center">
                      <SimpleSpinner />
                    </div>
                  ) : (
                    <button
                      className="btn btn-primary px-16 py-5"
                      type="submit"
                      disabled={
                        Object.keys(errors).length > 0 ||
                        isSubmitting ||
                        !values?.selectedPartnerId
                      }
                    >
                      Submit Job Posting
                    </button>
                  )}
                </div>
              </div>
            </>
          ) : (
            <p>Please select an organization.</p>
          )}
        </form>
      )}
    </Formik>
  )
}

JobForm.defaultProps = {
  initialValues: null,
  error: null,
}

JobForm.propTypes = {
  submit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    status: PropTypes.bool,
  }),
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  partnerList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  jobFormFields: PropTypes.shape({
    selectedPartnerId: PropTypes.shape({}),
    jobName: PropTypes.shape({}),
    imageUpload: PropTypes.shape({}),
    website: PropTypes.shape({
      name: PropTypes.string,
    }),
    bookNowURL: PropTypes.shape({
      name: PropTypes.string,
    }),
    jobLocation: PropTypes.shape({}),
    categories: PropTypes.shape({}),
    publishOn: PropTypes.shape({}),
  }).isRequired,
  error: PropTypes.string,
  editing: PropTypes.bool.isRequired,
}

export default JobForm
