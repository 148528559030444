import { get } from 'lodash'

export const organizationSelectFields = (currentEvent, activePartnerId) => {
  const formData = {
    organizationName: {
      name: 'organizationName',
      type: 'select',
      options: [
        { label: 'Verb Interactive', value: 'Verb Interactive' },
        { label: 'Holy Tequila', value: 'Holy Tequila' },
      ],
      initialValue: get(
        currentEvent,
        'field_partner.id',
        activePartnerId || '',
      ),
    },
  }
  return formData
}
