import React, { useState } from 'react'
import PropTypes from 'prop-types'

// ASSETS
import eyeIcon from '../../../assets/icons/eye.svg'

// STYLES
import './styles.scss'

const TextInput = ({
  disabled,
  type,
  name,
  onChange,
  value,
  placeholder,
  error,
  onFocus,
  onBlur,
}) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div
      className={`custom-text-input ${value && 'active'} ${error && 'error'}`}
    >
      <label className="floating-label" htmlFor={name}>
        {placeholder}
      </label>
      <div className="relative">
        <input
          id={name}
          type={showPassword && type === 'password' ? 'text' : type}
          name={name}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onFocus={() => {
            if (onFocus) {
              onFocus()
            }
          }}
          onBlur={e => {
            if (onBlur) onBlur(e)
          }}
        />
        {type === 'password' && (
          <button
            className="btn absolute p-0 password-reveal"
            type="button"
            onClick={e => {
              e.preventDefault()
              setShowPassword(!showPassword)
            }}
          >
            <div className="flex flex-align-center">
              <img
                src={eyeIcon}
                alt={showPassword ? 'hide password' : 'show password'}
              />
            </div>
          </button>
        )}
      </div>
      {error && <p className="color-error remove-margin">{error}</p>}
    </div>
  )
}

TextInput.defaultProps = {
  disabled: false,
  error: null,
  type: 'text',
  onFocus: null,
  onBlur: null,
}

TextInput.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}

export default TextInput
