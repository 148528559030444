import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Stepper from '../../components/elements/Stepper/Stepper'
import CompanyDetailsForm from './forms/CompanyDetailsForm'
import BusinessDetailsForm from './forms/BusinessDetailsForm'
import PaymentForm from './forms/PaymentForm'
import companyDetailsFormFields from './forms/formFields/companyDetailsFormFields'
import businessDetailsFormFields from './forms/formFields/businessDetailsFormFields'
import paymentFormFields from './forms/formFields/paymentFormFields'

import { formInitialValues } from '../../utilities/content/generateFormData'
import './style.scss'
import formatRequestBody from './forms/utilities/formatRequestBody'
import { selectUserUid, selectUserUuid } from '../../redux/user/fetchUser'
import { selectUserToken } from '../../redux/authentication/authentication'
import {
  createOrganization,
  triggerOrganizationImport,
  addUserToAccount,
} from '../../api/api'
import { fetchPartners } from '../../redux/partners/userPartners'

import { selectAccountFormData } from '../../redux/accountFormData/accountFormData'
import routes from '../../configuration/routes'

const getMatchingData = (arr, value) =>
  arr?.find(option => option.value === value)

const formSteps = ['Company Contact Details', 'Business Details', 'Payment']

const RegisterOrganization = () => {
  const userUuid = useSelector(selectUserUuid)
  const userUid = useSelector(selectUserUid)
  const userToken = useSelector(selectUserToken)

  const accountFormDataOptions = useSelector(selectAccountFormData)
  const history = useHistory()
  const [activeStep, setActiveStep] = useState(0)
  const [formValues, setFormValues] = useState({
    companyDetails: formInitialValues(companyDetailsFormFields),
    businessDetails: formInitialValues(businessDetailsFormFields),
    paymentDetails: formInitialValues(paymentFormFields),
  })
  const [submissionError, setSubmissionError] = useState(null)
  const dispatch = useDispatch()

  function submit(formData) {
    let selectedInterest = null
    setSubmissionError(null)
    if (formData?.businessDetails?.assistanceInterest) {
      const matchingOption = accountFormDataOptions?.assistanceInterest?.find(
        option =>
          option.value === formData?.businessDetails?.assistanceInterest,
      )

      if (matchingOption?.value) {
        selectedInterest = {
          type: 'taxonomy_term--assistance_interest',
          id: matchingOption.id,
          field_value: matchingOption.value,
        }
      }
    }

    const { accountData } = formatRequestBody({
      ...formData,
      userUuid,
      selectedInterest,
      companySizeData: getMatchingData(
        accountFormDataOptions?.companySize,
        formData?.companyDetails?.companySize,
      ),
      preferredContactData: getMatchingData(
        accountFormDataOptions?.contactMethod,
        formData?.companyDetails?.contactMethod,
      ),
      industryData: getMatchingData(
        accountFormDataOptions?.industry,
        formData?.companyDetails?.industry,
      ),
      paymentContactData: getMatchingData(
        accountFormDataOptions?.paymentOptions,
        formData?.paymentDetails?.contactMethod,
      ),
    })

    return createOrganization(accountData, userToken).then(res =>
      triggerOrganizationImport(res?.data?.[0]?.id, userToken)
        .then(importRes => {
          const matchingAccountId = importRes?.data?.uuid

          const currentPendingMembers = []

          currentPendingMembers.push({
            uuid: userUuid,
          })

          if (!matchingAccountId) {
            return setSubmissionError('Unable to add Organization')
          }
          return addUserToAccount({
            accountId: matchingAccountId,
            token: userToken,
            members: currentPendingMembers.map(member => ({
              id: member.uuid,
              type: 'user--user',
            })),
          })
            .then(() => {
              dispatch(fetchPartners(userUid))
              setActiveStep(3)
            })
            .catch(err => {
              // eslint-disable-next-line
              console.log('.catch error ', err)
              setSubmissionError(
                err?.response?.data?.errors?.[0]?.detail ||
                  'Unable to create organization',
              )
            })
        })
        .catch(err => {
          if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line
            console.log(err?.response)
          }
          setSubmissionError(
            err?.response?.data?.errors?.[0]?.detail ||
              'Unable to join organization',
          )
        })
        .catch(err => {
          if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line
            console.log(err?.response)
          }
          setSubmissionError(
            err?.response?.data?.errors?.[0]?.detail ||
              'Unable to join organization',
          )
        }),
    )
  }

  function handleStepChange(step) {
    setActiveStep(step)
    window.scrollTo(0, 0)
  }

  function renderForm() {
    switch (activeStep) {
      case 1:
        return (
          <BusinessDetailsForm
            submit={businessDetails => {
              setFormValues({
                ...formValues,
                businessDetails,
              })
              handleStepChange(2)
            }}
            back={() => handleStepChange(0)}
            initialValues={formValues.businessDetails}
            assistanceInterestOptions={
              accountFormDataOptions?.assistanceInterest || []
            }
          />
        )
      case 2:
        return (
          <PaymentForm
            submit={paymentDetails => {
              setFormValues({
                ...formValues,
                paymentDetails,
              })
              return submit({
                ...formValues,
                paymentDetails,
              })
            }}
            back={() => handleStepChange(1)}
            initialValues={formValues.paymentDetails}
            paymentOptions={accountFormDataOptions?.paymentOptions || []}
          />
        )
      case 3:
        return (
          <div className="text-center">
            <p className="text-center f-18">
              Thank you. Your organization has been submitted and is pending
              approval.
            </p>
            <div className="pt-8 text-center">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  dispatch(fetchPartners(userUid))
                  history.push(routes.dashboard)
                }}
              >
                Return Home
              </button>
            </div>
          </div>
        )
      case 0:
      default:
        return (
          <CompanyDetailsForm
            submit={companyDetails => {
              setFormValues({
                ...formValues,
                companyDetails,
              })
              handleStepChange(1)
            }}
            initialValues={formValues.companyDetails}
            industryOptions={accountFormDataOptions?.industry || []}
            companySizeOptions={accountFormDataOptions?.companySize || []}
            contactMethodOptions={accountFormDataOptions?.contactMethod || []}
          />
        )
    }
  }

  return (
    <div className="py-12 py-16-lgr-up register-org">
      <h1 className="text-center pb-12 pb-2-lgr-up mb-0 px-6-lg-max">
        Organization Membership Application
      </h1>

      <div className="container-centered-lgr px-13-lg-up">
        <p className="text-right remove-margin pb-4 requirements-reminder">
          Items Marked with a * are required
        </p>
        <div className="bg-white form-container border border-medium-grey border-radius-4">
          {activeStep !== 3 && (
            <Stepper
              activeStep={activeStep}
              steps={formSteps}
              handleStepChange={handleStepChange}
            />
          )}
          <div className="py-8-lg-up pt-8-lg-max pb-2-lg-max">
            {renderForm(activeStep)}
          </div>
          {submissionError && (
            <p className="text-center color-error f-18">{submissionError}</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default RegisterOrganization
