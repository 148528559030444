import facebookIcon from '../../assets/icons/facebook-icon.svg'
import instagramIcon from '../../assets/icons/instagram-icon.svg'
import twitterIcon from '../../assets/icons/twitter-icon.svg'
import youtubeIcon from '../../assets/icons/youtube-icon.svg'
// import googleIcon from '../../assets/icons/google-icon.svg'

export const socialLinksData = [
  {
    icon: facebookIcon,
    label: 'Facebook',
    name: 'facebook',
    href: 'https://www.facebook.com/',
  },
  {
    icon: instagramIcon,
    label: 'Instagram',
    name: 'instagram',
    href: 'https://www.instagram.com/',
  },
  {
    icon: twitterIcon,
    label: 'Twitter',
    name: 'twitter',
    href: 'https://twitter.com/',
  },
  {
    icon: youtubeIcon,
    label: 'YouTube Channel',
    name: 'youtube_channel',
    href: 'https://www.youtube.com/channel/',
  },
]
