import React, { useState } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'

import SimpleHeader from '../../components/structure/SimpleHeader'
import formErrors from '../../configuration/formErrors'
import FormikInput from '../../components/elements/FormikInput'
import routes from '../../configuration/routes'
import { resetPassword } from '../../api/api'
import SimpleSpinner from '../../components/elements/SimpleSpinner'

const ResetPassword = ({ handleRouteChange }) => {
  const tempPassword = queryString.parse(window.location.search)?.token
  const [error, setError] = useState(null)

  const location = useLocation()
  const history = useHistory()

  function submit(formValues) {
    setError(null)
    const reqBody = {
      name: formValues.userName,
      temp_pass: tempPassword,
      new_pass: formValues.password,
    }

    return resetPassword(reqBody)
      .then(res => {
        const queryParams = new URLSearchParams(location.search)
        queryParams.delete('token')
        history.replace({
          search: queryParams.toString(),
        })
        handleRouteChange(
          routes.login,
          {
            message:
              res?.data?.message ||
              'Your password has been updated, please login.',
          },
          queryParams.toString()?.length ? `?${queryParams.toString()}` : '',
        )
      })
      .catch(err => {
        setError(err?.response?.data?.message || 'Unable to reset password')
      })
  }

  return (
    <>
      <SimpleHeader />
      <div className="container-centered container-md px-2 py-20">
        <h1>Reset Password?</h1>
        {tempPassword ? (
          <>
            <p>Enter and confirm your password to reset it</p>
            <Formik
              onSubmit={submit}
              validationSchema={Yup.object({
                userName: Yup.string().required(formErrors.requiredField),
                password: Yup.string().required(formErrors.requiredField),
                confirmPassword: Yup.string()
                  .required(formErrors.requiredField)
                  .oneOf([Yup.ref('password')], formErrors.passwordMismatch),
              })}
              initialValues={{
                userName: '',
                password: '',
                confirmPassword: '',
              }}
            >
              {({ isSubmitting, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <FormikInput
                    name="userName"
                    type="text"
                    placeholder="Username or email address *"
                  />
                  <div className="pt-4">
                    <FormikInput
                      name="password"
                      type="password"
                      placeholder="New Password *"
                    />
                  </div>
                  <div className="py-4">
                    <FormikInput
                      name="confirmPassword"
                      type="password"
                      placeholder="Confirm Password *"
                    />
                  </div>
                  {error && <p className="color-error f-18 bold">{error}</p>}
                  {isSubmitting ? (
                    <SimpleSpinner />
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      Update
                    </button>
                  )}
                </form>
              )}
            </Formik>
          </>
        ) : (
          <div>
            <p>Unable to reset password. </p>
            <div className="pt-8">
              <Link
                to={routes.forgotPassword}
                className="btn btn-primary no-text-decoration"
              >
                Request a reset
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

ResetPassword.propTypes = {
  handleRouteChange: PropTypes.func.isRequired,
}

export default ResetPassword
