import * as Yup from 'yup'
import { get, isNil } from 'lodash'
import formErrors from '../../../../configuration/formErrors'
import { getCountrySelectOptions } from '../../../../utilities/content/supportedCountries'

export const locationDetailsFormFields = currentEvent => {
  const address = currentEvent?.field_address
  return {
    mainLocation: {
      name: 'mainLocation',
      type: 'radio',
      inline: true,
      errorShowOnTop: true,
      validations: Yup.string().required(formErrors.requiredOptions),
      initialValue: get(currentEvent, 'field_location.uuid', ''),
    },

    locationName: {
      name: 'locationName',
      type: 'text',
      placeholder: 'Location Name',
      initialValue: get(address, 'organization', ''),
    },

    streetAddress: {
      name: 'streetAddress',
      type: 'text',
      isRequired: true,
      placeholder: 'Street Address *',
      validations: Yup.string().required(formErrors.requiredField),
      initialValue: get(address, 'address_line1', ''),
    },
    streetAddress2: {
      name: 'streetAddress2',
      type: 'text',
      placeholder: 'Street Address Line 2',
      initialValue: isNil(address?.address_line2) ? '' : address?.address_line2,
    },
    city: {
      name: 'city',
      type: 'text',
      placeholder: 'City *',
      validations: Yup.string().required(formErrors.requiredField),
      initialValue: get(address, 'locality', ''),
    },
    state: {
      name: 'state',
      type: 'select',
      placeholder: 'State *',
      options: [{ label: 'State *', value: '' }],
      defaultOption: [{ label: 'State *', value: '' }],
      initialValue: get(address, 'administrative_area', ''),
      validations: Yup.string().required(formErrors.requiredField),
      isRequired: true,
    },
    country: {
      name: 'country',
      type: 'select',
      placeholder: 'Country *',
      initialValue: 'US',
      options: getCountrySelectOptions(),
      clearFieldOnChange: 'state',
      validations: Yup.string().required(formErrors.requiredField),
      isRequired: true,
    },
    zip: {
      name: 'zip',
      type: 'text',
      placeholder: 'Zip Code *',
      validations: Yup.string().required(formErrors.requiredField),
      initialValue: get(address, 'postal_code', ''),
      isRequired: true,
    },
    latitude: {
      name: 'latitude',
      type: 'text',
      placeholder: 'Latitude',
      validations: Yup.number()
        .typeError(formErrors.invalidNumber)
        .required(formErrors.requiredField),
      initialValue: get(currentEvent, 'field_geolocation.lat', '').toString(),
    },
    longitude: {
      name: 'longitude',
      type: 'text',
      placeholder: 'Longitude',
      validations: Yup.number()
        .typeError(formErrors.invalidNumber)
        .required(formErrors.requiredField),
      initialValue: get(currentEvent, 'field_geolocation.lng', '').toString(),
    },
    directions: {
      name: 'directions',
      type: 'textArea',
      rows: '12',
      initialValue: isNil(currentEvent?.field_directions)
        ? ''
        : currentEvent?.field_directions,
    },
  }
}
