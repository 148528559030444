export function renderDisplayName(data) {
  if (!data?.createdBy) return ''

  let displayName = data.createdBy?.display_name || ''

  if (data.createdBy?.field_first_name || data.createdBy?.field_last_name) {
    displayName = `${data.createdBy?.field_first_name || ''} ${
      data.createdBy?.field_last_name || ''
    }`
  }

  return displayName
}

const fieldCalendarLabels = {
  community: 'hiltonheadisland.org',
  chamber: 'hiltonheadchamber.org',
  bluffton: 'visitbluffton.org',
}

export function renderPublishedOn(calendars) {
  if (!calendars || !calendars?.length) {
    return null
  }

  return calendars
    .map(key => fieldCalendarLabels[key] || null)
    .filter(val => !!val)
    .join(', ')
}
