import React from 'react'
import PropTypes from 'prop-types'

import '../TextInput/styles.scss'

const Select = ({
  value,
  onChange,
  error,
  options,
  placeholder,
  name,
  disabled,
  ariaLabel,
}) => (
  <div
    className={`custom-select-input ${value && placeholder ? 'active' : ''} ${
      error ? 'error' : ''
    }`}
  >
    <label className="floating-label" htmlFor={name}>
      {placeholder}
    </label>
    <select
      value={value}
      onChange={onChange}
      name={name}
      aria-label={ariaLabel}
      disabled={disabled}
    >
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>

    {error && <p className="color-error remove-margin">{error}</p>}
  </div>
)

Select.defaultProps = {
  disabled: false,
  error: null,
  placeholder: null,
  ariaLabel: 'Select Partner Organization',
}

Select.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
}

export default Select
