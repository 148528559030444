import React from 'react'
import * as Yup from 'yup'
import { get } from 'lodash'
import moment from 'moment'
import { RRule, rrulestr } from 'rrule'
import formErrors from '../../../../configuration/formErrors'
import {
  weekdayOptions,
  frequencyOptions,
  nthOptions,
  includeOptions,
} from '../../utilities/getStaticOptions'

export const timeDateFormFields = currentEvent => {
  const { rules, ruleSet } = getRRuleFromEvent(currentEvent)
  const isNth = Boolean(
    rules?.origOptions?.byweekday &&
      rules?.origOptions?.byweekday.length > 0 &&
      rules?.origOptions?.byweekday[0].n,
  )

  const initialIncludedDates = []
  const initialExcludedDates = []
  if (ruleSet) {
    const includedDates = ruleSet.rdates()
    includedDates.forEach(incDate => {
      initialIncludedDates.push(incDate.toString())
    })
    const excludedDates = ruleSet.exdates()
    excludedDates.forEach(exDate => {
      initialExcludedDates.push(exDate.toString())
    })
  }

  const eventDate = currentEvent?.field_event_date

  const callForTimes = get(currentEvent, 'field_call_for_times', false)
  const isAllDayEvent = Boolean(get(currentEvent, 'field_all_day_event', false))
  const formData = {
    startDate: {
      name: 'startDate',
      isRequired: true,
      initialValue: get(eventDate, 'value')
        ? moment(get(eventDate, 'value')).clone().utc().toISOString()
        : '',
      validations: Yup.string().required(formErrors.requiredField),
      forceDateAfterMine: ['endDate', 'rruleIncludeDate'],
    },
    startTimeSelection: {
      name: 'startTimeSelection',
      type: 'select',
      placeholder: 'Start Time',
      options: [{ label: 'Start Time', value: '' }],
      validations: Yup.string().when(['callTimes', 'allDayEvent'], {
        is: (callTimes, allDayEvent) => callTimes || allDayEvent,
        then: Yup.string(),
        otherwise: Yup.string().required(formErrors.requiredField),
      }),
      initialValue:
        get(eventDate, 'value') && !callForTimes && !isAllDayEvent
          ? moment(get(eventDate, 'value')).clone().utc().format('HH:mm:ss')
          : '',
    },
    endDate: {
      name: 'endDate',
      isRequired: true,
      validations: Yup.mixed().test({
        message: formErrors.endDateMin,
        // eslint-disable-next-line
        test: function (value) {
          const {
            startDate,
            startTimeSelection,
            endTimeSelection,
            callTimes,
            allDayEvent,
          } = this.parent

          return (
            ((callTimes || allDayEvent) &&
              !startTimeSelection &&
              value >= startDate) ||
            value > startDate ||
            (value === startDate &&
              ((!startTimeSelection && !endTimeSelection) ||
                endTimeSelection > startTimeSelection))
          )
        },
      }),
      initialValue: get(eventDate, 'end_value')
        ? moment(get(eventDate, 'end_value')).clone().utc().toISOString()
        : '',
    },
    endTimeSelection: {
      name: 'endTimeSelection',
      type: 'select',
      isRequired: false,
      placeholder: 'End Time',
      options: [{ label: 'End Time', value: '' }],
      validations: Yup.string().when(['callTimes', 'allDayEvent'], {
        is: (callTimes, allDayEvent) => callTimes || allDayEvent,
        then: Yup.string(),
        otherwise: Yup.string().required(formErrors.requiredField),
      }),
      initialValue:
        get(eventDate, 'end_value') && !callForTimes && !isAllDayEvent
          ? moment(get(eventDate, 'end_value')).clone().utc().format('HH:mm:ss')
          : '',
    },
    isRepeating: {
      name: 'isRepeating',
      type: 'checkbox',
      renderLabel: () => (
        <p key="yes" className="m-0">
          Yes
        </p>
      ),
      initialValue: !!get(eventDate, 'rrule', false),
    },
    rruleFrequency: {
      name: 'rruleFrequency',
      type: 'select',
      placeholder: 'Frequency',
      options: frequencyOptions,
      initialValue: rules?.options?.freq?.toString() || '',
      validations: Yup.string().when('isRepeating', {
        is: true,
        then: schema => schema.required('Required'),
        otherwise: schema => schema,
      }),
    },
    rruleInterval: {
      name: 'rruleInterval',
      type: 'text',
      placeholder: 'Every ...',
      initialValue: rules?.origOptions?.interval
        ? rules.origOptions.interval.toString()
        : '',
      validations: Yup.number().when('isRepeating', {
        is: true,
        then: schema =>
          schema
            .typeError('Please enter a number')
            .positive('Please enter a number')
            .integer('Please enter an integer')
            .required('Required'),
        otherwise: schema => schema,
      }),
    },
    rruleNth: {
      name: 'rruleNth',
      type: 'select',
      placeholder: 'on the ...',
      options: nthOptions,
      initialValue: isNth ? rules?.origOptions?.byweekday[0]?.n?.toString() : 0,
    },
    rruleByWeekDay: {
      name: 'rruleByWeekDay',
      initialValue: {},
      type: 'checkbox',
    },
    rruleByMonth: {
      name: 'rruleByMonth',
      initialValue: {},
      type: 'checkbox',
    },
    rruleCount: {
      name: 'rruleCount',
      type: 'text',
      initialValue: rules?.origOptions?.count
        ? rules.origOptions.count.toString()
        : '',
      placeholder: 'Max. Occurences',
    },
    // Drupal currently only allows exclude. Leaving this in case that changes
    // see getStaticOptions.js for other commented out code.
    rruleIncludeOptions: {
      name: 'rruleIncludeOptions',
      type: 'hidden',
      initialValue: 'exclude',
      placeholder: 'Exclude',
      options: includeOptions,
    },
    rruleIncludeDate: {
      name: 'rruleIncludeDate',
    },
    rruleIncluded: {
      name: 'rruleIncluded',
      type: 'hidden',
      initialValue: initialIncludedDates.join(','),
    },
    rruleExcluded: {
      name: 'rruleExcluded',
      type: 'hidden',
      initialValue: initialExcludedDates.join(','),
    },
    callTimes: {
      name: 'callTimes',
      type: 'checkbox',
      renderLabel: () => (
        <p key="yes" className="m-0">
          Call for times
        </p>
      ),
      initialValue: callForTimes,
    },
    allDayEvent: {
      name: 'allDayEvent',
      type: 'checkbox',
      renderLabel: () => (
        <p key="yes" className="m-0">
          This is an all day event
        </p>
      ),
      initialValue: isAllDayEvent,
    },
  }

  const initialRRuleByWeekDay = get(
    rules,
    isNth ? 'options.bynweekday' : 'options.byweekday',
    null,
  )

  if (initialRRuleByWeekDay?.length) {
    initialRRuleByWeekDay.forEach(weekDayOption => {
      const value = isNth
        ? weekdayOptions[weekDayOption[0]].value
        : weekdayOptions[weekDayOption].value
      formData.rruleByWeekDay.initialValue = {
        ...formData.rruleByWeekDay?.initialValue,
        [value]: true,
      }
    })
  } else {
    formData.rruleByWeekDay.initialValue = {}
  }

  const initialRRuleByMonth = get(rules, 'options.bymonth', null)

  if (initialRRuleByMonth?.length) {
    initialRRuleByMonth.forEach(monthOption => {
      const value = monthOption
      formData.rruleByMonth.initialValue = {
        ...formData.rruleByMonth?.initialValue,
        [value]: true,
      }
    })
  } else {
    formData.rruleByMonth.initialValue = {}
  }

  return formData
}

export const getRRuleFromEvent = currentEvent => {
  const rruleString = get(currentEvent, 'field_event_date.rrule')
  const parts = rruleString ? rruleString.split('\n') : []
  // we need both the RRules and the RuleSet,
  // as the RRules contains the origOptions we need because of a bug
  // where the original rules and the "interpretted" rules npm are different
  const rules = parts[0] ? RRule.fromString(parts[0]) : null
  const ruleSet = parts[1] ? rrulestr(rruleString, { forceset: true }) : null
  return { rules, ruleSet }
}
