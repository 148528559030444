import { combineReducers } from 'redux'
import accountFormDataReducer from './accountFormData/accountFormData'
import appDataReducer from './appData/appData'
import authenticationReducer from './authentication/authentication'
import eventCategoriesReducer from './eventCategories/eventCategories'
import eventsReducer from './events/events'
import offerCategoriesReducer from './offerCategories/offerCategories'
import locationServiceReducer from './locationFilters/locationFilters'
import jobCategoriesReducer from './jobCategories/jobCategories'
import offersReducer from './offers/offers'
import userPartnerReducer from './partners/userPartners'
import registrationReducer from './registration/registration'
import userReducer from './user/fetchUser'
import activePartnerReducer from './activePartner/activePartner'
import businessCategoriesReducer from './businessCategories/businessCategories'
import availablePartnerReducer from './partners/availablePartners'
import contentReducer from './content/content'
import jobLocationsReducer from './jobLocations/jobLocations'
import jobReducer from './jobs/jobs'

const rootReducer = combineReducers({
  accountFormData: accountFormDataReducer,
  activePartner: activePartnerReducer,
  appData: appDataReducer,
  authenticationData: authenticationReducer,
  availablePartnerData: availablePartnerReducer,
  businessCategoriesData: businessCategoriesReducer,
  contentData: contentReducer,
  eventCategoriesData: eventCategoriesReducer,
  eventsData: eventsReducer,
  jobsData: jobReducer,
  locationServicesData: locationServiceReducer,
  offerCategoriesData: offerCategoriesReducer,
  offersData: offersReducer,
  registrationData: registrationReducer,
  userData: userReducer,
  userPartnerData: userPartnerReducer,
  jobCategoriesData: jobCategoriesReducer,
  jobLocationsData: jobLocationsReducer,
})

export default rootReducer
