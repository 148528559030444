import {
  convertEditorStateToPlainText,
  convertEditorStateToRaw,
} from '../../../../components/elements/WYSIWYG/utilities'

export default function formatRequestBody(formData = {}) {
  const {
    companyDetails,
    businessDetails,
    // paymentDetails, need to add unger support for these fields?
    // userUuid,
    selectedInterest,
    companySizeData,
    preferredContactData,
    industryData,
    paymentContactData,
  } = formData

  const accountData = {
    createRegistrationsParameters: [
      {
        name: companyDetails?.companyName,
        country: companyDetails?.country,
        state: companyDetails?.state,
        city: companyDetails?.city,
        postalCode: `${companyDetails?.zip}`,
        address1: companyDetails?.mailingAddress,
        address2: companyDetails?.mailingAddress2,
        phone: companyDetails?.phone,
        lastName: companyDetails?.contactLastName,
        firstName: companyDetails?.contactFirstName,
        fax: companyDetails?.fax,
        email: companyDetails?.email,
        website: companyDetails?.website || '',
        preferredContactMethod: preferredContactData?.value || 'email',
        details: {
          contactMethod: paymentContactData?.value || 'email',
          currentlyLicensed: businessDetails?.licensed === 'yes' ? 'Y' : 'N',
          handicappedAccessible:
            businessDetails?.accessible === 'yes' ? 'Y' : 'N',
          interest: selectedInterest?.field_value || '', // We currently only accept 1 value. In the future, may convert to multiple
          numberHotelRooms: businessDetails?.numOfRooms || '',
          numberRestaurantSeats: businessDetails?.numOfRestaurants || '',
          industry: industryData?.value || '',
          numberEmployees: companySizeData?.value || '',
          productServiceCode: businessDetails?.directoryCategory || '',
        },
      },
    ],
  }

  const partnerData = {
    type: 'node--partner',
    attributes: {
      title: companyDetails?.companyName,
      moderation_state: 'draft',
      field_website_url: {
        uri: companyDetails?.website?.trim() || '',
      },
      body: {
        value: convertEditorStateToRaw(businessDetails?.body),
        format: 'basic_html',
        summary: convertEditorStateToPlainText(businessDetails?.body),
      },
    },
    relationships: {},
  }

  /*
  if (
    businessDetails?.logoUpload.length > 0 &&
    Object.keys(businessDetails?.logoUpload[0]).length > 0 &&
    businessDetails.logoUpload?.[0]?.drupalId
  ) {
    partnerData.relationships.field_partner_logo = {
      data: {
        type: 'file--file',
        id: businessDetails.logoUpload?.[0]?.drupalId,
      },
    }
  }

  if (
    businessDetails?.imageUpload.length > 0 &&
    Object.keys(businessDetails?.imageUpload[0]).length > 0
  ) {
    const data = businessDetails.imageUpload
      .map(image => ({
        type: 'file--file',
        id: image.drupalId,
      }))
      .filter(image => image.id)

    partnerData.relationships.field_partner_images = { data }
    }
  */

  return {
    accountData,
    partnerData,
  }
}
