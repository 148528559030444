import React from 'react'
import * as Yup from 'yup'
import { get } from 'lodash'
import moment from 'moment'
import formErrors from '../../../../configuration/formErrors'
import {
  convertRawToEditorState,
  getTotalLengthFromEditorState,
} from '../../../../components/elements/WYSIWYG/utilities'

export const renderInitialFormFields = (currentOffer, firstPartnerId) => {
  const formData = {
    offerName: {
      name: 'offerName',
      type: 'text',
      isRequired: true,
      placeholder: 'Offer Name *',
      validations: Yup.string().required(formErrors.requiredField),
      initialValue: get(currentOffer, 'title', ''),
    },
    rawDescriptionData: {
      name: 'rawDescriptionData',
      initialValue: convertRawToEditorState(currentOffer?.body?.value),
      validations: Yup.object({}).test({
        message: formErrors.requiredField,
        test: value => {
          const rawLength = getTotalLengthFromEditorState(value)
          return rawLength > 0 && rawLength <= 500
        },
      }),
    },
    launches: {
      name: 'launches',
      validations: Yup.string().required(formErrors.requiredField),
      initialValue: get(currentOffer, 'field_offer_launches')
        ? moment(get(currentOffer, 'field_offer_launches'))
            .clone()
            .toISOString()
        : '',
    },
    expires: {
      name: 'expires',
      validations: Yup.string().required(formErrors.requiredField),
      initialValue: get(currentOffer, 'field_offer_expires')
        ? moment(get(currentOffer, 'field_offer_expires')).clone().toISOString()
        : '',
    },
    imageUpload: {
      name: 'imageUpload',
      type: 'file',
      placeholder: 'Offer Image',
      validations: Yup.array(),
      initialValue: [get(currentOffer, 'formattedOfferImage', {})],
      count: 1,
      fetchAsFile: true,
    },
    phone: {
      name: 'phone',
      type: 'text',
      isRequired: false,
      placeholder: 'Phone Number',
      initialValue: get(currentOffer, 'field_phone_number', ''),
    },
    website: {
      name: 'website',
      type: 'text',
      isRequired: false,
      placeholder: 'Website URL',
      initialValue: get(currentOffer, 'field_website_url.uri', ''),
      validations: Yup.string().url(formErrors.invalidURL),
    },
    bookNowURL: {
      name: 'bookNowURL',
      type: 'text',
      isRequired: false,
      placeholder: 'Book Now URL',
      initialValue: get(currentOffer, 'field_book_now.uri', ''),
      validations: Yup.string().url(formErrors.invalidURL),
    },
    paid: {
      name: 'paid',
      type: 'checkbox',
      isRequired: false,
      renderLabel: () => <>Yes, this offer is paid content</>,
      initialValue: Boolean(get(currentOffer, 'field_paid_offer', false)),
    },
    selectedPartnerId: {
      name: 'selectedPartnerId',
      validations: Yup.string().required(formErrors.requiredField),
      type: 'select',
      initialValue: get(currentOffer, 'field_partner.id', firstPartnerId || ''),
    },
    categories: {
      name: 'categories',
      initialValue: {},
    },
    status: {
      name: 'status',
      initialValue: Boolean(currentOffer?.status),
      type: 'checkbox',
      renderLabel: () => <>Yes, publish offer</>,
    },
  }
  const initialCategories = get(currentOffer, 'field_offer_categories', null)

  if (initialCategories?.length) {
    initialCategories.forEach(category => {
      if (category?.uuid) {
        formData.categories.initialValue = {
          ...formData.categories?.initialValue,
          [category?.uuid]: true,
        }
      }
    })
  }
  return formData
}
