import { RRule } from 'rrule'

export const weekdayOptions = [
  { label: 'Monday', value: 0 },
  { label: 'Tuesday', value: 1 },
  { label: 'Wednesday', value: 2 },
  { label: 'Thursday', value: 3 },
  { label: 'Friday', value: 4 },
  { label: 'Saturday', value: 5 },
  { label: 'Sunday', value: 6 },
]

export const weekDayConstants = {
  0: RRule.MO,
  1: RRule.TU,
  2: RRule.WE,
  3: RRule.TH,
  4: RRule.FR,
  5: RRule.SA,
  6: RRule.SU,
}

export const monthOptions = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
]

export const frequencyOptions = [
  { label: 'Event Frequency', value: '' },
  { label: 'Hourly', value: RRule.HOURLY.toString() },
  { label: 'Daily', value: RRule.DAILY.toString() },
  { label: 'Weekly', value: RRule.WEEKLY.toString() },
  { label: 'Monthly', value: RRule.MONTHLY.toString() },
  { label: 'Yearly', value: RRule.YEARLY.toString() },
]

export const nthOptions = [
  { label: 'Every', value: '0' },
  { label: '1st', value: '1' },
  { label: '2nd', value: '2' },
  { label: '3rd', value: '3' },
  { label: '4th', value: '4' },
  { label: '5th', value: '5' },
]

// Drupal currently only allows exclude. Leaving this in case that changes
// see tineDataFormFields.js for other commented out code.
export const includeOptions = [
  // { label: 'Include', value: 'include' },
  { label: 'Exclude', value: 'exclude' },
]
