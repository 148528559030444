import * as Yup from 'yup'
import { get } from 'lodash'
import formErrors from '../../../../configuration/formErrors'

export const renderInitialFormFields = currentUser => ({
  firstName: {
    name: 'firstName',
    type: 'text',
    placeholder: 'First Name *',
    validations: Yup.string().required(formErrors.requiredField),
    initialValue: get(currentUser, 'firstName', ''),
  },
  lastName: {
    name: 'lastName',
    type: 'text',
    placeholder: 'Last Name *',
    validations: Yup.string().required(formErrors.requiredField),
    initialValue: get(currentUser, 'lastName', ''),
  },
  phone: {
    name: 'phone',
    type: 'text',
    placeholder: 'Phone Number',
    initialValue: get(currentUser, 'phone', ''),
  },
  email: {
    name: 'email',
    type: 'text',
    placeholder: 'Email Address *',
    initialValue: get(currentUser, 'email', ''),
    validations: Yup.string()
      .email(formErrors.invalidEmail)
      .required(formErrors.requiredField),
  },
  currentPassword: {
    name: 'currentPassword',
    type: 'password',
    placeholder: 'Current Password *',
    validations: Yup.string()
      .when('password', {
        is: val => val && val.length,
        then: Yup.string().required(formErrors.requiredField),
      })
      .when('email', {
        is: val => val !== currentUser?.email,
        then: Yup.string().required(formErrors.emailChange),
      }),
    initialValue: '',
  },
  password: {
    name: 'password',
    type: 'password',
    placeholder: 'New Password *',
    validations: Yup.string(),
    initialValue: '',
  },
  confirmPassword: {
    name: 'confirmPassword',
    type: 'password',
    placeholder: 'Confirm New Password *',
    validations: Yup.string().when('password', {
      is: val => val && val.length,
      then: Yup.string()
        .oneOf([Yup.ref('password')], formErrors.passwordMismatch)
        .required(formErrors.requiredField),
    }),
    initialValue: '',
  },
})
