import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

Modal.setAppElement('#hhi_pmc')

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    maxWidth: '90%',
    maxHeight: '90%',
  },
  overlay: {
    zIndex: 2,
  },
}

export default function ContentModal({
  isOpen,
  modalTitle,
  children,
  closeModal,
  clickAway,
}) {
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel={modalTitle}
      shouldCloseOnOverlayClick
      onRequestClose={() => clickAway(false)}
    >
      {children}
      <button
        className="btn btn-primary flex-md-up flex-justify-center flex-align-center m-0 full-width py-3 px-8"
        onClick={closeModal}
        onKeyDown={closeModal}
        tabIndex={0}
        type="button"
      >
        Done
      </button>
    </Modal>
  )
}

ContentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  closeModal: PropTypes.func.isRequired,
  clickAway: PropTypes.func.isRequired,
}
