import {
  convertEditorStateToPlainText,
  convertEditorStateToRaw,
} from '../../../components/elements/WYSIWYG/utilities'

export const formatFormDataForSubmission = (formData = {}) => {
  const {
    jobName,
    website,
    bookNowURL,
    categories,
    jobLocation,
    selectedPartnerId,
    rawDescriptionData,
    rawShortDescriptionData,
    imageUpload,
  } = formData

  const requestBody = {
    type: 'node--job',
    attributes: {
      title: jobName,
    },
    relationships: {
      field_partner: {
        data: {
          type: 'node--partner',
          id: selectedPartnerId,
        },
      },
    },
  }

  if (rawDescriptionData) {
    requestBody.attributes.body = {
      value: convertEditorStateToRaw(rawDescriptionData),
      format: 'basic_html',
    }
  }

  if (rawShortDescriptionData && rawDescriptionData) {
    requestBody.attributes.field_short_description =
      convertEditorStateToPlainText(rawShortDescriptionData)
  }

  if (website?.length) {
    requestBody.attributes.field_website_url = {
      uri: website.trim(),
      title: '',
      options: [],
    }
  }

  if (bookNowURL?.length) {
    requestBody.attributes.field_apply_url = {
      // Required
      uri: bookNowURL.trim(),
      title: '',
      options: [],
    }
  }

  if (categories && Object.keys(categories)?.length) {
    requestBody.relationships.field_job_category = {
      data: [],
    }

    Object.entries(categories).forEach(([id, checked]) => {
      if (checked) {
        requestBody.relationships.field_job_category.data.push({
          type: 'taxonomy_term--job_category',
          id,
        })
      }
    })
  }

  if (jobLocation) {
    requestBody.relationships.field_job_location = {
      data: {
        type: 'taxonomy_term--job_location',
        id: jobLocation,
      },
    }
  }

  // only allowed one here, uploader supports multiple for other forms
  if (imageUpload?.[0] && imageUpload?.[0]?.drupalId) {
    requestBody.relationships.field_job_image = {
      data: {
        type: 'file--file',
        id: imageUpload?.[0]?.drupalId,
      },
    }
  }

  return requestBody
}
