import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import StatusPill from '../../StatusPill/StatusPill'

const SubmissionsItems = ({ title, status, time, isLocked }) => (
  <div className="flex flex-space-between mt-4">
    <div className="flex flex-column">
      <p className="font-body f-14 bold remove-margin">{title}</p>
      <p className="font-body f-12 normal color-field-light-grey remove-margin">
        Submitted: {moment(time).format('MMM D, YYYY')}
      </p>
    </div>
    <StatusPill
      status={status}
      isLocked={isLocked}
      pendingBbClass="bg-field-light-grey"
    />
  </div>
)

SubmissionsItems.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  time: PropTypes.string.isRequired,
  isLocked: PropTypes.bool.isRequired,
}

export default SubmissionsItems
