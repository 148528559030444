import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { selectActivePartnerOffers } from '../../redux/offers/offers'

import SubmissionCard from '../../components/elements/SubmissionCard/SubmissionCard'
import routes from '../../configuration/routes'
import { renderDisplayName } from './utilities'

export default function OfferList({ hasPermissions }) {
  const offers = useSelector(selectActivePartnerOffers)

  return (
    <div className="container-centered-lg">
      <div className="pb-8 pb-0-lg-max flex full-width flex-space-between flex-align-center px-6-lg-max flex-wrap">
        <h1 className="normal remove-margin">Your Offer Submissions</h1>
        {hasPermissions && (
          <Link
            to={routes.createOffer}
            className="border-none py-6 px-8 my-8-lg-max border-radius-3 color-white font-body f-15 bold bg-primary-color uppercase no-text-decoration"
            style={{ minWidth: '165px' }}
          >
            + Create New
          </Link>
        )}
      </div>
      {(!offers || offers.length === 0) && <p>No offers were found.</p>}
      {offers?.length > 0 &&
        offers.map(offer => (
          <div key={offer.id} className="flex flex-column">
            <SubmissionCard
              id={offer.id}
              status={offer.status}
              title={offer.title}
              type="offer"
              date={offer.created}
              author={renderDisplayName(offer)}
              hasPermissions={offer.canEdit}
              isLocked={offer?.hasLiveRevision}
            />
          </div>
        ))}
    </div>
  )
}

OfferList.propTypes = {
  hasPermissions: PropTypes.bool.isRequired,
}
