import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import SimpleHeader from '../../components/structure/SimpleHeader'
import routes from '../../configuration/routes'
import {
  registerUser,
  selectRegistrationState,
} from '../../redux/registration/registration'
import {
  loginUser,
  selectAuthenticationState,
} from '../../redux/authentication/authentication'

import BrandLoader from '../../components/elements/BrandLoader'

import RegisterForm from './components/RegisterForm'
import './styles.scss'

const RegisterContainer = ({ handleRouteChange }) => {
  const dispatch = useDispatch()
  const { loading } = useSelector(selectRegistrationState)
  const { loading: loginLoading } = useSelector(selectAuthenticationState)
  const [formValues, setFormValues] = useState(null)
  const [registerError, setRegisterError] = useState(null)

  function handleSubmit(values) {
    // Save values except pw to local state in case of error as form will unmount.
    setFormValues({
      ...values,
      password: '',
      confirmPassword: '',
    })
    dispatch(
      registerUser({
        username: values.username,
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
      }),
    )
      .then(() => {
        dispatch(
          loginUser({ username: values.username, password: values.password }),
        ).then(() => {
          handleRouteChange(routes.registrationStepTwo)
        })
      })
      .catch(err => {
        setRegisterError(err.message)
      })
  }

  return (
    <>
      <SimpleHeader />
      <div
        data-testid="register-container"
        className="full-width flex-grow register-container px-5-lg-max"
      >
        <div className="px-2 py-16 py-12-lg-max text-center">
          <h1 className="normal remove-margin">Create a Personal Profile</h1>
        </div>

        {loading || loginLoading ? (
          <div className="bg-white container-centered-lg border border-medium-grey border-radius-4">
            <div className="container-centered-sm">
              <BrandLoader />
            </div>
          </div>
        ) : (
          <div className="bg-white container-centered-lg border border-medium-grey border-radius-4">
            <div className="py-10">
              <h2 className="text-center normal m-0 f-24">
                Join the Partner Portal
              </h2>
            </div>
            <div className="container-centered-lg pb-12 pb-10-lg-max px-16 px-8-lg-max">
              {registerError && (
                <p className="register-error color-error f-18 bold">
                  {registerError.indexOf('email address') > -1 ? (
                    <>
                      A user account with this contact information may already
                      exist. Please try{' '}
                      <Link
                        to={routes.login}
                        className="color-primary-color bold"
                      >
                        logging in
                      </Link>{' '}
                      or{' '}
                      <Link
                        to={routes.resetPassword}
                        className="color-primary-color bold"
                      >
                        resetting your password
                      </Link>
                      .
                    </>
                  ) : (
                    { registerError }
                  )}
                </p>
              )}
              <RegisterForm
                submit={handleSubmit}
                savedFormValues={formValues}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

RegisterContainer.propTypes = {
  handleRouteChange: PropTypes.func.isRequired,
}

export default RegisterContainer
