import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import routes from '../../configuration/routes'
import addIcon from '../../assets/icons/add.svg'

import SearchableSelect from '../../components/elements/SearchableSelect/SearchableSelect'
import {
  fetchPartners,
  selectNonUserPartners,
} from '../../redux/partners/userPartners'

import './styles.scss'
import { selectUserUid, selectUserUuid } from '../../redux/user/fetchUser'
import { selectUserToken } from '../../redux/authentication/authentication'
import { addUserToAccount } from '../../api/api'
import SimpleSpinner from '../../components/elements/SimpleSpinner'
import {
  fetchAvailablePartners,
  selectAvailablePartnerState,
} from '../../redux/partners/availablePartners'

const JoinOrganizationContainer = ({ handleRouteChange, title }) => {
  const [selectedOrg, setSelectedOrg] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const partners = useSelector(selectNonUserPartners)
  const userUuid = useSelector(selectUserUuid)
  const userUid = useSelector(selectUserUid)
  const userToken = useSelector(selectUserToken)
  const availablePartnerState = useSelector(selectAvailablePartnerState)
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      !availablePartnerState?.data &&
      !availablePartnerState?.loading &&
      !availablePartnerState?.error
    ) {
      dispatch(fetchAvailablePartners())
    }
  }, [availablePartnerState])

  function submit() {
    setLoading(true)
    setError(null)

    const matchingPartner = partners.find(partner => partner.id === selectedOrg)
    const matchingAccountId = matchingPartner?.field_account?.uuid

    const currentPendingMembers =
      matchingPartner?.field_account?.field_pending_user || []

    currentPendingMembers.push({
      uuid: userUuid,
    })

    if (!matchingAccountId) {
      setLoading(false)
      return setError('Unable to add Organization')
    }

    return addUserToAccount({
      accountId: matchingAccountId,
      token: userToken,
      members: currentPendingMembers.map(member => ({
        id: member.uuid,
        type: 'user--user',
      })),
    })
      .then(() => {
        setLoading(false)
        dispatch(fetchPartners(userUid))
        handleRouteChange(routes.dashboard)
      })
      .catch(err => {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line
          console.log(err?.response)
        }

        setLoading(false)
        setError(
          err?.response?.data?.errors?.[0]?.detail ||
            'Unable to join organization',
        )
      })
  }

  if (!partners?.length && !availablePartnerState?.loading) {
    return (
      <div className="px-2 py-10 text-center">
        <p>
          {availablePartnerState?.error
            ? availablePartnerState?.error
            : 'Unable to find any new organizations'}
        </p>
        <div>
          <div className="pt-8">
            <Link
              className="btn btn-primary no-text-decoration"
              to={routes.registerOrganization}
            >
              Create New +
            </Link>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="full-height px-5 pb-10-lg-max">
      <div className="px-2 py-10 py-12-lg-max text-center">
        {title?.length > 0 && <h1 className="normal remove-margin">{title}</h1>}
      </div>

      <div className="bg-white container-centered-lgr px-7 border border-medium-grey border-radius-4">
        <div className="py-10">
          <h2 className="text-center normal m-0 f-24">Join Organization</h2>
        </div>

        <>
          <div className="container-centered-lg pb-10 px-2">
            {availablePartnerState?.loading ? (
              <div className="flex flex-align-center flex-justify-center">
                <SimpleSpinner />
              </div>
            ) : (
              <>
                <div className="flex-lg-up pb-4">
                  <div className="flex-half-lg-up pl-2-lg-max pr-2-lg-up">
                    <p className="normal font-body m-0 f-18 pb-6-lg-max text-center-md-max">
                      Search For Your Organization
                    </p>
                  </div>

                  <Link
                    to={routes.registerOrganization}
                    className="create-org no-text-decoration color-primary-color"
                  >
                    <div className="flex flex-row flex-align-center m-0 pt-1-lg-max create-btn bold flex-justify-end-lg-up f-16 pb-2-lg-max">
                      <img className="px-2" src={addIcon} alt="Add Icon" />
                      Create New Organization
                    </div>
                  </Link>
                </div>
                <SearchableSelect
                  options={partners?.map(partner => ({
                    label: partner?.title,
                    value: partner.id,
                  }))}
                  handleSelection={val => setSelectedOrg(val)}
                />
                {error && (
                  <div className="pt-8">
                    <p className="m-0 color-error bold">{error}</p>
                  </div>
                )}
                <div className="relative flex flex-align-center pt-8 search-btn">
                  {loading ? (
                    <SimpleSpinner />
                  ) : (
                    <button
                      className="btn btn-primary f-15 bold px-8 py-6"
                      onClick={submit}
                      type="button"
                      disabled={!selectedOrg || loading}
                    >
                      Join Organization
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      </div>
    </div>
  )
}

JoinOrganizationContainer.defaultProps = {
  title: null,
}

JoinOrganizationContainer.propTypes = {
  handleRouteChange: PropTypes.func.isRequired,
  title: PropTypes.string,
}

export default JoinOrganizationContainer
