import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { selectActivePartnerEvents } from '../../redux/events/events'

import SubmissionCard from '../../components/elements/SubmissionCard/SubmissionCard'
import routes from '../../configuration/routes'
import { renderDisplayName, renderPublishedOn } from './utilities'
import { selectActivePartner } from '../../redux/activePartner/activePartner'
import { NON_MEMBER_ACCOUNT_ID } from '../../configuration/constants'

export default function EventList({ hasPermissions }) {
  const events = useSelector(selectActivePartnerEvents)
  const activePartnerId = useSelector(selectActivePartner)

  return (
    <div className="container-centered-lg">
      <div className="pb-8 pb-0-lg-max flex full-width flex-space-between flex-align-center px-6-lg-max flex-wrap">
        <h1 className="normal remove-margin"> Your Event Submissions</h1>
        {(hasPermissions || activePartnerId?.id === NON_MEMBER_ACCOUNT_ID) && (
          <Link
            to={routes.createEvent}
            className="border-none py-6 px-8 my-8-lg-max border-radius-3 color-white font-body f-15 bold bg-primary-color uppercase no-text-decoration"
            style={{ minWidth: '165px' }}
          >
            + Create New
          </Link>
        )}
      </div>
      {(!events || events.length === 0) && (
        <p className="pb-8 pb-0-lg-max pt-4-lg-max flex full-width flex-space-between flex-align-center px-6-lg-max flex-wrap">
          No events were found.
        </p>
      )}
      {events?.length > 0 &&
        events.map(event => (
          <div key={event.id} className="flex flex-column">
            <SubmissionCard
              id={event.id}
              status={event.status}
              title={event.title}
              type="event"
              property={renderPublishedOn(event?.field_calendar)}
              date={event.created}
              author={renderDisplayName(event)}
              hasPermissions={event.canEdit}
              isLocked={event?.hasLiveRevision}
            />
          </div>
        ))}
    </div>
  )
}

EventList.propTypes = {
  hasPermissions: PropTypes.bool.isRequired,
}
