/* eslint-disable import/no-cycle */
import moment from 'moment'
import { getEvents } from '../../api/api'
import { selectPartnerState } from '../partners/userPartners'
import { selectActivePartner } from '../activePartner/activePartner'
import { NON_MEMBER_ACCOUNT_ID } from '../../configuration/constants'
import { selectUserUuid } from '../user/fetchUser'

// CONSTANTS
export const FETCH_EVENTS_BEGIN = 'FETCH_EVENTS_BEGIN'
export const FETCH_EVENTS_ERROR = 'FETCH_EVENTS_ERROR'
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS'
export const CLEAR_EVENTS = 'CLEAR_EVENTS'

// ACTIONS
export const fetchEvents = uid => dispatch => {
  dispatch({ type: FETCH_EVENTS_BEGIN })
  return getEvents(uid)
    .then(res => {
      if (res?.data?.data) {
        const payload = res?.data?.data
          .sort((a, b) =>
            moment(a.changed).isBefore(moment(b.changed)) ? 1 : -1,
          )
          .reduce((obj, event) => {
            const id = event?.uuid
            return {
              ...obj,
              [id]: formatEvent(event),
            }
          }, {})
        dispatch({ type: FETCH_EVENTS_SUCCESS, payload })
      } else {
        dispatch({
          type: FETCH_EVENTS_ERROR,
          payload: 'Unable to retrieve events',
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_EVENTS_ERROR,
        payload: err?.response?.message || 'Unable to retrieve events',
      })
    })
}

// REDUCER
export default function eventReducer(
  state = { loading: false, error: null, data: null },
  action,
) {
  switch (action.type) {
    case FETCH_EVENTS_BEGIN:
      return {
        ...state,
        loading: true,
      }
    case FETCH_EVENTS_ERROR:
      return {
        loading: false,
        error: action.payload || 'Something went wrong',
        data: null,
      }

    case FETCH_EVENTS_SUCCESS:
      return {
        loading: false,
        error: null,
        data: action.payload,
      }
    case CLEAR_EVENTS:
      return {
        ...state,
        data: null,
      }
    default:
      return state
  }
}

// SELECTORS
export const selectEventsState = state => state.eventsData

export const selectEvents = state => {
  if (!state?.eventsData?.data || !Object.keys(state?.eventsData?.data).length)
    return null

  const partners = selectPartnerState(state)
  const userUuid = selectUserUuid(state)

  return Object.values(state.eventsData.data).map(event => {
    const accountData = event?.field_partner?.field_account || null

    const adminUsers = accountData?.field_admin_user?.length
      ? accountData?.field_admin_user
          ?.filter(user => !!user)
          .map(user => ({ ...user, role: 'admin' }))
      : []

    const isAdmin = adminUsers.some(user => user?.uuid === userUuid)

    return {
      ...event,
      isOwner: userUuid && event?.createdBy?.uuid === userUuid,
      userRole: partners?.data?.[event?.partnerId]?.userRole,
      canEdit: userUuid && (event?.createdBy?.uuid === userUuid || isAdmin),
    }
  })
}

export const selectEventById = (state, id) =>
  state?.eventsData?.data?.[id] || null

export const selectActivePartnerEvents = state => {
  const allEvents = selectEvents(state)
  const activePartnerId = selectActivePartner(state)?.id

  return allEvents && activePartnerId
    ? allEvents.filter(
        offer =>
          (activePartnerId === NON_MEMBER_ACCOUNT_ID && !offer.partnerId) ||
          offer.partnerId === activePartnerId,
      )
    : []
}

// UTILITIES
const formatEvent = event => {
  const formattedEventImages = event?.field_event_image?.length
    ? event?.field_event_image.map(image => ({
        ...image,
        id: image.uuid,
        src: image.url,
      }))
    : null

  const isPendingRevision = !!event?.revision_pending
  const isLive = event?.status

  return {
    ...event,
    id: event.uuid,
    formattedEventImages,
    partnerId: event?.field_partner?.uuid,
    createdBy: event?.uid || {},
    isLive,
    hasLiveRevision: isPendingRevision && !isLive,
  }
}
