import { object } from 'yup'

function generateFields(formLayout, field) {
  const values = {}

  if (!formLayout?.length || !field) {
    return values
  }

  formLayout.forEach(row => {
    if (row?.fields?.length) {
      row.fields.forEach(input => {
        if (input[field]) {
          values[input.name] = input[field]
        }
        if (field === 'initialValue' && !input[field] && input.validations) {
          values[input.name] = ''
        }
      })
    }
  })

  return values
}
export const generateFormInitialValues = formLayout =>
  generateFields(formLayout, 'initialValue')

export const generateFormValidationSchema = formLayout =>
  object().shape(generateFields(formLayout, 'validations'))

export const formInitialValues = formFields =>
  Object.values(formFields).reduce(
    (obj, field) => ({
      ...obj,
      [field.name]: field.initialValue !== undefined ? field.initialValue : '',
    }),
    {},
  )

export const formValidation = (formFields = {}) =>
  object().shape(
    Object.values(formFields).reduce((obj, field) => {
      const fieldSchema = field.validations
        ? {
            [field.name]: field.validations,
          }
        : {}

      return {
        ...obj,
        ...fieldSchema,
      }
    }, {}),
  )
