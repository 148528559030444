import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'

import {
  formInitialValues,
  formValidation,
} from '../../../utilities/content/generateFormData'
import FormikInput from '../../../components/elements/FormikInput'

import WysiwygEditor from '../../../components/elements/WYSIWYG/WysiwygEditor'

function getPublishOnSite(value, options) {
  if (!options || !options?.length > 0 || !value) {
    return ''
  }

  const match = options.find(option => option.value === value)

  return match?.label || ''
}

const EventDetailsForm = ({
  submit,
  initialValues,
  categories,
  eventDetailsFormFields,
  disableSubmit,
  disabledMessage,
}) => (
  <Formik
    initialValues={initialValues || formInitialValues(eventDetailsFormFields)}
    onSubmit={submit}
    validationSchema={formValidation(eventDetailsFormFields)}
  >
    {({
      handleSubmit,
      isSubmitting,
      errors,
      setFieldValue,
      values,
      touched,
    }) => (
      <form onSubmit={handleSubmit}>
        <div className="py-3">
          <FormikInput {...eventDetailsFormFields.eventName} />
        </div>
        <div className="flex-md-up">
          <div className="flex-half">
            <FormikInput
              {...eventDetailsFormFields.webURL}
              onFocus={() => {
                if (!values?.webURL) {
                  setFieldValue(eventDetailsFormFields.webURL.name, 'https://')
                }
              }}
              onBlur={() => {
                if (
                  values?.webURL === 'https://' ||
                  values?.webURL === 'http://'
                ) {
                  setFieldValue(eventDetailsFormFields.webURL.name, '')
                }
              }}
            />
            <p className="mt-2 mb-0 font-body f-12">
              These must be external URLs such as <i>http://example.com</i>
            </p>
          </div>
        </div>
        <div className="pt-4">
          <div className="half-width-md-up">
            <FormikInput {...eventDetailsFormFields.admissionDetails} />
            <p className="mt-2 mb-0 font-body f-12">
              i.e. $10 per person, children free (max. 200 characters)
            </p>
          </div>
        </div>
        <div className="pt-6">
          <p className="bold f-18 font-body">Event Description *</p>

          <WysiwygEditor
            onChange={value => setFieldValue('rawDescriptionData', value)}
            initialState={values?.rawDescriptionData || null}
          />
          {touched?.rawDescriptionData && errors?.rawDescriptionData && (
            <p className="color-error remove-margin">
              {errors.rawDescriptionData}
            </p>
          )}
        </div>
        <div className="pt-6">
          <FormikInput {...eventDetailsFormFields.imageUpload} />
        </div>
        <div className="pt-6">
          <p className="mb-2 font-body f-18 bold">
            Publish On <span className="color-primary-color">*</span>
          </p>
          <p className="font-body mb-0">
            Choose which site this event will appear on
          </p>

          {eventDetailsFormFields?.appearSite?.options?.length > 0 && (
            <div className="flex-md-up flex-wrap-md-up pt-6">
              <div className="flex-half edit-event__radios">
                <FormikInput {...eventDetailsFormFields.appearSite} />
              </div>
            </div>
          )}
        </div>
        <div className="pt-6">
          <p className="mb-2 font-body f-18 bold">
            Event Categories <span className="color-primary-color">*</span>
          </p>
          <p className="mb-0 font-body">
            Select up to 3 categories for your{' '}
            {getPublishOnSite(
              values.appearSite,
              eventDetailsFormFields?.appearSite?.options,
            )}{' '}
            event
          </p>
          <div className="flex-md-up flex-wrap-md-up pt-6">
            {categories?.length > 0 &&
              categories.map(category => (
                <div className="flex-half" key={category.value}>
                  <FormikInput
                    {...eventDetailsFormFields.categories}
                    name={`categories.${category.value}`}
                    placeholder={category.label}
                    renderLabel={() => <p className="mb-0">{category.label}</p>}
                  />
                </div>
              ))}

            {touched?.categories && errors?.categories && (
              <p className="color-error">{errors?.categories}</p>
            )}
          </div>
        </div>
        <div className="pt-14">
          {disableSubmit && (
            <p className="color-error">
              {disabledMessage || 'Please ensure form is complete'}
            </p>
          )}
          <button
            className="btn btn-primary"
            type="submit"
            disabled={isSubmitting || disableSubmit}
          >
            Continue to Location Details
          </button>
        </div>
      </form>
    )}
  </Formik>
)

EventDetailsForm.defaultProps = {
  initialValues: null,
  categories: null,
  disableSubmit: false,
  disabledMessage: '',
}

EventDetailsForm.propTypes = {
  submit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    status: PropTypes.bool,
  }),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  eventDetailsFormFields: PropTypes.shape({
    eventName: PropTypes.shape({}),
    imageUpload: PropTypes.shape({}),
    phone: PropTypes.shape({}),
    admissionDetails: PropTypes.shape({}),
    webURL: PropTypes.shape({
      name: PropTypes.string,
    }),
    appearSite: PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    categories: PropTypes.shape({}),
    status: PropTypes.shape({}),
  }).isRequired,
  disableSubmit: PropTypes.bool,
  disabledMessage: PropTypes.string,
}

export default EventDetailsForm
