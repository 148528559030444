import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const TextArea = ({ type, name, rows, onChange, value, error }) => (
  <div>
    <textarea
      className="custom-text-area border border-radius-3 border-border-color"
      type={type}
      name={name}
      rows={rows}
      onChange={onChange}
      value={value}
    />
    {error && <p className="color-error remove-margin">{error}</p>}
  </div>
)

TextArea.defaultProps = {
  rows: null,
  type: 'textArea',
  error: null,
}

TextArea.propTypes = {
  rows: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
}

export default TextArea
