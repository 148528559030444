import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ClickAwayHandler from '../ClickAwayHandler'

// ASSETS
import chevDown from '../../../assets/icons/chev-down.svg'

// STYLES
import './styles.scss'

const Dropdown = ({
  activeBtnClassNames,
  btnClassNames,
  btnText,
  children,
  icon,
  spaceBetween,
  menuUp,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div
      className={`custom-dropdown relative ${isOpen ? 'active' : null} ${
        menuUp ? 'menu-up' : null
      }`}
    >
      <ClickAwayHandler handleClickAway={() => setIsOpen(false)}>
        <button
          className={`btn btn-dropdown ${
            isOpen && activeBtnClassNames ? activeBtnClassNames : btnClassNames
          }`}
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div
            className={`flex btn-dropdown-inner ${
              spaceBetween ? 'flex-space-between' : 'flex-align-center'
            }`}
          >
            {icon && (
              <img
                src={icon}
                alt={btnText}
                className={`${
                  !menuUp ? 'mr-2' : 'mb-1-lgr-up mb-2-lgr-max'
                } custom-icon`}
              />
            )}
            <span className={`${menuUp ? 'custom-dropdown__labels' : ''}`}>
              {btnText}
            </span>
            <img
              src={chevDown}
              alt={isOpen ? 'close' : 'open'}
              className="ml-2 chev mr-1-lg-max"
            />
          </div>
        </button>
      </ClickAwayHandler>
      <div className="absolute menu">{children}</div>
    </div>
  )
}

Dropdown.defaultProps = {
  activeBtnClassNames: null,
  btnClassNames: '',
  icon: null,
  spaceBetween: false,
  menuUp: false,
}

Dropdown.propTypes = {
  activeBtnClassNames: PropTypes.string,
  btnClassNames: PropTypes.string,
  btnText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  spaceBetween: PropTypes.bool,
  menuUp: PropTypes.bool,
}

export default Dropdown
