import axios from 'axios'

const endpoints = {
  csrf: `${process.env.REACT_APP_ENDPOINT_DRUPAL}/session/token`,
  drupalApi: `${process.env.REACT_APP_ENDPOINT_DRUPAL}/api`,
  logoutToken: `${process.env.REACT_APP_ENDPOINT_DRUPAL}/session/token/logout`,
  login: `${process.env.REACT_APP_ENDPOINT_DRUPAL}/user/login?_format=json`,
  emailLogin: `${process.env.REACT_APP_ENDPOINT_DRUPAL}/user/email-login?_format=json`,
  logout: `${process.env.REACT_APP_ENDPOINT_DRUPAL}/user/logout?_format=json`,
  offerCategories: `${process.env.REACT_APP_ENDPOINT_DRUPAL}/api/taxonomy/offer_categories?_format=json`,
  businessCategories: `${process.env.REACT_APP_ENDPOINT_DRUPAL}/api/taxonomy/business_categories?_format=json`,
  offers: `${process.env.REACT_APP_ENDPOINT_DRUPAL_NODES}/offer`,
  eventCategories: `${process.env.REACT_APP_ENDPOINT_DRUPAL}/api/taxonomy/event_categories?_format=json`,
  events: `${process.env.REACT_APP_ENDPOINT_DRUPAL_NODES}/event`,
  jobs: `${process.env.REACT_APP_ENDPOINT_DRUPAL_NODES}/job`,
  jobCategories: `${process.env.REACT_APP_ENDPOINT_DRUPAL}/api/taxonomy/job_category?_format=json`,
  jobLocations: `${process.env.REACT_APP_ENDPOINT_DRUPAL}/api/taxonomy/job_location?_format=json`,
  partners: `${process.env.REACT_APP_ENDPOINT_DRUPAL_NODES}/partner`,
  register: `${process.env.REACT_APP_ENDPOINT_DRUPAL}/user/register?_format=json&lang=en`,
  user: `${process.env.REACT_APP_ENDPOINT_DRUPAL}/user`,
  locationFilters: `${process.env.REACT_APP_ENDPOINT_DRUPAL}/api/taxonomy/location_filters?_format=json`,
  account: `${process.env.REACT_APP_UNGERBOECK_API}/api/ungerboeck/registrations?clientId=hhi`,
  organizationImport: `${process.env.REACT_APP_UNGERBOECK_API}/api/hhi-partner-portal/accounts/import`,
  accountDrupal: `${process.env.REACT_APP_ENDPOINT_DRUPAL_NODES}/account`,
  taxonomyTerm: `${process.env.REACT_APP_ENDPOINT_DRUPAL}/api/taxonomy/`,
  imageEntity: `${process.env.REACT_APP_ENDPOINT_DRUPAL_MEDIA}/image/field_media_image`,
  image: `${process.env.REACT_APP_ENDPOINT_DRUPAL_MEDIA}/image/`,
  restApi: `${process.env.REACT_APP_ENDPOINT_DRUPAL}/rest/`,
  pages: `${process.env.REACT_APP_ENDPOINT_DRUPAL}/rest/page/`,
}

export const getCSRFToken = () =>
  axios.get(endpoints.csrf, {
    withCredentials: true,
  })

export const getLogoutToken = () =>
  axios.get(endpoints.logoutToken, {
    withCredentials: true,
  })

const isEmail = string =>
  String(string)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )

export const login = ({ username, password }) => {
  const useEmail = isEmail(username)
  const data = JSON.stringify(
    useEmail
      ? {
          mail: username,
          pass: password,
        }
      : {
          name: username,
          pass: password,
        },
  )

  const headers = {
    'Content-type': 'application/json',
  }

  return axios.post(
    isEmail(username) ? endpoints.emailLogin : endpoints.login,
    data,
    { headers, withCredentials: true },
  )
}

export const getLoggedInStatus = () =>
  axios.get(`${endpoints.user}/info/uid?_format=json`, {
    withCredentials: true,
  })

export const getUser = ({ uid }) =>
  axios.get(`${endpoints.user}/${uid}?_format=json`, { withCredentials: true })

export const logout = ({ logoutToken, csrfToken }) => {
  const headers = {
    'Content-type': 'application/json',
    'X-CSRF-Token': csrfToken,
  }

  return axios.post(
    `${endpoints.logout}&token=${logoutToken}`,
    {},
    { headers, withCredentials: true },
  )
}

export const register = ({
  username,
  email,
  password,
  firstName,
  lastName,
  phone,
}) => {
  const data = {
    name: [{ value: username }],
    mail: [{ value: email }],
    pass: [{ value: password }],
    field_first_name: [{ value: firstName }],
    field_last_name: [{ value: lastName }],
    field_phone_number: [{ value: phone }],
  }

  const headers = {
    'Content-Type': 'application/json',
  }

  return axios.post(endpoints.register, data, {
    headers,
    withCredentials: true,
  })
}

export const updateUser = ({ reqBody, token, uid }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: '*',
      'X-CSRF-Token': token,
    },
    withCredentials: true,
  }

  return axios.patch(
    `${endpoints.user}/${uid}?_format=json`,
    JSON.stringify({ ...reqBody }),
    config,
  )
}

export const getOffers = uid => {
  const headers = {
    'Content-type': 'application/json',
  }
  return axios.get(`${endpoints.drupalApi}/user/${uid}/offer`, {
    headers,
    withCredentials: true,
  })
}

export const createOffer = ({ reqBody, token, isUpdate, currentOfferId }) => {
  const config = {
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      'X-CSRF-Token': token,
    },
    withCredentials: true,
  }

  if (isUpdate && currentOfferId) {
    return axios.patch(
      `${endpoints.offers}/${currentOfferId}`,
      JSON.stringify({ data: reqBody }),
      config,
    )
  }

  return axios.post(endpoints.offers, JSON.stringify({ data: reqBody }), config)
}

export const getJobs = uid => {
  const headers = {
    'Content-type': 'application/json',
  }
  return axios.get(`${endpoints.drupalApi}/user/${uid}/job`, {
    headers,
    withCredentials: true,
  })
}

export const createJob = ({ reqBody, token, isUpdate, currentJobId }) => {
  const config = {
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      'X-CSRF-Token': token,
    },
    withCredentials: true,
  }

  if (isUpdate && currentJobId) {
    return axios.patch(
      `${endpoints.jobs}/${currentJobId}`,
      JSON.stringify({ data: reqBody }),
      config,
    )
  }

  return axios.post(endpoints.jobs, JSON.stringify({ data: reqBody }), config)
}

export const unpublishJob = ({ token, currentJobId }) => {
  const config = {
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      'X-CSRF-Token': token,
    },
    withCredentials: true,
  }

  const reqBody = {
    id: currentJobId,
    type: 'node--job',
    attributes: {
      moderation_state: 'archived',
      status: false,
    },
  }

  if (currentJobId) {
    return axios.patch(
      `${endpoints.jobs}/${currentJobId}`,
      JSON.stringify({ data: reqBody }),
      config,
    )
  }

  return axios.post(endpoints.jobs, JSON.stringify({ data: reqBody }), config)
}

export const getEvents = uid => {
  const headers = {
    'Content-type': 'application/json',
  }

  return axios.get(`${endpoints.drupalApi}/user/${uid}/event`, {
    headers,
    withCredentials: true,
  })
}

export const createEvent = ({ reqBody, token }) => {
  const config = {
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      'X-CSRF-Token': token,
    },
    withCredentials: true,
  }

  return axios.post(endpoints.events, JSON.stringify({ data: reqBody }), config)
}

export const updateEvent = ({ reqBody, token, currentEventId }) => {
  const config = {
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      'X-CSRF-Token': token,
    },
    withCredentials: true,
  }

  return axios.patch(
    `${endpoints.events}/${currentEventId}`,
    JSON.stringify({ data: reqBody }),
    config,
  )
}

export const getPartners = uid => {
  const headers = {
    'Content-type': 'application/json',
  }
  const url = uid ? `/user/${uid}/partner` : '/partner'

  return axios.get(`${endpoints.drupalApi}${url}`, {
    headers,
    withCredentials: true,
  })
}

export const getEventCategories = () => axios.get(endpoints.eventCategories)

export const getLocationFilters = () => axios.get(endpoints.locationFilters)

export const getOfferCategories = () => axios.get(endpoints.offerCategories)

export const getJobCategories = () => axios.get(endpoints.jobCategories)

export const getJobLocations = () => axios.get(endpoints.jobLocations)

export const getBusinessCategories = () =>
  axios.get(endpoints.businessCategories)

export const createOrganization = (reqBody, token) => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-CSRF-Token': token,
  }

  return axios.post(endpoints.account, JSON.stringify(reqBody), {
    headers,
    // withCredentials: true,
  })
}

export const triggerOrganizationImport = (id, token) => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-CSRF-Token': token,
  }

  return axios.get(`${endpoints.organizationImport}/${id}?clientId=hhi`, {
    headers,
  })
}

export const createPartner = (reqBody, token) => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-CSRF-Token': token,
  }

  return axios.post(endpoints.partners, JSON.stringify({ data: reqBody }), {
    headers,
    withCredentials: true,
  })
}

export const updateAccountAccolades = ({ accountId, token, accolades }) => {
  const config = {
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      'X-CSRF-Token': token,
    },
    withCredentials: true,
  }

  return axios.patch(
    `${endpoints.accountDrupal}/${accountId}`,
    JSON.stringify({
      data: {
        type: 'node--account',
        id: accountId,
        field_accolades: accolades,
      },
    }),
    config,
  )
}

export const addUserToAccount = ({ accountId, token, members }) => {
  const config = {
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      'X-CSRF-Token': token,
    },
    withCredentials: true,
  }

  return axios.patch(
    `${endpoints.accountDrupal}/${accountId}`,
    JSON.stringify({
      data: {
        type: 'node--account',
        id: accountId,
        relationships: {
          field_pending_user: {
            data: members,
          },
        },
      },
    }),
    config,
  )
}

export function updateAccountMembers({ token, members = {}, accountId }) {
  const { adminIds = [], memberIds = [], pendingMemberIds = [] } = members

  const config = {
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      'X-CSRF-Token': token,
    },
    withCredentials: true,
  }

  const fieldPendingUserData = pendingMemberIds.map(id => ({
    id,
    type: 'user--user',
  }))
  const fieldMemberUserData = memberIds.map(id => ({
    id,
    type: 'user--user',
  }))
  const fieldAdminUserData = adminIds.map(id => ({
    id,
    type: 'user--user',
  }))
  const reqBody = {
    data: {
      type: 'node--account',
      id: accountId,
      relationships: {
        field_pending_user: {
          data: fieldPendingUserData,
        },
        field_member_user: {
          data: fieldMemberUserData,
        },
        field_admin_user: {
          data: fieldAdminUserData,
        },
      },
    },
  }

  return axios.patch(
    `${endpoints.accountDrupal}/${accountId}`,
    JSON.stringify(reqBody),
    config,
  )
}

export const updatePartner = ({ reqBody, token }) => {
  const config = {
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      'X-CSRF-Token': token,
    },
    withCredentials: true,
  }

  return axios.patch(
    `${endpoints.partners}/${reqBody.id}`,
    JSON.stringify({ data: reqBody }),
    config,
  )
}

export const postImageEntity = ({ data, token, fileName }) => {
  const config = {
    headers: {
      'Content-Disposition': `file; filename="${fileName}"`,
      'Content-Type': 'application/octet-stream',
      'X-CSRF-Token': token,
    },
    withCredentials: true,
  }
  return axios.post(endpoints.imageEntity, data, config)
}

export const postImage = ({ uuid, imageData, token }) => {
  const config = {
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      'X-CSRF-Token': token,
    },
    withCredentials: true,
  }

  const data = JSON.stringify({
    data: {
      type: 'media--image',
      attributes: {
        name: imageData?.attributes?.filename,
      },
      relationships: {
        uid: {
          data: {
            type: 'user--user',
            id: uuid,
          },
        },
        field_media_image: {
          data: [
            {
              type: 'file--file',
              id: imageData?.id,
              name: imageData?.attributes?.filename,
            },
          ],
        },
      },
    },
  })

  return axios.post(endpoints.image, data, config)
}

export const createImage = ({
  uuid,
  imageData,
  token,
  fileName,
  fetchAsFile,
}) =>
  postImageEntity({ data: imageData, token, fileName }).then(res => {
    if (fetchAsFile) {
      return res
    }

    return postImage({ uuid, imageData: res?.data?.data, token })
  })

export const getAccountFormTaxonomies = () =>
  axios.all([
    axios.get(`${endpoints.taxonomyTerm}assistance_interest?_format=json`),
    axios.get(`${endpoints.taxonomyTerm}company_size?_format=json`),
    axios.get(`${endpoints.taxonomyTerm}contact_method?_format=json`),
    axios.get(`${endpoints.taxonomyTerm}industry?_format=json`),
    axios.get(`${endpoints.taxonomyTerm}payment_options?_format=json`),
  ])

export const lostPassword = email =>
  axios.post(
    `${endpoints.user}/lost-password?_format=json`,
    JSON.stringify({ mail: email }),
    {
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
      },
    },
  )

export const resetPassword = reqBody =>
  axios.post(
    `${endpoints.user}/lost-password-reset?_format=json`,
    JSON.stringify(reqBody),
    {
      headers: {
        'Content-type': 'application/json',
      },
    },
  )

export const getPage = slug =>
  axios.get(`${endpoints.pages}${slug}`, {
    withCredentials: true,
  })
