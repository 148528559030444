export const formatFormDataForSubmission = (formData = {}) => {
  const { firstName, lastName, email, phone, currentPassword, password } =
    formData

  const reqBody = {
    mail: [{ value: email }],
    field_first_name: [{ value: firstName }],
    field_last_name: [{ value: lastName }],
    field_phone_number: [{ value: phone?.length ? phone : '' }],
  }

  if (password && currentPassword) {
    reqBody.pass = [
      {
        existing: currentPassword,
        value: password,
      },
    ]
  }

  return reqBody
}
