import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Formik, useFormikContext } from 'formik'
import { formatRRule } from '../utilities/formatRRule'
import { weekdayOptions, monthOptions } from '../utilities/getStaticOptions'

import arrowLeft from '../../../assets/icons/arrow-left.svg'
import '../styles.scss'

import {
  formInitialValues,
  formValidation,
} from '../../../utilities/content/generateFormData'
import FormikInput from '../../../components/elements/FormikInput'
import { supportedTime } from '../../../utilities/content/supportedTime'
import FormikDateInput from '../../../components/elements/FormikDateInput'

const TimeDateForm = ({
  submit,
  back,
  initialValues,
  timeDateFormFields,
  disableSubmit,
  disabledMessage,
}) => {
  const initialFields = formInitialValues(timeDateFormFields)

  const AutoUpdateRRule = () => {
    const { values } = useFormikContext()

    const { startDate, endDate, rruleCount } = values || {}

    let adjustedEndDate = endDate
    if (moment(startDate).format('L') === moment(endDate).format('L')) {
      adjustedEndDate = null
    }
    const ruleSet = formatRRule({
      ...values,
      endDate: adjustedEndDate,
      rruleCount: Number(rruleCount),
    })

    if (ruleSet) {
      const rules = ruleSet.rrules()
      if (rules.length > 0) {
        let dateItems = []
        if (Number(rruleCount) > 0 || adjustedEndDate) {
          dateItems = rules[0].all()
        }

        return (
          <div className="rrule-examples">
            {!adjustedEndDate && (
              <p>Starting On: {moment(startDate).format('MMM D, YYYY')}</p>
            )}
            <p className="rrule-text">Repeat Rule: {rules[0].toText()}</p>

            {dateItems.length > 0 && (
              <div className="examples">
                <h4>Dates Included:</h4>
                <ul className="dates-list">
                  {dateItems.map(dateItem => (
                    <li key={dateItem} className="dates-list-item">
                      {moment(dateItem).format('MMM D, YYYY')}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )
      }
    }
    return <p>No rules set</p>
  }

  const addDateItem = (values, setValue) => {
    if (values.rruleIncludeOptions === 'include') {
      if (values.rruleIncluded === '') {
        setValue('rruleIncluded', values.rruleIncludeDate)
      } else {
        setValue(
          'rruleIncluded',
          `${values.rruleIncluded},${values.rruleIncludeDate}`,
        )
      }
    } else if (values.rruleExcluded === '') {
      setValue('rruleExcluded', values.rruleIncludeDate)
    } else {
      setValue(
        'rruleExcluded',
        `${values.rruleExcluded},${values.rruleIncludeDate}`,
      )
    }
  }

  const removeDateItem = (str, values, setValue, type) => {
    if (type === 'include') {
      const dateStrArr = values.rruleIncluded.split(',')
      dateStrArr.splice(dateStrArr.indexOf(str), 1)
      setValue('rruleIncluded', dateStrArr.join(','))
    } else {
      const dateStrArr = values.rruleExcluded.split(',')
      dateStrArr.splice(dateStrArr.indexOf(str), 1)
      setValue('rruleExcluded', dateStrArr.join(','))
    }
  }

  const displayDates = (datesString, values, setValue, type) => {
    const dateStringArr = datesString.split(',')
    const listItems = dateStringArr.map(str => (
      // eslint-disable-next-line
      <li key={str}>
        <button
          type="button"
          className="btn"
          onClick={() => removeDateItem(str, values, setValue, type)}
        >
          X
        </button>
        {moment(str).format('MMM DD, YYYY')}
      </li>
    ))
    // eslint-disable-next-line
    return <>{listItems}</>
  }

  const freqInterval = freq => {
    switch (freq) {
      case '0':
        return 'Years'
      case '1':
        return 'Months'
      case '2':
        return 'Weeks'
      case '3':
        return 'Days'
      case '4':
        return 'Hours'
      default:
        return ''
    }
  }

  return (
    <Formik
      initialValues={initialValues || initialFields}
      onSubmit={submit}
      validationSchema={formValidation(timeDateFormFields)}
    >
      {({
        handleSubmit,
        isSubmitting,
        touched,
        errors,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="pb-4">
            <p className="mb-0 font-body f-18 bold">
              Start Date <span className="color-primary-color">*</span>
            </p>
          </div>
          <div className="flex-md-up">
            <div className="flex-half-md-up pr-4-md-up edit-event__start-date">
              <FormikDateInput {...timeDateFormFields.startDate} />
            </div>
            <div className="flex-half-md-up pl-4-md-up mt-4-md-max">
              <FormikInput
                {...timeDateFormFields.startTimeSelection}
                options={supportedTime()}
              />
            </div>
          </div>
          <div
            className={`pt-8 pt-4-md-max pb-4 ${
              values.isRepeating ? 'display-none' : ''
            }`}
          >
            <p className="mb-0 font-body f-18 bold">
              End Date <span className="color-primary-color">*</span>
            </p>
          </div>
          <div className="flex-md-up">
            <div className="flex-half-md-up pr-4-md-up">
              <FormikDateInput
                {...timeDateFormFields.endDate}
                minDate={values.startDate}
              />
            </div>
            <div className="flex-half-md-up pl-4-md-up mt-4-md-max">
              <FormikInput
                {...timeDateFormFields.endTimeSelection}
                options={supportedTime()}
              />
            </div>
          </div>
          <div className="pt-14 pt-6-md-max">
            <p className="mb-0 font-body f-18 bold">
              Is this a repeating event?
            </p>
          </div>
          <div className="pt-4 content-width">
            <FormikInput
              {...timeDateFormFields.isRepeating}
              disabled={values.callTimes}
              onChangeCallback={isChecked => {
                if (isChecked) {
                  setFieldValue(
                    timeDateFormFields.endDate.name,
                    values[timeDateFormFields.startDate.name],
                  )
                }
              }}
            />
          </div>
          <div>
            <p className="f-18 font-body">
              If this is an repeating event, please ensure the end date is the
              same date as your start date and indicate the repeating pattern
              below.
            </p>
          </div>
          {values.isRepeating && (
            <>
              <div className="pb-4">
                <p className="mb-0 font-body f-18 bold">
                  Repeat <span className="color-primary-color">*</span>
                </p>
              </div>
              <div className="flex-md-up pb-4-md-max">
                <div className="flex-half-md-up flex flex-column pr-4-md-up mb-4">
                  <FormikInput {...timeDateFormFields.rruleFrequency} />
                </div>
                <div className="flex-half-md-up flex flex-column pl-4-md-up">
                  <div className="flex flex-row flex-align-center">
                    <FormikInput {...timeDateFormFields.rruleInterval} />
                    <p className="mb-0 font-body f-18 bold ml-4">
                      {freqInterval(values.rruleFrequency)}
                    </p>
                  </div>
                </div>
              </div>
              {values.rruleFrequency < 2 && (
                <div className="flex-md-up half-width pb-4">
                  <div className="flex-half-md-up flex flex-column pr-4-md-up">
                    <div className="half-width-lg-up">
                      <FormikInput {...timeDateFormFields.rruleNth} />
                    </div>
                  </div>
                </div>
              )}
              {values.rruleFrequency < 4 && (
                <div className="flex-md-up pb-4">
                  <div className="flex flex-column flex-row-lg-up full-width">
                    {weekdayOptions?.length > 0 &&
                      weekdayOptions.map(option => (
                        <div
                          className="mr-8"
                          key={!option.value ? '' : option.value.toString()}
                        >
                          <FormikInput
                            {...timeDateFormFields.rruleByWeekDay}
                            name={`rruleByWeekDay.${option.value}`}
                            placeholder={option.label}
                            renderLabel={() => (
                              <p className="mb-0">{option.label}</p>
                            )}
                          />
                        </div>
                      ))}
                    {touched?.rruleByWeekDay && errors?.rruleByWeekDay && (
                      <p className="color-error">{errors?.rruleByWeekDay}</p>
                    )}
                  </div>
                </div>
              )}
              <div className="pb-4">
                <p className="mb-0 font-body f-18 bold">Only In</p>
              </div>
              <div className="flex-md-up pb-4">
                <div className="flex flex-column flex-row-lg-up full-width flex-wrap">
                  {monthOptions?.length > 0 &&
                    monthOptions.map(option => (
                      <div className="mr-8" key={option.value}>
                        <FormikInput
                          {...timeDateFormFields.rruleByMonth}
                          name={`rruleByMonth.${option.value}`}
                          placeholder={option.label}
                          renderLabel={() => (
                            <p className="mb-0">{option.label}</p>
                          )}
                        />
                      </div>
                    ))}
                </div>
              </div>
              <div className="pb-4">
                <p className="mb-0 font-body f-18 bold">End After</p>
              </div>
              <div className="flex-md-up half-width pb-4">
                <div className="half-width-lg-up pr-4-md-up">
                  <FormikInput {...timeDateFormFields.rruleCount} />
                </div>
              </div>
              <div className="pb-4 pt-2">
                <p className="mb-0 font-body f-18 bold">Exclude</p>
              </div>
              <div className="flex-md-up pb-8">
                <FormikInput {...timeDateFormFields.rruleIncludeOptions} />
                <div className="flex-half-md-up pr-4-md-up">
                  <FormikDateInput
                    {...timeDateFormFields.rruleIncludeDate}
                    minDate={values.startDate}
                  />
                </div>
                <button
                  type="button"
                  className="btn"
                  disabled={!values.rruleIncludeDate}
                  onClick={() => addDateItem(values, setFieldValue)}
                >
                  Add
                </button>
              </div>
              {(values.rruleIncluded || values.rruleExcluded) && (
                <div className="flex-md-up">
                  {values.rruleIncluded && (
                    <div className="flex-half-md-up pr-4-md-up">
                      <h3>Included Dates</h3>
                      <ul className="included-dates">
                        {displayDates(
                          values.rruleIncluded,
                          values,
                          setFieldValue,
                          'include',
                        )}
                      </ul>
                    </div>
                  )}
                  {values.rruleExcluded && (
                    <div className="flex-half-md-up pr-4-md-up">
                      <h3>Excluded Dates</h3>
                      <ul className="included-dates">
                        {displayDates(
                          values.rruleExcluded,
                          values,
                          setFieldValue,
                          'exclude',
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              )}
              <div className="flex">
                <AutoUpdateRRule />
              </div>
            </>
          )}
          <div className="pt-2">
            <p className="f-16 font-body">
              If your event start time is not absolute, or your event has
              multiple start and end times, check “Call for Times” to provide a
              message for interested parties to call prior to the event for
              specific times.
            </p>
          </div>
          <div className="pt-6 flex-md-up half-width flex-space-between-md-up">
            <div>
              <FormikInput
                {...timeDateFormFields.callTimes}
                clearFieldOnChange={timeDateFormFields.isRepeating.name}
              />
            </div>
            <div>
              <FormikInput {...timeDateFormFields.allDayEvent} />
            </div>
          </div>
          <div className="pt-6 ">
            {disableSubmit && (
              <p className="color-error">
                {disabledMessage || 'Please ensure form is complete'}
              </p>
            )}
            <div className="flex flex-space-between flex-column-md-max">
              <button
                className="edit-event__backBtn btn btn-inverse mr-7-md-up"
                onClick={back}
                type="button"
              >
                <img src={arrowLeft} alt="Return arrow" className="mr-3" />
                Return to Location Details
              </button>
              <button
                className="edit-event__btn btn btn-primary mt-4-md-max"
                type="submit"
                disabled={isSubmitting}
              >
                Continue to Contact Details
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}

TimeDateForm.defaultProps = {
  initialValues: {},
  schema: {},
  disableSubmit: false,
  disabledMessage: '',
}

TimeDateForm.propTypes = {
  submit: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  schema: PropTypes.shape({}),
  timeDateFormFields: PropTypes.shape({
    startDate: PropTypes.shape({
      name: PropTypes.string,
    }),
    startTimeSelection: PropTypes.shape({}),
    endDate: PropTypes.shape({
      name: PropTypes.string,
    }),
    endTimeSelection: PropTypes.shape({}),
    isRepeating: PropTypes.shape({
      name: PropTypes.string,
    }),
    callTimes: PropTypes.shape({}),
    allDayEvent: PropTypes.shape({}),
    rruleFrequency: PropTypes.shape({}),
    rruleInterval: PropTypes.shape({}),
    rruleNth: PropTypes.shape({}),
    rruleByWeekDay: PropTypes.shape({}),
    rruleByMonth: PropTypes.shape({}),
    rruleCount: PropTypes.shape({}),
    rruleIncludeOptions: PropTypes.shape({}),
    rruleIncludeDate: PropTypes.shape({}),
  }).isRequired,
  disableSubmit: PropTypes.bool,
  disabledMessage: PropTypes.string,
}

export default TimeDateForm
