import * as Yup from 'yup'
import { get, isNil } from 'lodash'
import formErrors from '../../configuration/formErrors'
import {
  convertEditorStateToPlainText,
  convertEditorStateToRaw,
  convertRawToEditorState,
} from '../../components/elements/WYSIWYG/utilities'

const renderFormFields = (currentPartner, isAdmin) => {
  const formData = {
    id: {
      name: 'id',
      initialValue: currentPartner?.id,
      disabled: true,
      type: 'hidden',
    },
    title: {
      name: 'title',
      placeholder: 'Title',
      initialValue: currentPartner?.title || '',
      type: 'text',
      validations: Yup.string().required(formErrors.requiredField),
      disabled: !isAdmin,
    },
    body: {
      name: 'body',
      placeholder: 'Description',
      initialValue: currentPartner?.body?.value
        ? convertRawToEditorState(currentPartner?.body?.value)
        : '',
      disabled: !isAdmin,
    },
    website: {
      name: 'website',
      placeholder: 'Website',
      initialValue: isNil(currentPartner?.field_website_url)
        ? ''
        : currentPartner?.field_website_url?.uri,
      disabled: !isAdmin,
      validations: Yup.string().url(formErrors.invalidURL),
    },
    imageUpload: {
      name: 'imageUpload',
      type: 'file',
      placeholder: 'Image',
      validations: Yup.array().nullable(),
      initialValue: isNil(currentPartner?.formattedPartnerImages)
        ? []
        : currentPartner?.formattedPartnerImages,
      count: 1,
      fetchAsFile: true,
    },
    logoUpload: {
      name: 'logoUpload',
      type: 'file',
      placeholder: 'Logo',
      validations: Yup.array().nullable(),
      initialValue: isNil(currentPartner?.formattedPartnerLogo)
        ? []
        : [currentPartner?.formattedPartnerLogo],
      count: 1,
      fetchAsFile: true,
    },
    accolades: {
      name: 'accolades',
      type: 'file',
      placeholder: 'Accolades',
      validations: Yup.array().nullable(),
      initialValue: isNil(currentPartner?.formattedPartnerAccolades)
        ? []
        : currentPartner?.formattedPartnerAccolades,
      count: 3,
      fetchAsFile: true,
    },
  }
  const initialSocialMedia = get(
    currentPartner,
    'field_social_media.platform_values',
    null,
  )

  if (initialSocialMedia) {
    Object.entries(initialSocialMedia).forEach(([name, data]) => {
      formData[name] = {
        name,
        initialValue: data.value,
        validations: Yup.string().test({
          message: formErrors.invalidNotURL,
          // eslint-disable-next-line
          test: function (value) {
            const expression =
              /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
            const regex = new RegExp(expression)
            return !value || !value.match(regex)
          },
        }),
      }
    })
  }

  return formData
}

export const formatFormForSubmission = formFields => {
  const requestBody = {
    type: 'node--partner',
    id: formFields.id,
    attributes: {
      title: formFields?.title,
      moderation_state: 'draft',
      body: {
        value: convertEditorStateToRaw(formFields?.body),
        format: 'basic_html',
        summary: convertEditorStateToPlainText(formFields?.body),
      },
      field_website_url: formFields?.website.trim(),
      field_social_media: {
        platform_values: {
          facebook: {
            value: formFields.facebook || '',
          },
          twitter: {
            value: formFields.twitter || '',
          },
          instagram: {
            value: formFields.instagram || '',
          },
          youtube_channel: {
            value: formFields.youtube_channel || '',
          },
        },
      },
    },
    relationships: {},
  }

  if (
    formFields?.imageUpload.length > 0 &&
    Object.keys(formFields?.imageUpload[0]).length > 0
  ) {
    const data = formFields.imageUpload.map(image => ({
      type: 'file--file',
      id: image.drupalId || image.id,
    }))

    requestBody.relationships.field_partner_images = { data }
  }

  if (
    formFields?.logoUpload.length > 0 &&
    Object.keys(formFields?.logoUpload[0]).length > 0
  ) {
    requestBody.relationships.field_partner_logo = {
      data: {
        type: 'file--file',
        id:
          formFields.logoUpload[0].drupalId ||
          formFields.logoUpload[0].image.id,
      },
    }
  }

  if (
    formFields?.accolades.length > 0 &&
    Object.keys(formFields?.accolades[0]).length > 0
  ) {
    const data = formFields.accolades.map(image => ({
      type: 'file--file',
      id: image.drupalId || image.id,
    }))
    requestBody.relationships.field_partner_accolades = { data }
  }

  return { partnerData: requestBody }
}

export default renderFormFields
