import React from 'react'
import HHTextLogo from '../../assets/images/hhi-text-logo.svg'

const SimpleHeader = () => (
  <div className="px-2 py-6 bg-black color-white text-center">
    <img src={HHTextLogo} alt="" />
  </div>
)

export default SimpleHeader
