import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import './styles.scss'
import { setActivePartner } from '../../../redux/activePartner/activePartner'
import routes from '../../../configuration/routes'

// ASSETS
import edit from '../../../assets/icons/edit.svg'

const OrganizationCard = ({ partner, isActive }) => {
  const title = partner?.title
  const photos = partner?.formattedPartnerImages || []
  const logo = partner?.formattedPartnerLogo?.src
    ? partner?.formattedPartnerLogo
    : null
  const photo = photos.find(currentPhoto => currentPhoto?.src)

  const displayPhoto = logo || photo
  const dispatch = useDispatch()
  const canEdit = partner.isOwner || partner?.userRole === 'admin'

  const hasPendingUsers = !!partner?.allUsers?.some(
    user => user.role === 'pending',
  )

  const hasActiveFieldAccountCode = !!partner?.field_account?.field_account_code

  function renderRole() {
    switch (partner?.userRole) {
      case 'admin':
        return 'Administrator'
      case 'member':
        return 'Member'
      case 'pending':
        return 'Pending Membership'
      default:
        return 'Unknown'
    }
  }

  return (
    <div
      className={`organization-card flex flex-space-between flex-column-lg-max container-card border border-card-border border-radius-4 ${
        isActive ? 'org-card-box-shadow' : ''
      }`}
    >
      <div
        className="half-width-lg-up full-width-md-max organization-card-image-container"
        style={{
          backgroundImage: displayPhoto?.src
            ? `url(${displayPhoto?.src})`
            : 'none',
        }}
      >
        <img
          className="full-width full-height"
          src={displayPhoto?.src}
          alt={title}
        />
      </div>
      <div className="flex flex-column flex-space-between half-width full-width-lg-max bg-white border-radius-4 details-section">
        <div className="flex flex-column py-8 pl-6 details-copy">
          <p className="f-24 mb-0 color-body-copy">{title}</p>
          {!hasActiveFieldAccountCode && (
            <p className="bold f-14 mb-0">
              (Unable to create event/offer. Please try again later.)
            </p>
          )}
          <p className="f-14 mb-0 mt-2 color-body-copy">Role: {renderRole()}</p>
          {canEdit && (
            <div className="flex flex-row flex-align-center pt-3">
              {partner?.hasLiveRevision || partner.hasDraft ? (
                <p className="m-0 bold f-14">Revision pending</p>
              ) : (
                <>
                  <img src={edit} alt="edit" className="pr-2" />
                  <Link
                    to={`${routes.editPartner}${partner.id}`}
                    className="color-primary-color f-14 bold"
                  >
                    Edit Organization
                  </Link>
                </>
              )}
            </div>
          )}
          {partner.canEditTeam && (
            <div className="flex flex-row flex-align-center pt-3">
              <img src={edit} alt="edit" className="pr-2" />
              <Link
                to={`${routes.editPartnerMembers}${partner.id}`}
                className="color-primary-color f-14 bold"
              >
                Edit Team
              </Link>
              {hasPendingUsers && (
                <p className="bold f-14 mb-0 ml-2">(Pending users)</p>
              )}
            </div>
          )}
        </div>
        <button
          className={`full-width color-white bg-primary-color text-center uppercase remove-padding border-none f-15 bold font-body py-4 px-2 m-0 ${
            !isActive ? 'btn' : ''
          }`}
          type="button"
          onClick={() => dispatch(setActivePartner(partner.id, title))}
        >
          {isActive ? 'Managing' : 'Manage Organizational Account'}
        </button>
      </div>
    </div>
  )
}

OrganizationCard.propTypes = {
  partner: PropTypes.shape({
    allUsers: PropTypes.arrayOf(
      PropTypes.shape({
        role: PropTypes.string,
      }),
    ),
    id: PropTypes.string,
    title: PropTypes.string,
    isOwner: PropTypes.bool,
    formattedPartnerImages: PropTypes.arrayOf(PropTypes.shape({})),
    formattedPartnerLogo: PropTypes.shape({
      src: PropTypes.string,
    }),
    userRole: PropTypes.string.isRequired,
    hasLiveRevision: PropTypes.bool,
    canEditTeam: PropTypes.bool,
    hasDraft: PropTypes.bool.isRequired,
    field_account: PropTypes.shape({
      field_account_code: PropTypes.string.isRequired,
    }),
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
}

export default OrganizationCard
