import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import UploadInput from './UploadInput'
import './styles.scss'
import { selectUserToken } from '../../../redux/authentication/authentication'
import { selectUserUuid } from '../../../redux/user/fetchUser'
import { createImage } from '../../../api/api'

export default function UploadContainer({
  uploadCount,
  fieldValue,
  setFieldValue,
  value,
  placeholder,
  fetchAsFile,
}) {
  const [uploads, setUploads] = useState(value)
  const [loading, setLoading] = useState(false)

  const userToken = useSelector(selectUserToken)
  const userUuid = useSelector(selectUserUuid)

  useEffect(() => {
    setFieldValue(fieldValue, uploads)
  }, [uploads])

  const handleUploads = (file, key) => {
    const formData = new FormData()
    formData.append('file', file)
    setLoading(key)
    const newArr = Array.isArray(uploads) ? [...uploads] : [uploads]
    createImage({
      uuid: userUuid,
      token: userToken,
      imageData: formData,
      fileName: file?.name,
      fetchAsFile,
    })
      .then(res => {
        newArr[key] = { id: key, file, drupalId: res?.data?.data?.id }
        setUploads(newArr)
        setLoading(false)
      })
      .catch(() => {
        newArr[key] = { id: key, file, error: 'Unable to upload image.' }
        setUploads(newArr)
        setLoading(false)
      })
  }

  const renderUploaders = count => {
    const uploadArr = []
    // eslint-disable-next-line
    for (let index = 0; index < count; index++) {
      uploadArr.push(
        <UploadInput
          handleUploads={handleUploads}
          value={value[index] || {}}
          id={index}
          key={`uploader-${index}`}
          loading={loading}
          errorMessage={value[index]?.error || null}
        />,
      )
    }
    return uploadArr
  }

  let uploadClasses = ''
  if (uploadCount === 1) {
    uploadClasses = 'flex-row flex-justify-center single-upload full-width'
  } else if (uploadCount > 1) {
    uploadClasses = 'flex-column flex-space-between full-width'
  } else {
    uploadClasses = 'flex-row flex-justify-center full-width'
  }

  return (
    <div>
      <p className="mb-4 font-body f-18 bold capitalize">{placeholder}</p>
      <div
        className={`file-uploader flex-lg-up p-6 full-width  ${
          uploadCount === 2 ? 'flex-space-around' : 'flex-space-between'
        }`}
      >
        <div className="file-uploader__content pb-4 pb-0-lg-up">
          <p>
            For optimal results images should be landscape, 1200px x 750px (w x
            h), or a minimum of 620px x 470px (w x h).
          </p>
          <p>Images cannot include any writing or logos.</p>
          <p className="m-0">
            <strong>
              Maximum {uploadCount} {uploadCount > 1 ? 'files' : 'file'}.
            </strong>
          </p>
          <p className="m-0">
            <strong>5 MB limit.</strong>
          </p>
          <p className="m-0">
            <strong>Allowed types: png gif jpg jpeg.</strong>
          </p>
        </div>
        <div className={`${uploadClasses} flex-md-up  flex-align-center`}>
          {renderUploaders(uploadCount)}
        </div>
      </div>
    </div>
  )
}

UploadContainer.defaultProps = {
  value: [],
  fetchAsFile: false,
}

UploadContainer.propTypes = {
  uploadCount: PropTypes.number.isRequired,
  fieldValue: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  placeholder: PropTypes.string.isRequired,
  fetchAsFile: PropTypes.bool,
}
