import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { fetchPage, selectPageState } from '../../redux/content/content'
import SimpleSpinner from '../../components/elements/SimpleSpinner'
import RouteNotFound from '../RouteNotFound'

import './styles.scss'

// children act as a fall back if the content fails to load.
const ContentPage = ({ slug }) => {
  const { loading, error, data } = useSelector(state =>
    selectPageState(state, slug),
  )
  const dispatch = useDispatch()
  useEffect(() => {
    if (!loading && !error && !data) {
      dispatch(fetchPage(slug))
    }
  }, [loading, error, data])

  const title = data?.title
  const body = data?.body

  if (error) {
    return <RouteNotFound noHeader noFooter />
  }

  return (
    <div className="content-page container-centered-lg py-14 px-4-lg-max">
      {title && <h1 className="pb-8 normal remove-margin">{title}</h1>}
      <div className="content-page-container container-centered-lg bg-white border border-border-light py-16">
        {loading && (
          <div className="flex flex-justify-center">
            <SimpleSpinner />
          </div>
        )}
        {/* eslint-disable-next-line */}
        {body && <div dangerouslySetInnerHTML={{ __html: body }} />}
      </div>
    </div>
  )
}

ContentPage.propTypes = {
  slug: PropTypes.string.isRequired,
}

export default ContentPage
