import * as Yup from 'yup'
import { get, isNil } from 'lodash'
import formErrors from '../../../../configuration/formErrors'

export const contactDetailsFormFields = currentEvent => {
  const formData = {
    coordinatorName: {
      name: 'coordinatorName',
      type: 'text',
      placeholder: 'Coordinator Name *',
      initialValue: get(currentEvent, 'field_coordinator_name', ''),
      validations: Yup.string().required(formErrors.requiredField),
    },
    coordinatorEmailAddress: {
      name: 'coordinatorEmailAddress',
      type: 'text',
      placeholder: 'Coordinator Email Address *',
      initialValue: get(currentEvent, 'field_coordinator_email', ''),
      validations: Yup.string()
        .email(formErrors.invalidEmail)
        .required(formErrors.requiredField),
    },
    coordinatorPhoneNumber: {
      name: 'coordinatorPhoneNumber',
      type: 'text',
      placeholder: 'Coordinator Phone Number',
      initialValue: isNil(currentEvent?.field_coordinator_phone)
        ? ''
        : currentEvent?.field_coordinator_phone,
    },
    PhoneNumber: {
      name: 'PhoneNumber',
      type: 'text',
      placeholder: 'Phone Number',
      validations: Yup.string().when('tollFreePhoneNumber', {
        is: tollFreePhoneNumber => tollFreePhoneNumber !== undefined,
        then: Yup.string(),
        otherwise: Yup.string().required(formErrors.requiredField),
      }),
      initialValue:
        isNil(currentEvent?.field_public_coordinator_phone) ||
        !currentEvent?.field_public_coordinator_phone?.length
          ? ''
          : currentEvent?.field_public_coordinator_phone,
    },
    tollFreePhoneNumber: {
      name: 'tollFreePhoneNumber',
      type: 'text',
      placeholder: 'Toll Free Phone Number',
      validations: Yup.string(),
      initialValue:
        isNil(currentEvent?.field_public_toll_free) ||
        !currentEvent?.field_public_toll_free?.length
          ? ''
          : currentEvent?.field_public_toll_free,
    },
  }

  const initialSocialMedia = get(
    currentEvent,
    'field_social_media.platform_values',
    null,
  )

  if (initialSocialMedia) {
    Object.entries(initialSocialMedia).forEach(([name, data]) => {
      formData[name] = {
        name,
        initialValue: data.value,
        validations: Yup.string().test({
          message: formErrors.invalidNotURL,
          // eslint-disable-next-line
          test: function (value) {
            const expression =
              /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
            const regex = new RegExp(expression)
            return !value || !value.match(regex)
          },
        }),
      }
    })
  }

  return formData
}
