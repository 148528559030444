import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { formInitialValues } from '../../utilities/content/generateFormData'

import OfferForm from './forms/OfferForm'
import { renderInitialFormFields } from './forms/formFields/offerFormFields'

import './style.scss'
import {
  fetchOfferCategories,
  selectOfferCategoryState,
} from '../../redux/offerCategories/offerCategories'
import BrandLoader from '../../components/elements/BrandLoader'
import { formatFormDataForSubmission } from './utilities/formatOffer'
import { selectUserPartners } from '../../redux/partners/userPartners'
import { selectActivePartner } from '../../redux/activePartner/activePartner'
import { selectUserToken } from '../../redux/authentication/authentication'
import { createOffer } from '../../api/api'
import { fetchOffers, selectOfferById } from '../../redux/offers/offers'
import routes from '../../configuration/routes'
import { selectUserUid } from '../../redux/user/fetchUser'
import { NON_MEMBER_ACCOUNT_ID } from '../../configuration/constants'

const CreateOffer = () => {
  const { id: currentOfferId } = useParams()

  const offerCardCategoriesState = useSelector(selectOfferCategoryState)
  const activePartner = useSelector(selectActivePartner)
  const partnerList = useSelector(selectUserPartners)
  const userToken = useSelector(selectUserToken)
  const userUid = useSelector(selectUserUid)
  const currentOffer = useSelector(state =>
    selectOfferById(state, currentOfferId),
  )

  const isEdit = currentOffer !== null

  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    if (
      !offerCardCategoriesState.data &&
      !offerCardCategoriesState.loading &&
      !offerCardCategoriesState.error
    ) {
      dispatch(fetchOfferCategories())
    }
  }, [offerCardCategoriesState])

  const initialFormData = renderInitialFormFields(
    currentOffer,
    activePartner?.id && activePartner?.id !== NON_MEMBER_ACCOUNT_ID
      ? activePartner?.id
      : null,
  )

  const [formValues, setFormValues] = useState(
    formInitialValues(initialFormData),
  )

  const [currentStep, setCurrentStep] = useState('form')
  const [error, setError] = useState(null)

  const isLoading =
    offerCardCategoriesState?.loading || !offerCardCategoriesState.data

  function handleSubmit(offerDetails) {
    setError(null)
    const reqBody = formatFormDataForSubmission(offerDetails)

    if (currentOfferId) {
      reqBody.id = currentOfferId
    }

    return createOffer({
      reqBody,
      token: userToken,
      isUpdate: Boolean(currentOfferId),
      currentOfferId,
    })
      .then(res => {
        if (res.data?.data) {
          dispatch(fetchOffers(userUid))
          setCurrentStep('success')
          return res.data?.data
        }
        setError('Unable to create offer')
        return false
      })
      .catch(err => {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line
          console.log(err)
        }
        setError(
          err?.response?.data?.errors?.[0]?.detail || 'Unable to create offer',
        )
        return false
      })
  }

  return (
    <div className="px-2 py-10 full-height create-offer">
      <div className="container-centered-lg">
        <div className="pt-4 pb-15">
          {isLoading ? (
            <div className="text-center container-centered-sm">
              <BrandLoader />
            </div>
          ) : (
            <>
              {currentStep === 'success' || currentOffer?.hasLiveRevision ? (
                <div className="pt-2">
                  <div className="bg-white py-8 border border-medium-grey border-radius-4">
                    {currentStep === 'success' ? (
                      <p className="text-center f-18 m-0">
                        Thank You. Your offer has been submitted and is pending
                        approval.
                      </p>
                    ) : (
                      <p className="text-center f-18 m-0">
                        Your offer has been submitted and is pending approval.
                      </p>
                    )}

                    <div className="text-center pt-8">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => history.push(routes.offers)}
                      >
                        Return to Offers
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <OfferForm
                  submit={offerDetails => {
                    setFormValues(offerDetails)
                    return handleSubmit(offerDetails)
                  }}
                  initialValues={formValues}
                  categories={offerCardCategoriesState.data || []}
                  partnerList={partnerList}
                  offerFormFields={initialFormData}
                  error={error}
                  editing={isEdit}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default CreateOffer
