export default {
  requiredField: 'This field is required',
  invalidEmail: 'Please enter a valid email address',
  passwordMismatch: 'Passwords must match',
  mustAcceptTerms: 'You must accept the terms and conditions',
  invalidURL: 'Please enter a valid website URL',
  invalidNotURL: 'Please enter only the relative path',
  overLimitCharacters: 'Field must be between 1 and 500 characters',
  requiredOptions: 'You must slect one of these options',
  invalidNumber: 'Must be a number',
  requiredEndDate: 'Pick an end date',
  selectUpToThree: 'Please select up to three categories',
  emailChange: 'Please enter your current password for email address changes',
  endDateMin: 'End date must not occur before start date',
}
