import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { formValidation } from '../../../utilities/content/generateFormData'
import paymentFormFields from './formFields/paymentFormFields'
import FormikInput from '../../../components/elements/FormikInput'

import LeftChev from '../../../assets/icons/chev-left-blue.svg'
import SimpleSpinner from '../../../components/elements/SimpleSpinner'

const PaymentForm = ({ submit, back, initialValues = {}, paymentOptions }) => (
  <Formik
    initialValues={initialValues}
    onSubmit={submit}
    validationSchema={formValidation(paymentFormFields)}
  >
    {({ handleSubmit, isSubmitting }) => (
      <form onSubmit={handleSubmit} className="register-org-payment-form">
        <div className="pt-4-lg-up pb-9 pb-7-lg-max">
          <p>
            Investments are payable in advance. Your membership is automatically
            renewed each year unless advance notice of resignation is submitted
            to the membership department.
          </p>
          <p>
            Chamber membership dues payments may be tax deductible by members as
            an ordinary and necessary business expense, but not as a charitable
            contribution.
          </p>
        </div>
        <div key="contact">
          <p className="bold f-18 pb-3-lg-max">
            Someone will contact you about payment options shortly. <br />
            Please indicate how you would prefer to be contacted: *
          </p>
        </div>

        <div className="contact-radios">
          <FormikInput
            {...paymentFormFields.contactMethod}
            options={paymentOptions}
          />
        </div>

        <div className="pb-8 pt-10 pt-4-lg-max news-opt-in">
          <FormikInput {...paymentFormFields.optIn} />
        </div>

        {isSubmitting ? (
          <div className="flex flex-justify-center">
            <SimpleSpinner />
          </div>
        ) : (
          <div className="pt-6-lg-up flex flex-column-lg-max flex-align-center-lg-max">
            <button
              className="btn btn-inverse mr-8-lg-up mb-4-lg-max flex flex-justify-center flex-align-center flex-half"
              onClick={back}
              type="button"
            >
              <img src={LeftChev} alt="Left Chevron" className="mr-3" />
              <span>Return to Business Details</span>
            </button>
            <button
              className="btn btn-primary flex-half"
              type="submit"
              disabled={isSubmitting}
            >
              Complete Application
            </button>
          </div>
        )}
      </form>
    )}
  </Formik>
)

PaymentForm.defaultProps = {
  initialValues: {},
  paymentOptions: [],
}

PaymentForm.propTypes = {
  submit: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  paymentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
}

export default PaymentForm
