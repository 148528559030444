import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { logoutUser } from '../../redux/authentication/authentication'
import routes from '../../configuration/routes'
import Dropdown from '../elements/Dropdown/Dropdown'
import { HeaderDropdownItem } from './Header'

import userGuide from '../../assets/icons/guide.svg'
import homeIcon from '../../assets/icons/home.svg'
import calIcon from '../../assets/icons/calendar.svg'
import offerIcon from '../../assets/icons/offer.svg'
import userIcon from '../../assets/icons/user.svg'
import helpIcon from '../../assets/icons/help.svg'
import gearIcon from '../../assets/icons/gears.svg'
import logOutIcon from '../../assets/icons/arrow-out.svg'
import questionIcon from '../../assets/icons/help-light-grey.svg'
import phoneIcon from '../../assets/icons/phone.svg'
import jobLogo from '../../assets/icons/Post-a-job-Tack-icon.svg'
import { selectHasPartners } from '../../redux/partners/userPartners'

import '../../styles/structure/mobile-footer.scss'

const MobileFooter = () => {
  const dispatch = useDispatch()
  const hasPartners = useSelector(selectHasPartners)

  function handleLogout() {
    dispatch(logoutUser())
  }

  return (
    <div className="mobile-footer bg-dark flex-align-center flex-justify-center">
      <Link
        to={routes.dashboard}
        className="mobile-toggle flex flex-center color-white no-text-decoration uppercase bold f-12"
      >
        <div className="flex flex-align-center flex-column">
          <img src={homeIcon} alt="home" className="mobile-footer-img" />
          <p className="mobile-toggle__labels f-12 mb-0">Home</p>
        </div>
      </Link>
      <Link
        to={routes.events}
        className="mobile-toggle flex flex-center color-white no-text-decoration uppercase bold f-12"
      >
        <div className="flex flex-align-center flex-column">
          <img src={calIcon} alt="calender" className="mobile-footer-img" />
          <p className="mobile-toggle__labels f-12 mb-0">Events</p>
        </div>
      </Link>
      {hasPartners && (
        <>
          <Link
            to={routes.offers}
            className="mobile-toggle color-white no-text-decoration uppercase bold f-12"
          >
            <div className="flex flex-align-center flex-column">
              <img src={offerIcon} alt="offers" className="mobile-footer-img" />
              <p className="mobile-toggle__labels f-12 mb-0">Offers</p>
            </div>
          </Link>
          <Link
            to={routes.jobs}
            className="mobile-toggle color-white no-text-decoration uppercase bold f-12"
          >
            <div className="flex flex-align-center flex-column">
              <img src={jobLogo} alt="Jobs" className="mobile-footer-img" />
              <p className="mobile-toggle__labels f-12 mb-0 lg-max">Jobs</p>
              <p className="mobile-toggle__labels f-12 mb-0 lg-up">Jobs</p>
            </div>
          </Link>
        </>
      )}

      <div className="mobile-toggle mobile-toggle__light pr-2-sm-up">
        <Dropdown
          btnText="Account"
          btnClassNames="color-white bg-dark-grey f-12 "
          activeBtnClassNames="btn-primary f-12 "
          icon={userIcon}
          menuUp
        >
          <div className="bg-white box-shadow border border-light-grey border-radius-3">
            <Link to={routes.profileSettings}>
              <HeaderDropdownItem
                onClick={() => null}
                icon={gearIcon}
                text="Profile Settings"
                borderBottom
              />
            </Link>
            <HeaderDropdownItem
              onClick={handleLogout}
              icon={logOutIcon}
              text="Log Out"
            />
          </div>
        </Dropdown>
      </div>
      <div className="mobile-toggle mobile-toggle__light">
        <Dropdown
          btnText="Help"
          btnClassNames="color-white bg-dark-grey f-12 "
          activeBtnClassNames="btn-primary f-12 "
          icon={helpIcon}
          menuUp
        >
          <div className="bg-white box-shadow border border-light-grey border-radius-3">
            <Link to={routes.faq}>
              <HeaderDropdownItem icon={questionIcon} text="FAQ" borderBottom />
            </Link>
            <Link to={routes.userGuide}>
              <HeaderDropdownItem
                icon={userGuide}
                text="User Guide"
                borderBottom
              />
            </Link>
            <Link to={routes.contactUs}>
              <HeaderDropdownItem icon={phoneIcon} text="Contact Us" />
            </Link>
          </div>
        </Dropdown>
      </div>
    </div>
  )
}

export default MobileFooter
