/* eslint-disable import/no-cycle */
import moment from 'moment'
import { getJobs } from '../../api/api'
import {
  selectPartnerState,
  selectUserPartnerIds,
} from '../partners/userPartners'
import { selectActivePartner } from '../activePartner/activePartner'
import { selectUserUuid } from '../user/fetchUser'

// CONSTANTS
export const FETCH_JOBS_BEGIN = 'FETCH_JOBS_BEGIN'
export const FETCH_JOBS_ERROR = 'FETCH_JOBS_ERROR'
export const FETCH_JOBS_SUCCESS = 'FETCH_JOBS_SUCCESS'
export const CLEAR_JOBS = 'CLEAR_JOBS'

// ACTIONS
export const fetchJobs = uid => dispatch => {
  dispatch({ type: FETCH_JOBS_BEGIN })
  return getJobs(uid)
    .then(res => {
      if (res?.data?.data) {
        const payload = res?.data?.data
          .sort((a, b) =>
            moment(a.changed).isBefore(moment(b.changed)) ? 1 : -1,
          )
          .reduce((obj, job) => {
            const id = job?.uuid
            return {
              ...obj,
              [id]: formatJob(job),
            }
          }, {})
        dispatch({ type: FETCH_JOBS_SUCCESS, payload })
      } else {
        dispatch({
          type: FETCH_JOBS_ERROR,
          payload: 'Unable to retrieve jobs',
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_JOBS_ERROR,
        payload: err?.response?.message || 'Unable to retrieve jobs',
      })
    })
}

// REDUCER
export default function jobReducer(
  state = { loading: false, error: null, data: null },
  action,
) {
  switch (action.type) {
    case FETCH_JOBS_BEGIN:
      return {
        ...state,
        loading: true,
      }
    case FETCH_JOBS_ERROR:
      return {
        loading: false,
        error: action.payload || 'Something went wrong',
        data: null,
      }

    case FETCH_JOBS_SUCCESS:
      return {
        loading: false,
        error: null,
        data: action.payload,
      }
    case CLEAR_JOBS:
      return {
        ...state,
        data: null,
      }
    default:
      return state
  }
}

// SELECTORS
export const selectJobsState = state => state.jobsData

export const selectJobs = state => {
  if (!state?.jobsData?.data || !Object.keys(state?.jobsData?.data).length)
    return null

  const partners = selectPartnerState(state)
  const userUuid = selectUserUuid(state)

  return Object.values(state.jobsData.data).map(job => {
    const accountData = job?.field_partner?.field_account || null

    const adminUsers = accountData?.field_admin_user?.length
      ? accountData?.field_admin_user
          ?.filter(user => !!user)
          .map(user => ({ ...user, role: 'admin' }))
      : []

    const isAdmin = adminUsers.some(user => user?.uuid === userUuid)
    return {
      ...job,
      isOwner: userUuid && job?.createdBy?.uuid === userUuid,
      userRole: partners?.data?.[job?.partnerId]?.userRole,
      canEdit: userUuid && (job?.createdBy?.uuid === userUuid || isAdmin),
    }
  })
}

export const selectUserJobs = state => {
  const partnerIds = selectUserPartnerIds(state)
  const allJobs = selectJobs(state)
  return allJobs?.length && partnerIds?.length
    ? allJobs.filter(job => partnerIds.some(id => id === job.partnerId))
    : null
}

export const selectActivePartnerJobs = state => {
  const allJobs = selectUserJobs(state)

  const activePartnerId = selectActivePartner(state)?.id

  return allJobs && activePartnerId
    ? allJobs.filter(job => job.partnerId === activePartnerId)
    : []
}

export const selectJobById = (state, id) => state?.jobsData?.data?.[id] || null

// UTILITIES
const formatJob = job => {
  const formattedJobImage = job?.field_job_image?.uuid
    ? {
        ...job.field_job_image,
        src: job.field_job_image?.url,
        drupalId: job.field_job_image.uuid,
      }
    : null
  const isLive = job?.status
  const isPendingRevision = !!job?.revision_pending

  return {
    ...job,
    id: job.uuid,
    formattedJobImage,
    partnerId: job?.field_partner?.uuid,
    createdBy: job.uid || {},
    isLive,
    hasLiveRevision: isPendingRevision && !isLive,
  }
}
