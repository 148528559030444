import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import {
  formInitialValues,
  formValidation,
} from '../../../utilities/content/generateFormData'
import FormikInput from '../../../components/elements/FormikInput'

import { getCountryStates } from '../../../utilities/content/supportedCountries'

import arrowLeft from '../../../assets/icons/arrow-left.svg'
import '../styles.scss'

const LocationDetailsForm = ({
  submit,
  back,
  initialValues,
  locations,
  locationDetailsFormFields,
  disableSubmit,
  disabledMessage,
}) => (
  <Formik
    initialValues={
      initialValues || formInitialValues(locationDetailsFormFields)
    }
    onSubmit={submit}
    validationSchema={formValidation(locationDetailsFormFields)}
  >
    {({ handleSubmit, isSubmitting }) => (
      <form onSubmit={handleSubmit}>
        <div>
          <p className="mb-0 font-body f-18 bold">
            Main Event Location <span className="color-primary-color">*</span>
          </p>
          <div className="pt-3 edit-event__radios">
            <FormikInput
              {...locationDetailsFormFields.mainLocation}
              options={locations || []}
            />
          </div>
        </div>
        <div className="pt-6">
          <FormikInput {...locationDetailsFormFields.locationName} />
        </div>
        <div className="pt-4">
          <FormikInput {...locationDetailsFormFields.streetAddress} />
        </div>
        <div className="pt-4">
          <FormikInput {...locationDetailsFormFields.streetAddress2} />
        </div>
        <div className="pt-4 flex-md-up">
          <div className="flex-half pr-4-md-up pb-4-md-max">
            <FormikInput {...locationDetailsFormFields.city} />
          </div>
          <div className="flex-half pl-4-md-up">
            <FormikInput
              {...locationDetailsFormFields.state}
              options={getCountryStates('US')}
            />
          </div>
        </div>
        <div className="pt-4 flex-md-up">
          <div className="flex-half pr-4-md-up pb-4-md-max">
            <FormikInput {...locationDetailsFormFields.country} />
          </div>
          <div className="flex-half pl-4-md-up">
            <FormikInput {...locationDetailsFormFields.zip} />
            <p className="f-12 mb-0 mt-2">
              Ensure Zip Code matches the street address and does not contain
              extra characters or spaces.
            </p>
          </div>
        </div>

        <div className="mt-14 mt-6-md-max bg-section-grey border border-border-light">
          <div className="px-6 py-6">
            <p className="mb-2 font-body f-18 bold">
              Maps <span className="color-primary-color">*</span>
            </p>
            <p className="mb-0">
              Retrieve your latitude and longitude from{' '}
              <a
                href="https://www.latlong.net/"
                target="_blank"
                rel="noreferrer"
              >
                getlatlong.net
              </a>
            </p>
            <div className="pt-4 flex-md-up">
              <div className="flex-half pr-4-md-up pb-2-md-max">
                <FormikInput {...locationDetailsFormFields.latitude} />
                <p className="mt-2 mb-0 f-12 font-body">
                  Enter in decimal 51.47879 format
                </p>
              </div>
              <div className="flex-half pl-4-md-up py-2-md-max">
                <FormikInput {...locationDetailsFormFields.longitude} />
                <p className="mt-2 mb-0 f-12 font-body">
                  Enter in decimal -0.010677 format
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-14 pt-6-md-max">
          <p className="mb-2 font-body f-18 bold">Directions</p>
          <p className="mb-0 font-body">
            Provide details to help guests find your event such as driving
            directions, recognizable landmarks, parking information and entry
            details.
          </p>
        </div>
        <div className="pt-4">
          <FormikInput {...locationDetailsFormFields.directions} />
        </div>
        <div className="pt-14">
          {disableSubmit && (
            <p className="color-error">
              {disabledMessage || 'Please ensure form is complete'}
            </p>
          )}
          <div className="flex flex-space-between flex-column-md-max">
            <button
              className="edit-event__backBtn btn btn-inverse mr-7-md-up"
              onClick={back}
              type="button"
            >
              <img src={arrowLeft} alt="Return arrow" className="mr-3" />
              Return to Event Details
            </button>
            <button
              className="edit-event__btn btn btn-primary mt-4-md-max"
              type="submit"
              disabled={isSubmitting}
            >
              Continue to Time and Date
            </button>
          </div>
        </div>
      </form>
    )}
  </Formik>
)

LocationDetailsForm.defaultProps = {
  initialValues: {},
  schema: {},
  locations: [],
  disableSubmit: false,
  disabledMessage: '',
}

LocationDetailsForm.propTypes = {
  submit: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  schema: PropTypes.shape({}),
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  locationDetailsFormFields: PropTypes.shape({
    mainLocation: PropTypes.shape({}),
    locationName: PropTypes.shape({}),
    streetAddress: PropTypes.shape({}),
    streetAddress2: PropTypes.shape({}),
    city: PropTypes.shape({}),
    state: PropTypes.shape({}),
    country: PropTypes.shape({}),
    zip: PropTypes.shape({}),
    latitude: PropTypes.shape({}),
    longitude: PropTypes.shape({}),
    directions: PropTypes.shape({}),
  }).isRequired,
  disableSubmit: PropTypes.bool,
  disabledMessage: PropTypes.string,
}

export default LocationDetailsForm
