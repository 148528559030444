import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'

import {
  formInitialValues,
  formValidation,
} from '../../../utilities/content/generateFormData'
import formErrors from '../../../configuration/formErrors'
import FormikInput from '../../../components/elements/FormikInput'

import './styles.scss'

const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY

const fields = {
  firstName: {
    name: 'firstName',
    type: 'text',
    isRequired: true,
    placeholder: 'First Name *',
    validations: Yup.string().required(formErrors.requiredField),
    initialValue: '',
  },

  lastName: {
    name: 'lastName',
    type: 'text',
    isRequired: true,
    placeholder: 'Last Name *',
    validations: Yup.string().required(formErrors.requiredField),
    initialValue: '',
  },
  email: {
    name: 'email',
    type: 'email',
    isRequired: true,
    placeholder: 'Email Address *',
    validations: Yup.string()
      .email(formErrors.invalidEmail)
      .required(formErrors.requiredField),
    initialValue: '',
    instructions: [
      'Please provide a valid email address. All emails from the system will be sent to this address.',
      'The email address is not made public and will only be used if you wish to receive a new password or wish to receive certain news or notifications by email.',
    ],
  },
  phone: {
    name: 'phone',
    type: 'text',
    isRequired: false,
    placeholder: 'Phone Number *',
    validations: Yup.string().required(formErrors.requiredField),
    initialValue: '',
  },
  username: {
    name: 'username',
    type: 'text',
    isRequired: true,
    placeholder: 'Username *',
    validations: Yup.string().required(formErrors.requiredField),
    initialValue: '',
  },
  password: {
    name: 'password',
    type: 'password',
    isRequired: true,
    placeholder: 'Password *',
    validations: Yup.string().required(formErrors.requiredField),
    initialValue: '',
  },
  confirmPassword: {
    name: 'confirmPassword',
    type: 'password',
    isRequired: true,
    placeholder: 'Confirm Password *',
    validations: Yup.string().oneOf(
      [Yup.ref('password'), null],
      formErrors.passwordMismatch,
    ),
    initialValue: '',
  },
  termsAndConditions: {
    name: 'termsAndConditions',
    type: 'checkbox',
    isRequired: true,
    renderLabel: () => (
      <>
        I have read and agree to the{' '}
        <a
          href={`${process.env.REACT_APP_ENDPOINT_DRUPAL}/terms-conditions`}
          target="__blank"
          rel="noreferrer noopener"
        >
          Terms and Conditions
        </a>
      </>
    ),

    validations: Yup.bool().oneOf([true]).required(formErrors.mustAcceptTerms),
    initialValue: false,
  },
  recaptcha: {
    name: 'recaptcha',
    validations: Yup.string().required(),
    isRequired: true,
    initialValue: '',
  },
}

const RegisterForm = ({ submit, savedFormValues }) => (
  <Formik
    initialValues={savedFormValues || formInitialValues(fields)}
    onSubmit={submit}
    validationSchema={formValidation(fields)}
  >
    {({ handleSubmit, setFieldValue }) => (
      <form onSubmit={handleSubmit} className="register-form">
        <div className="flex-lg-up pb-6-lg-up">
          <div className="flex-half-lg-up pr-4-lg-up pb-2-lg-max">
            <FormikInput {...fields.firstName} />
          </div>
          <div className="flex-half-lg-up pl-4-lg-up py-2-lg-max">
            <FormikInput {...fields.lastName} />
          </div>
        </div>
        <div className="pt-2-lg-max">
          <FormikInput {...fields.email} />
          <div className="py-2 py-4-lg-max mb-8-lg-up">
            <p className="m-0 f-12 normal instructions pb-2-lg-max">
              Please provide a valid email address. All emails from the system
              will be sent to this address.
            </p>
            <p className="m-0 f-12 normal instructions pt-2-lg-max">
              The email address is not made public and will only be used if you
              wish to receive a new password or wish to receive certain news or
              notifications by email.
            </p>
          </div>
        </div>
        <div className="half-width-lg-up pt-4-lg-up pb-3-lg-up pr-4-lg-up py-2-lg-max">
          <FormikInput {...fields.phone} />
        </div>
        <div className="half-width-lg-up pt-4-lg-up pb-3-lg-up pr-4-lg-up py-2-lg-max">
          <FormikInput {...fields.username} />
        </div>
        <div className="flex-lg-up pt-3-lg-up pb-1-lg-up">
          <div className="flex-half-lg-up pr-4-lg-up py-2-lg-max">
            <FormikInput {...fields.password} />
          </div>
          <div className="flex-half-lg-up pl-4-lg-up pt-2-lg-max">
            <FormikInput {...fields.confirmPassword} />
          </div>
        </div>
        <div className="py-9 py-8-lg-max mb-10-lg-max">
          <FormikInput {...fields.termsAndConditions} />
        </div>
        <div className="pb-9-lg-up recaptcha">
          <ReCAPTCHA
            sitekey={recaptchaKey}
            onChange={value => setFieldValue('recaptcha', value)}
          />
        </div>
        <div className="mt-18-lg-max pt-8-lg-max pt-1-lg-up">
          <button type="submit" className="btn btn-primary px-8 py-6">
            Create Account
          </button>
          <p className="mt-6 mt-8-lg-max normal">
            Your privacy is important to us. View our{' '}
            <Link
              to={{
                pathname:
                  'https://partners.hiltonheadisland.org/privacy-policy',
              }}
              target="_blank"
              rel="noreferrer noopener"
            >
              Privacy Policy.
            </Link>
          </p>
        </div>
      </form>
    )}
  </Formik>
)

RegisterForm.defaultProps = {
  savedFormValues: null,
}

RegisterForm.propTypes = {
  submit: PropTypes.func.isRequired,
  savedFormValues: PropTypes.shape({}),
}

export default RegisterForm
